import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getCampaignHostsWithAggregation } from 'apis/campaignHost';
import { withAppContext } from 'contexts/AppContext/AppContext';
import Card from 'components/Card/Card';

import { useFetchConstant } from 'utils/hooks';
import { getColumnFilterRadioProps, getColumnFilterSearchProps, constructConstantsLabel, constructArrayConstantsLabel } from 'utils/table';
import { getNewCampaignHostRoute, getCampaignHostRoute } from 'utils/routes';

const newCampaignHostRoute = getNewCampaignHostRoute();

const HostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CenterAlignContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const useLoadCampaignHosts = () => {
  const [currentQuery, setCurrentQuery] = useState({});
  const [campaignHosts, setCampaignHosts] = useState([]);
  const [totalOfCampaignHosts, setTotalOfCampaignHosts] = useState(0);

  const fetchCampaignHosts = async newQuery => {
    if (newQuery) {
      setCurrentQuery(newQuery);
    }
    const queryToFetch = newQuery || currentQuery;
    const hostsDetails = await getCampaignHostsWithAggregation(queryToFetch);

    setCampaignHosts(hostsDetails.campaignHosts);
    setTotalOfCampaignHosts(hostsDetails.totalCount);
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    fetchCampaignHosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { campaignHosts, setCampaignHosts, totalOfCampaignHosts, setTotalOfCampaignHosts, currentQuery, setCurrentQuery, fetchCampaignHosts };
};

const constructFilterQuery = filters => {
  const keys = Object.keys(filters);
  const filterQuery = {};
  keys.forEach(key => (filterQuery[key] = filters[key][0]));
  return filterQuery;
};

const getColumns = (campaigns, states, paymentStatuses) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...getColumnFilterSearchProps('name', 'Search name'),
      render: (text, record) => {
        return <Link to={getCampaignHostRoute(record._id).path}>{text}</Link>;
      }
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      width: '15%',
      ...getColumnFilterSearchProps('contactNumber', 'Search contact'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      ...getColumnFilterSearchProps('email', 'Search email'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Units On Hand',
      dataIndex: 'unitsOnHand',
      key: 'unitsOnHand',
      width: '10%',
      sorter: true,
      render: totalNoOfUnits => <CenterAlignContainer>{totalNoOfUnits ? <span>{totalNoOfUnits}</span> : <span>0</span>}</CenterAlignContainer>
    },
    {
      title: 'Campaigns',
      dataIndex: 'campaigns',
      key: 'campaigns',
      sorter: true,
      ...getColumnFilterRadioProps('campaigns', campaigns),
      render: values => (values && values.length > 0 ? <span style={{ textTransform: 'capitalize' }}>{constructArrayConstantsLabel(campaigns, values)}</span> : <span>-</span>)
    },
    {
      title: 'Covered Areas',
      dataIndex: 'coveredAreas',
      key: 'coveredAreas',
      sorter: true,
      ...getColumnFilterRadioProps('coveredAreas', states),
      render: values => (values ? <span style={{ textTransform: 'capitalize' }}>{constructArrayConstantsLabel(states, values)}</span> : <span>-</span>)
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      sorter: true,
      ...getColumnFilterRadioProps('paymentStatus', paymentStatuses),
      render: text => (text ? <span style={{ textTransform: 'capitalize' }}>{constructConstantsLabel(paymentStatuses, text)}</span> : <span>-</span>)
    }
  ];
  return columns;
};

const handleOnTableChange = async ({ pagination, filters, sorter }, fetchHosts) => {
  const filter = constructFilterQuery(filters);
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(filters && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && columnKey && { sort: encodeURIComponent(JSON.stringify({ [columnKey]: order === 'descend' ? -1 : 1 })) }),
    ...(pagination && current && { pagination: encodeURIComponent(JSON.stringify({ limit: pageSize, currentPage: current })) })
  };
  fetchHosts(query);
};

const CampaignHost = () => {
  const { campaignHosts, totalOfCampaignHosts, fetchCampaignHosts } = useLoadCampaignHosts();
  const { selections: campaigns, isLoading: isCampaignsLoading } = useFetchConstant('campaigns');
  const { selections: paymentStatuses, isLoading: isPaymentStatusesLoading } = useFetchConstant('campaignPaymentStatuses');
  const { selections: states, isLoading: isStatesLoading } = useFetchConstant('statesMY');
  const isLoading = isCampaignsLoading || isStatesLoading || isPaymentStatusesLoading;

  return (
    <>
      <Card>
        <HostHeader>
          <Link to={newCampaignHostRoute.path}>
            <Button type="primary" icon="plus">
              Create Campaign Host
            </Button>
          </Link>
        </HostHeader>
        {!isLoading && (
          <Table
            onChange={(pagination, filters, sorter) => handleOnTableChange({ pagination, filters, sorter }, fetchCampaignHosts)}
            rowKey={record => record._id}
            dataSource={campaignHosts}
            scroll={{ x: 1000 }}
            pagination={{ total: totalOfCampaignHosts }}
            columns={getColumns(campaigns, states, paymentStatuses)}
          />
        )}
      </Card>
    </>
  );
};

export default withAppContext(withRouter(CampaignHost));
