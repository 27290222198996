import React from 'react';
import { Table } from 'antd';

import Card from 'components/Card/Card';

import { getLabelOfConstant } from 'utils/general';
import { getEnquiryRoute } from 'utils/routes';
import { getColumnFilterSearchProps } from 'utils/table';

const getColumns = hostMatchStatuses => [
  {
    title: 'Ticket Number',
    dataIndex: 'ticketNumber',
    key: 'ticketNumber',
    ...getColumnFilterSearchProps('ticketNumber', 'Search ticket number'),
    render: (text, record) => {
      return <a href={getEnquiryRoute(record._id).path}>{text}</a>;
    }
  },
  {
    title: 'Building Name',
    dataIndex: 'buildingName',
    key: 'buildingName',
    ...getColumnFilterSearchProps('buildingName', 'Search building name'),
    render: text => (text ? <span>{text}</span> : <span>-</span>)
  },
  {
    title: 'Assignment Status',
    dataIndex: 'hostMatchStatus',
    key: 'hostMatchStatus',
    sorter: (prev, next) => prev.hostMatchStatus.localeCompare(next.hostMatchStatus),
    render: text => <span>{getLabelOfConstant(text, hostMatchStatuses)}</span>
  },
  {
    title: 'Enquiry PIC',
    dataIndex: 'pic',
    key: 'pic',
    ...getColumnFilterSearchProps('pic', 'Search PIC'),
    render: text => (text ? <span>{text}</span> : <span>-</span>)
  }
];

const MatchedEnquiryInfo = ({ enquiryMatches, hostMatchStatuses }) => {
  return (
    <Card title="Matched Enquiries">
      <Table
        rowkey={record => `${record._id}${record.buildingName}`}
        dataSource={enquiryMatches}
        columns={getColumns(hostMatchStatuses)}
        pagination={{ total: enquiryMatches.length }}
      />
    </Card>
  );
};

export default MatchedEnquiryInfo;
