import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import Card from 'components/Card/Card';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import LocationWithGoogleMap from 'components/LocationWithGoogleMap/LocationWithGoogleMap';
import UploadSinglePhoto from 'components/UploadPhotos/UploadSinglePhoto';

import { useFetchConstant } from 'utils/hooks';
import { INPUT_TYPE_TEXTAREA, DEFAULT_CONTACT_COUNTRY_CODE } from 'utils/constants';
import FormHidden from 'components/FormHidden/FormHidden';

const BasicDetails = ({ form, campaignHost, countryCodes, states }) => {
  const defaultContactCountryCode = useMemo(() => (!!campaignHost.contact && campaignHost.contact.countryCode) || DEFAULT_CONTACT_COUNTRY_CODE, [
    campaignHost.contact
  ]);

  const handleOnLogoUploadFinish = (logoUrl) => {
    form.setFieldsValue({
      logo: logoUrl,
    });
  };

  const logoUrl = form.getFieldValue('logo') || campaignHost.logo;

  return (
    <Card title="Basic Details">
      <Row type="flex" gutter={24}>
        <Col span={12}>
          <FormInput
            form={form}
            name="name"
            label="Name"
            defaultValue={campaignHost.name}
            placeholder="e.g. Joe Doe"
            requiredErrorMessage="Please enter the campaign host's name"
          />
        </Col>
        <Col span={12}>
          <FormInput form={form} name="email" label="Email" defaultValue={campaignHost.email} placeholder="e.g. host@hostastay.com" isEmail />
        </Col>
      </Row>
      <Row type="flex" gutter={24}>
        <Col span={24} md={12}>
          <FormInput form={form} name="website" label="Website" defaultValue={campaignHost.website} placeholder="e.g. https://www.hostastay.com" />{' '}
        </Col>
        <Col span={24} md={12}>
          <FormLabel>Contact Number</FormLabel>
          <Row type="flex">
            <Col span={4}>
              <FormSelection form={form} name="contactCountryCode" selections={countryCodes} defaultValue={defaultContactCountryCode} />
            </Col>
            <Col span={20}>
              <FormInput
                form={form}
                name="contactNumber"
                defaultValue={!!campaignHost.contact ? campaignHost.contact.contactNumber : undefined}
                placeholder="e.g. 3 7886 7829"
                isContactNumber
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" gutter={24}>
        <Col span={24} xl={8}>
          <FormInputNumber
            form={form}
            name="yearEstablished"
            label="Year of Establishment"
            defaultValue={campaignHost.yearEstablished}
            placeholder={`e.g. ${new Date().getFullYear()}`}
            requiredErrorMessage="Please enter the campaign host's year of establishment"
          />
        </Col>
        <Col span={24} xl={8}>
          <FormInputNumber
            form={form}
            name="unitsOnHand"
            label="Total Number of Unit(s) On Hand"
            defaultValue={campaignHost.unitsOnHand}
            placeholder="e.g. 250"
            requiredErrorMessage="Please enter the campaign host's total number of units on hand"
          />
        </Col>
        <Col span={24} xl={8}>
          <FormSelection
            hasMultipleMode
            form={form}
            name="coveredAreas"
            label="Covered Areas"
            selections={states}
            defaultValue={campaignHost.coveredAreas}
            placeholder="Select area(s) covered by this host"
            requiredErrorMessage="Please enter areas covered by this campaign host"
          />
        </Col>
      </Row>
      <Row type="flex" gutter={24}>
        <Col span={24} md={18}>
          <Row type="flex" gutter={24}>
            <Col span={24}>
              <FormInput
                form={form}
                name="briefDescription"
                label="Summary"
                defaultValue={campaignHost.briefDescription}
                placeholder="Briefly describe about this host"
                requiredErrorMessage="Please briefly describe about this campaign host"
              />
            </Col>
          </Row>
          <Row type="flex" gutter={24}>
            <Col span={24}>
              <FormInput
                inputType={INPUT_TYPE_TEXTAREA}
                form={form}
                name="longDescription"
                label="Detailed Description"
                defaultValue={campaignHost.longDescription}
                placeholder="Elaborate more from the summary"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} md={6}>
          <FormLabel>Logo</FormLabel>
          <FormHidden form={form} name="logo" defaultValue={logoUrl} />
          <UploadSinglePhoto onUploadFinish={handleOnLogoUploadFinish} photo={logoUrl} />
        </Col>
      </Row>
    </Card>
  );
};

const GeneralInfo = ({ form, campaignHost, latitude, longitude, setCoordinates, countryCodes }) => {
  const { selections: states, isLoading: isStatesLoading } = useFetchConstant('statesMY');
  return (
    <>
      <Row>{!isStatesLoading && <BasicDetails form={form} campaignHost={campaignHost} countryCodes={countryCodes} states={states} />}</Row>
      <Row>
        <Card title="Address">
          <LocationWithGoogleMap
            form={form}
            defaultValue={campaignHost}
            latitude={latitude}
            longitude={longitude}
            onMapCoordinateChange={setCoordinates}
          />
        </Card>
      </Row>
    </>
  );
};

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired,
  campaignHost: PropTypes.object.isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCoordinates: PropTypes.func.isRequired,
  countryCodes: PropTypes.array.isRequired
};

export default GeneralInfo;
