import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL } from 'utils/constants';

import FormLabel from 'components/FormLabel/FormLabel';

const addRules = (hasRequiredErrorMessage, requiredErrorMessage, extraRules) => {
  const rules = [];

  if (hasRequiredErrorMessage) {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }

  return [...rules, ...extraRules];
};

const FormItem = ({
  form,
  name,
  defaultValue,
  requiredErrorMessage,
  extraRules,
  label,
  labelSize,
  labelClassName,
  formLabel,
  className,
  children,
  ...props
}) => {
  const hasRequiredErrorMessage = requiredErrorMessage && typeof requiredErrorMessage === 'string';
  const rules = addRules(hasRequiredErrorMessage, requiredErrorMessage, extraRules);

  return (
    <div className={className}>
      {!formLabel && !!label && (
        <FormLabel labelSize={labelSize} className={labelClassName} hasRequiredErrorMessage={hasRequiredErrorMessage} {...props}>
          {label}
        </FormLabel>
      )}
      <Form.Item label={formLabel} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(children)}
      </Form.Item>
    </div>
  );
};

FormItem.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,
  label: PropTypes.string,
  labelSize: PropTypes.oneOf([LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL, '']),
  labelClassName: PropTypes.string,
  formLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

FormItem.defaultProps = {
  requiredErrorMessage: '',
  extraRules: [],
  label: '',
  labelSize: '',
  labelClassName: '',
  formLabel: '',
  className: ''
};

export default FormItem;
