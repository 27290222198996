import React, { useCallback, useState } from 'react';
import { Button, Col, Icon, List, Modal, Row } from 'antd';
import styled, { css } from 'styled-components/macro';

import Card from 'components/Card/Card';
import FormSelection from 'components/FormSelection/FormSelection';

import { useEditItem, useFetchConstant } from 'utils/hooks';
import { getCurrentIndexForPaginatedList } from 'utils/general';

import UnitModal from './components/UnitModal/UnitModal';

const AddUnitButton = styled(Button)`
  margin-bottom: 16px;
  width: auto !important;
`;

const ListItemMeta = styled(List.Item.Meta)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const RowLabel = styled.p`
  word-break: break-word;
  ${props =>
    props.type === 'title' &&
    css`
      color: #4f4f4f;
      margin-bottom: 0px;
      margin-top: 8px;
      font-weight: 700;
    `}

  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-bottom: 0px;

  @media (min-width: 992px) {
    width: auto;
  }
`;

const UnitRow = ({ unit, onEdit, onDelete }) => {
  return (
    <Row type="flex" align="middle" style={{ height: '100px' }}>
      <Col span={24} md={14}>
        <Row>
          <RowLabel type="title">{unit.name || '-'}</RowLabel>
        </Row>
      </Col>
      <Col span={24} md={8}>
        <Row type="flex" justify="end" align="middle" gutter={8}>
          <Col span={24} xs={12} md={24} lg={12} style={{ textAlign: 'right' }}>
            <ActionButton type="primary" icon="edit" onClick={onEdit}>
              Edit
            </ActionButton>
          </Col>
          <Col span={24} xs={12} md={24} lg={12}>
            <ActionButton type="secondary" icon="delete" onClick={onDelete}>
              Delete
            </ActionButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const getIsHostJoinedInstaCampaign = (form, campaignsObj) => {
  const campaignsJoined = form.getFieldValue('campaigns') || [];
  return campaignsJoined.find(campaignJoined => campaignsObj.TOP_3_INSTAGRAMMABLE_UNITS.code === campaignJoined);
};

const CampaignInfo = ({ form, campaigns, campaignsObj, campaignHost, units, onUnitsChange }) => {
  const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { selections: paymentStatuses } = useFetchConstant('campaignPaymentStatuses');
  const isHostJoinedInstaCampaign = getIsHostJoinedInstaCampaign(form, campaignsObj);
  const {
    selectedItem: selectedUnit,
    selectedIndex,
    isEditing,
    updateEditItem: updateSelectedUnit,
    resetEditItem: resetSelectedUnit
  } = useEditItem();

  const handleOnAddUnitButtonClick = useCallback(
    e => {
      e.preventDefault();
      setIsUnitModalVisible(true);
      resetSelectedUnit();
    },
    [resetSelectedUnit]
  );

  const handleOnEditUnit = useCallback(
    index => e => {
      e.preventDefault();
      setIsUnitModalVisible(true);
      updateSelectedUnit(index, units[index]);
    },
    [units, updateSelectedUnit, setIsUnitModalVisible]
  );

  const handleOnDeleteUnit = useCallback(
    index => e => {
      e.preventDefault();
      Modal.confirm({
        title: 'Are you sure want to drop this unit from the campaign?',
        content: 'This unit will not be removed from the campaign until you click the "Save" button',
        icon: <Icon type="warning" />,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk() {
          units.splice(index, 1);
          onUnitsChange([...units]);
        },
        onCancel() {}
      });
    },
    [units, onUnitsChange]
  );

  const handleOnCancelUnitModal = useCallback(
    e => {
      e.preventDefault();
      setIsUnitModalVisible(false);
      resetSelectedUnit();
    },
    [resetSelectedUnit]
  );

  const handleOnSaveUnitModal = useCallback(
    newUnit => {
      setIsUnitModalVisible(false);
      if (isEditing) {
        units[selectedIndex] = newUnit;
        onUnitsChange([...units]);
      } else {
        onUnitsChange([...units, newUnit]);
      }
    },
    [isEditing, selectedIndex, units, onUnitsChange]
  );
  return (
    <>
      <Card title="Host Campaign Details">
        <Row type="flex" gutter={24}>
          <Col span={24} lg={14}>
            <FormSelection
              hasMultipleMode
              form={form}
              name="campaigns"
              label="Campaign(s) Joined"
              selections={campaigns}
              placeholder="Select campaign(s) joined by this host"
              defaultValue={campaignHost.campaigns}
            />
          </Col>
          <Col span={24} lg={10}>
            <FormSelection
              form={form}
              name="paymentStatus"
              label="Payment Status"
              selections={paymentStatuses}
              placeholder="Select payment status"
              defaultValue={campaignHost.paymentStatus}
            />
          </Col>
        </Row>
      </Card>
      {isHostJoinedInstaCampaign && (
        <Card title={`More details for ${campaignsObj.TOP_3_INSTAGRAMMABLE_UNITS.label}`}>
          <AddUnitButton icon="plus" type="primary" onClick={handleOnAddUnitButtonClick}>
            Add Unit for Campaign
          </AddUnitButton>
          <List
            size="large"
            bordered
            dataSource={units}
            pagination={{
              onChange: page => setCurrentPage(page)
            }}
            renderItem={(item, index) => {
              const currentItemIndex = getCurrentIndexForPaginatedList(index, currentPage);
              return (
                <List.Item>
                  <ListItemMeta
                    avatar={
                      <div style={{ width: '100px', height: '100px', lineHeight: '100px', backgroundColor: '#FAFAFA' }}>
                        <img width="100%" height="auto" alt="logo" src={item.photos[0]} />
                      </div>
                    }
                    description={<UnitRow unit={item} onEdit={handleOnEditUnit(currentItemIndex)} onDelete={handleOnDeleteUnit(currentItemIndex)} />}
                  />
                </List.Item>
              );
            }}
          />
        </Card>
      )}
      {isUnitModalVisible && (
        <UnitModal unit={selectedUnit} isVisible={isUnitModalVisible} onSave={handleOnSaveUnitModal} onCancel={handleOnCancelUnitModal} />
      )}
    </>
  );
};

export default CampaignInfo;
