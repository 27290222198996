import React, { useState, useEffect } from 'react';
import { getHosts } from 'apis/host';
import { Form, Modal } from 'antd';

import FormSelection from 'components/FormSelection/FormSelection';

const useFetchHostSelections = () => {
  const [hostSelections, setHostSelections] = useState([]);

  useEffect(() => {
    getHosts({ isFetchAll: true }).then(hosts => {
      const hostSelectionsWithNewHost = [
        { value: '', label: 'Create a new host' },
        ...hosts.map(host => {
          return {
            value: host._id,
            label: host.name
          };
        })
      ];
      setHostSelections(hostSelectionsWithNewHost);
    });
  }, []);

  return hostSelections;
};
const CreateOrLinkHostModal = ({ form, onOk, onCancel, visible }) => {
  const hostSelections = useFetchHostSelections();

  return (
    <Modal title="Create or Link an Existing Host" visible={visible} destroyOnClose onOk={onOk} onCancel={onCancel}>
      <p>
        By closing an enquiry by a <strong>host</strong>, you <span style={{ color: '#00b9c6' }}>link</span> the enquiry to him/her. You can either
        choose an existing host or create a new one.
      </p>
      <Form>
        <FormSelection form={form} size="large" label="Host" name="host" defaultValue="" selections={hostSelections} isAllowClear={false} />
      </Form>
    </Modal>
  );
};

export default CreateOrLinkHostModal;
