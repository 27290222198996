import React, { useState } from 'react';
import { DatePicker, Modal, Row } from 'antd';
import moment from 'moment';
import csvjson from 'csvjson';

import { getCSV } from 'apis/general';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'YYYY-MM-DD';

export const getDataAndExportCSV = ({ setIsLoading, type, query, filename, postProcess = () => {} }) => {
  setIsLoading(true);
  getCSV(type.toLowerCase(), query).then(res => {
    const url = window.URL.createObjectURL(new Blob([csvjson.toCSV(res, { headers: 'key' })]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsLoading(false);
    postProcess();
  });
};

const DownloadCSVModal = ({ showModal, closeModal, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('month')
      .format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('month')
      .format(DATE_FORMAT)
  );
  const filename = `${type.toLowerCase()}-${startDate}-to-${endDate}.csv`;

  const handleDateChange = (dates, [startDateString, endDateString]) => {
    setStartDate(startDateString);
    setEndDate(endDateString);
  };

  const handleOnOk = () => {
    getDataAndExportCSV({ setIsLoading, type, query: { startDate, endDate }, filename, postProcess: closeModal });
  };

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      title={`Download ${type} CSV`}
      destroyOnClose={true}
      maskClosable={false}
      cancelText="Close"
      okButtonProps={{ loading: isLoading }}
      okText={'Download CSV'}
      onOk={handleOnOk}
    >
      <Row type="flex" justify="space-around" align="middle">
        Date Range:
        <RangePicker defaultValue={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]} onChange={handleDateChange} />
      </Row>
    </Modal>
  );
};

export default DownloadCSVModal;
