import React from 'react';
import { List, Modal, Tabs } from 'antd';

import Card from 'components/Card/Card';

const { TabPane } = Tabs;
const { Item } = List;
const { Meta } = Item;

const constructBuildingDataForList = buildingDetails => [
  { label: 'Building Name', value: buildingDetails.name || <i>No Info</i> },
  { label: 'Address', value: buildingDetails.address || <i>No Info</i> },
  { label: 'Building Type', value: buildingDetails.type || <i>No Info</i> },
  { label: 'Total No. Of Units Handled by Host', value: buildingDetails.totalHostOnHandUnits || <i>No Info</i> }
];

const constructHostDataForList = hostDetails => [
  { label: 'Host Name', value: hostDetails.name || <i>No Info</i> },
  { label: 'Contact Number', value: hostDetails.contactNumber || <i>No Info</i> },
  { label: 'Email', value: hostDetails.email || <i>No Info</i> },
  { label: 'Host Type', value: hostDetails.type || <i>No Info</i> },
  { label: 'Partnership Status', value: hostDetails.partnershipStatus || <i>No Info</i> },
  { label: 'Contract', value: hostDetails.contractPeriod || <i>No Info</i> },
  { label: 'Address', value: hostDetails.address || <i>No Info</i> },
  { label: 'Total No. Of Buildings', value: hostDetails.totalNoOfBuildings || <i>No Info</i> },
  { label: 'Total No. Of Units-On-Hand', value: hostDetails.totalNoOfUnitsOnHand || <i>No Info</i> }
];

const HostAndBuildingInfoModal = ({ buildingDetails, hostDetails, onCancel, onlyShowHostTab = false }) => {
  return (
    <Modal visible={true} footer={null} onCancel={onCancel}>
      <Tabs defaultActiveKey={onlyShowHostTab ? "hostInformation" : "buildingInformation"}>
        {!onlyShowHostTab && (
          <TabPane tab="Building Information" key="buildingInformation" style={{ marginBottom: '16px' }}>
            <Card>
              <List
                itemLayout="horizontal"
                dataSource={constructBuildingDataForList(buildingDetails)}
                renderItem={building => (
                  <Item>
                    <Meta title={building.label} description={building.value} />
                  </Item>
                )}
              />
            </Card>
          </TabPane>
        )}
        <TabPane tab="Host Information" key="hostInformation">
          <Card>
            <List
              itemLayout="horizontal"
              dataSource={constructHostDataForList(hostDetails)}
              renderItem={host => (
                <Item>
                  <Meta title={host.label} description={host.value} />
                </Item>
              )}
            />
          </Card>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default HostAndBuildingInfoModal;
