import React from 'react';

import { LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL } from 'utils/constants';
import { getColor } from 'utils/theme';

import styled, { css } from 'styled-components';

const getFontSize = props => {
  switch (props.labelSize) {
    case LABEL_SIZE_XXLARGE:
      return css`
        font-size: 40px;
        @media (min-width: 768px) {
          font-size: 40px;
        }
        @media (min-width: 1600px) {
          font-size: 64px;
        }
      `;
    case LABEL_SIZE_XLARGE:
      return css`
        font-size: 24px;
        @media (min-width: 768px) {
          font-size: 24px;
        }
        @media (min-width: 1600px) {
          font-size: 40px;
        }
      `;
    case LABEL_SIZE_LARGE:
      return css`
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        @media (min-width: 1600px) {
          font-size: 20px;
        }
      `;
    case LABEL_SIZE_REGULAR:
      return css`
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 12px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      `;
    case LABEL_SIZE_SMALL:
      return css`
        font-size: 10px;
        @media (min-width: 768px) {
          font-size: 10px;
        }
        @media (min-width: 1600px) {
          font-size: 14px;
        }
      `;
    default:
      return css`
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 12px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      `;
  }
};

const Root = styled.p`
  margin-bottom: 0px;
  text-align: ${props => props.align};
  color: ${getColor};
  font-weight: ${props => (props.bold ? 600 : 400)};
  ${getFontSize}
`;

const RequiredAsterisk = styled.span`
  float: left;
  margin-right: 4px;
  font-family: SimSun;
  color: #f5222d;
`;

const FormLabel = ({ className, children, hasRequiredErrorMessage, ...props }) => {
  return (
    <Root className={className} {...props}>
      {hasRequiredErrorMessage ? <RequiredAsterisk>*</RequiredAsterisk> : ''}
      {children}
    </Root>
  );
};

FormLabel.defaultProps = {
  hasRequiredErrorMessage: false,
  className: '',
  align: 'left',
  color: 'black',
  size: 'regular',
  bold: false
};

export default FormLabel;
