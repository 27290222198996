import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, message } from 'antd';
import styled from 'styled-components/macro';

import FormInput from 'components/FormInput/FormInput';
import FormLabel from 'components/FormLabel/FormLabel';
import UploadPhotos from 'components/UploadPhotos/UploadPhotos';

import { purifyPayload } from 'utils/general';

const StyledUploadPhotos = styled(UploadPhotos)`
  width: 100%;
`;

const UnitModal = ({ form, unit, isVisible, onSave, onCancel }) => {
  const [photos, setPhotos] = useState([]);
  const [isDefaultPhotoSet, setisDefaultPhotoSet] = useState(false);

  useEffect(() => {
    if (!isDefaultPhotoSet && unit.photos && unit.photos.length > 0) {
      const formattedPhotos = unit.photos.map(url => ({ url }));
      setPhotos(formattedPhotos);
      setisDefaultPhotoSet(true);
    }
  }, [isDefaultPhotoSet, unit.photos]);

  const handleOnSave = e => {
    e.preventDefault();
    e.stopPropagation();
    form.validateFields((err, values) => {
      if (!err) {
        if (photos.length <= 0) {
          message.error('Photo is required');
          return;
        }

        const newUnit = {
          _id: unit._id,
          name: values.name,
          photos: photos.map(photo => photo.url)
        };
        const purifiedValues = purifyPayload(newUnit);
        onSave(purifiedValues);
      }
    });
  };

  const handleOnPhotoCaptionChange = index => newCaption => {
    photos[index] = {
      ...photos[index],
      caption: newCaption
    };
    setPhotos([...photos]);
  };

  const handleOnPhotoDelete = index => e => {
    e.preventDefault();
    photos.splice(index, 1);
    setPhotos([...photos]);
  };

  const handleOnPhotoMakePrimary = index => e => {
    e.preventDefault();
    photos.unshift(photos[index]); // Move the photo to first element
    photos.splice(index + 1, 1); // Remove the photo
    setPhotos([...photos]);
  };

  const handleOnPhotoUploadComplete = (photoUrl) => {
    const uploadedPhoto = {
      url: photoUrl,
      caption: `Unit Image ${photos.length + 1}`
    };
    setPhotos([...photos, uploadedPhoto]);
  };

  return (
    <Modal maskClosable={false} visible={isVisible} onCancel={onCancel} footer={null} destroyOnClose>
      <Form onSubmit={handleOnSave}>
        <Row type="flex" gutter={24}>
          <Col span={24}>
            <FormInput
              form={form}
              name="name"
              label="Unit's Name"
              defaultValue={unit.name}
              placeholder="e.g. D-Suites"
              requiredErrorMessage="Please enter the unit's name"
            />
          </Col>
          <Col span={24}>
            <FormLabel hasRequiredErrorMessage>Images</FormLabel>
            <StyledUploadPhotos
              photos={photos}
              isFullWidth
              hasCaption={false}
              onCaptionChange={handleOnPhotoCaptionChange}
              onImageDelete={handleOnPhotoDelete}
              onImageMakePrimary={handleOnPhotoMakePrimary}
              onUploadFinish={handleOnPhotoUploadComplete}
            />
          </Col>
        </Row>
        <Row gutter={8} type="flex">
          <Col>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(UnitModal);
