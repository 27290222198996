import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, Alert, message, Modal, Icon } from 'antd';

import { useFetchConstant } from 'utils/hooks';
import { getUsersRoute } from 'utils/routes';

import { getUserById, postSubmitUser, putUpdateUser, deleteUser } from 'apis/user';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';

import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  margin-bottom: 20px !important;
`;

const StyledAlertDescrition = styled.p`
  margin-bottom: 0;
`;

const useFetchConstants = () => {
  const { selections: userRoles, isLoading: isUserRolesLoading } = useFetchConstant('roles', 'Error while getting user roles');

  const isConstantsLoading = useMemo(() => isUserRolesLoading, [isUserRolesLoading]);

  return { userRoles, isConstantsLoading };
};

const useFetchUser = userId => {
  const [isUserLoading, setisUserLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      let user = {};

      if (!!userId) {
        user = await getUserById(userId).catch(e => {
          console.error(e);
          message.error(e.message);

          return {};
        });
      }

      setUser(user);
      setisUserLoading(false);
    };

    fetchUser();
  }, [userId]);

  return { isUserLoading, user };
};

const handleOnSubmit = ({ form, userId, setIsButtonLoading, toUserListing }) => e => {
  e.preventDefault();
  form.validateFieldsAndScroll({ force: true }, (err, values) => {
    if (!err) {
      setIsButtonLoading(true);
      const newUser = {
        username: values.username,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role
      };

      if (!!userId) {
        putUpdateUser(userId, newUser)
          .then(() => {
            message.success('You have successfully updated this user from HostSearch.');
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      } else {
        postSubmitUser(newUser)
          .then(() => {
            message.success('You have successfully created a new user from HostSearch.');
            toUserListing();
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      }
    }
  });
};

const handleOnDelete = ({ userId, setIsButtonLoading, toUserListing }) => e => {
  setIsButtonLoading(true);

  e.preventDefault();
  Modal.confirm({
    title: 'Are you sure you want to delete this user?',
    content: 'This action cannot be undone.',
    icon: <Icon type="warning" theme="twoTone" twoToneColor="red" />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      deleteUser(userId)
        .then(() => {
          message.success('You have successfully deleted this user from HostSearch.');
          toUserListing();
        })
        .catch(e => {
          console.error(e);
          message.error(e.message);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
    onCancel() {
      setIsButtonLoading(false);
    }
  });
};

const UserForm = ({ className, history, match, form }) => {
  const toUserListing = () => history.push(getUsersRoute().path);
  const userId = useMemo(() => match.params.id, [match.params.id]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { userRoles, isConstantsLoading } = useFetchConstants();

  const { isUserLoading, user } = useFetchUser(userId);

  const isLoading = useMemo(() => isConstantsLoading || isUserLoading, [isConstantsLoading, isUserLoading]);

  return (
    <Card onClose={toUserListing} loading={isLoading}>
      <Form className={className}>
        <Card title="User Details">
          <StyledAlert
            message="User Details Configuration"
            description={
              <div>
                <StyledAlertDescrition>
                  Both Username and Email are required to match with the HostPlatform user in order to sync the user with HostSearch.
                </StyledAlertDescrition>
              </div>
            }
            type="info"
            showIcon
          />

          <Row>
            <FormInput
              form={form}
              name="username"
              label="Username"
              defaultValue={user.username}
              placeholder="e.g. jansonchah"
              requiredErrorMessage="Please enter a username"
              isDisabled={!!userId}
              isNotAllowUpperCase
            />
          </Row>
          <Row>
            <FormInput
              form={form}
              name="email"
              label="Email"
              defaultValue={user.email}
              placeholder="e.g. janson@hostastay.com"
              requiredErrorMessage="Please enter an email address"
              isDisabled={!!userId}
              isEmail
            />
          </Row>
          <Row type="flex" gutter={64}>
            <Col span={12}>
              <FormInput
                form={form}
                name="firstName"
                label="First Name"
                placeholder="e.g. John/Jane"
                defaultValue={user.firstName}
                requiredErrorMessage="Please enter the user's first name"
              />
            </Col>
            <Col span={12}>
              <FormInput
                form={form}
                name="lastName"
                label="Last Name"
                defaultValue={user.lastName}
                placeholder="e.g. Doe"
                requiredErrorMessage="Please enter the user's last name"
              />
            </Col>
          </Row>
          <Row>
            <FormSelection
              form={form}
              name="role"
              label="Role"
              selections={userRoles}
              defaultValue={user.role}
              placeholder="Select a role"
              requiredErrorMessage="Please select a role for this user"
            />
          </Row>
        </Card>

        <Row type="flex" justify="start" gutter={8}>
          <Col>
            <Button
              type="primary"
              size="large"
              loading={isButtonLoading}
              onClick={handleOnSubmit({ form, userId, setIsButtonLoading, toUserListing })}
            >
              Save
            </Button>
          </Col>
          {user && (
            <Col>
              <Button type="danger" size="large" loading={isButtonLoading} onClick={handleOnDelete({ userId, setIsButtonLoading, toUserListing })}>
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default withRouter(Form.create()(UserForm));
