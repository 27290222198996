import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import Card from 'components/Card/Card';
import Description from 'components/Description/Description';
import image from './images/register-property-bungalow-semiD.png';

const PropertyCard = ({ propertyName, propertyAddress, className, onDeleteClick, onEditClick, canEdit, ...props }) => {
  return (
    <Card
      bodyStyle={{ minHeight: 235 }}
      cover={<img alt="property" src={image} />}
      actions={
        canEdit
          ? [<Icon type="edit" onClick={onEditClick} />, <Icon type="delete" onClick={onDeleteClick} />]
          : [<Icon type="eye" onClick={onEditClick} />]
      }
    >
      <Description size="regular">{propertyName}</Description>
      <Description size="small">{propertyAddress}</Description>
    </Card>
  );
};

PropertyCard.propTypes = {
  propertyName: PropTypes.string.isRequired,
  propertyAddress: PropTypes.string,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
};

PropertyCard.defaultProps = {
  propertyAddress: '-',
  canEdit: true
};

export default PropertyCard;
