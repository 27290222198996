import React, { useState, useEffect } from 'react';

import { postLogin } from 'apis/auth';
import { updateApiHeaders } from 'apis/apiHelper';
import { getUser, getAuthToken, getIsUserLoggedIn, setUserAuthObj, deleteUserAuthObj } from 'utils/auth';
import { useFetchConstant } from 'utils/hooks';

const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState('');
  const [isLoadingUser, setIsLoading] = useState(true);
  const { originalSelection: rolesObj, selections: roles, isLoading: isRolesLoading } = useFetchConstant('roles');

  useEffect(() => {
    if (getIsUserLoggedIn()) {
      const storedUser = getUser();
      const storedToken = getAuthToken();
      setUser(storedUser);
      setToken(storedToken);
    }
    setIsLoading(false);
  }, []);

  const isUserLoading = isLoadingUser || isRolesLoading;

  const isLoggedIn = !!user && !!user.username;

  const isAdmin = rolesObj && rolesObj.ADMIN && user && user.role === rolesObj.ADMIN.code;

  const checkCanUserAccess = roleToAccess => {
    if (!roleToAccess || user.role === roleToAccess) {
      return true;
    } else {
      const foundUserRole = roles.find(role => {
        return role.code === user.role;
      });
      return !!foundUserRole && foundUserRole.access.includes(roleToAccess);
    }
  };

  const checkIsUserPIC = picId => {
    return user._id === picId;
  }

  const onLogin = (username, password) => {
    setIsLoading(true);
    return postLogin(username, password)
      .then(userData => {
        setUserAuthObj(userData);
        setUser(userData.user);
        setToken(userData.token);
        updateApiHeaders();
        return userData;
      })
      .finally(() => setIsLoading(false));
  };

  const onLogout = () => {
    deleteUserAuthObj();
    setUser({});
    setToken('');
    updateApiHeaders();
  };

  return (
    <AppContext.Provider
      value={{
        checkCanUserAccess,
        checkIsUserPIC,
        isLoggedIn,
        isAdmin,
        isUserLoading,
        onLogin,
        onLogout,
        token,
        user
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const AppContextConsumer = AppContext.Consumer;

const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};

export { AppContextProvider, AppContextConsumer, withAppContext };
