import React from 'react';
import { ThemeProvider } from 'styled-components';

export const WrappedOriginalThemeComponent = Component => props => (
  <ThemeProvider theme={original}>
    <Component {...props} />
  </ThemeProvider>
);

export const original = {
  color: {
    primary: '#00b9c6',
    secondary: 'yellow',

    white: '#ffffff',

    lightGrey: '#989898',
    grey: '#939393',
    darkGrey: '#565656',
    darkPrimary: '#13547a',
    black: '#4f4f4f',
    error: '#f5222d',
  },
  header: {
    mobile: {
      iconHeight: 48,
      paddingY: 24
    },
    desktop: {
      iconHeight: 72,
      paddingY: 24
    }
  },
  padding: {
    contentXPercent: 8
  }
};

export const getColor = props => {
  const defaultColor = 'black';
  if (props.theme && props.theme.color && props.color) {
    return props.theme.color[props.color] || defaultColor;
  } else {
    return defaultColor;
  }
};
