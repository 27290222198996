const getRoute = (path, role) => ({ path, role });

export const getDashboardRoute = () => getRoute('/dashboard', 'sales');

export const getEnquiriesRoute = () => getRoute('/enquiries', 'sales');
export const getNewEnquiryRoute = () => getRoute('/enquiries/new', 'sales');
export const getEnquiryRoute = (id = ':id') => getRoute(`/enquiries/${id}`, 'sales');

export const getCampaignHostsRoute = () => getRoute('/campaign-hosts', 'admin');
export const getNewCampaignHostRoute = () => getRoute('/campaign-hosts/new', 'admin');
export const getCampaignHostRoute = (id = ':id') => getRoute(`/campaign-hosts/${id}`, 'admin');

export const getHostsRoute = () => getRoute('/hosts', 'sales');
export const getNewHostRoute = () => getRoute('/hosts/new', 'sales');
export const getHostRoute = (id = ':id') => getRoute(`/hosts/${id}`, 'sales');

export const getUsersRoute = () => getRoute('/users', 'admin');
export const getUserRoute = (id = ':id') => getRoute(`/users/${id}`, 'admin');
export const getNewUserRoute = () => getRoute('/users/new', 'admin');

export const getSalesReportRoute = () => getRoute('/reports', 'admin');

export const getActivityLogsRoute = () => getRoute('/activitylogs', 'admin');

export const getBuildingsRoute = () => getRoute('/buildings', 'sales');
export const getNewBuildingRoute = () => getRoute('/buildings/new', 'sales');
export const getBuildingRoute = (id = ':id') => getRoute(`/buildings/${id}`, 'sales');

export const getPartnerListingsRoute = () => getRoute('/partner-listings', 'sales');

export const getLoginRoute = () => getRoute('/login', '');

export const getForbiddenRoute = () => getRoute('/403', '');
export const getNotFoundRoute = () => getRoute('/404', '');
