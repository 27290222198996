import React, { useEffect } from 'react';
import DataSet from '@antv/data-set';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';
import PropTypes from 'prop-types';

const Histogram = ({ height, graphData, xAxisName, yAxisName }) => {
  useEffect(() => {
    // Refer to this issue: https://github.com/alibaba/BizCharts/issues/279#issuecomment-400438180
    window.dispatchEvent(new Event('resize'));
  }, []);

  const ds = new DataSet();
  const dv = ds.createView().source(graphData);

  return (
    <Chart height={height} data={dv} forceFit>
      <Legend
        textStyle={{
          fontSize: '14'
        }}
      />
      <Axis name={xAxisName} />
      <Axis name={yAxisName} />
      <Tooltip />
      <Geom type="interval" position={`${xAxisName}*${yAxisName}`} color={xAxisName} />
    </Chart>
  );
};

Histogram.propTypes = {
  graphData: PropTypes.array.isRequired,
  height: PropTypes.number,
  title: PropTypes.string,
  fields: PropTypes.array,
};

Histogram.defaultProps = {
  height: 400,
  title: '',
  fields: []
}

export default Histogram;
