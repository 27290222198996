import React from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Button, Col, Form, Row } from 'antd';
import styled from 'styled-components/macro';

import FormSelection from 'components/FormSelection/FormSelection';
import LocationAutoSearchGoogleMap from 'components/LocationAutoSearchGoogleMap/LocationAutoSearchGoogleMap';

const FilterRow = styled(Row)`
  margin-bottom: 16px;
`;

const getAddressLatLng = address =>
  geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .catch(() => {});

const handleOnSelectGoogleAddress = form => ({ fullAddress }) => {
  form.setFieldsValue({ filterAddress: fullAddress });
};

const handleOnResetClick = (form, onResetClick) => () => {
  form.setFieldsValue({ filterAddress: undefined, filterState: 'all', filterHost: 'all', filterHostType: 'all', filterPartnershipStatus: 'all' });
  onResetClick();
};

const handleOnSearchClick = (form, onSearchClick) => () => {
  form.validateFieldsAndScroll({ force: true }, async (err, values) => {
    if (!err) {
      const address = values.filterAddress;
      const selectedState = values.filterState;
      const inputHostId = values.filterHost;
      const selectedHostType = values.filterHostType;
      const selectedPartnershipStatus = values.filterPartnershipStatus;

      const addressLatLng = await getAddressLatLng(address);

      onSearchClick(addressLatLng, selectedState, inputHostId, selectedHostType, selectedPartnershipStatus);
    }
  });
};

const Filters = ({ form, states, hostsFromHP, hostTypes, partnershipStatuses, onResetClick, onSearchClick }) => (
  <Form>
    <FilterRow>
      <LocationAutoSearchGoogleMap form={form} label="Address" name="filterAddress" onSelectGoogleAddress={handleOnSelectGoogleAddress(form)} />
    </FilterRow>
    <FilterRow gutter={8}>
      <Col span={24} md={12}>
        <FormSelection form={form} label="Filter by State" name="filterState" defaultValue="all" selections={states} isAllowClear={false} hasAll />
      </Col>
      <Col span={24} md={12}>
        <FormSelection
          form={form}
          label="Filter by Host Name"
          name="filterHost"
          defaultValue="all"
          selections={hostsFromHP}
          isAllowClear={false}
          hasAll
        />
      </Col>
    </FilterRow>
    <FilterRow gutter={8}>
      <Col span={24} md={12}>
        <FormSelection
          form={form}
          label="Filter by Host Type"
          name="filterHostType"
          defaultValue="all"
          selections={hostTypes}
          isAllowClear={false}
          hasAll
        />
      </Col>
      <Col span={24} md={12}>
        <FormSelection
          form={form}
          label="Filter by Partnership Status"
          name="filterPartnershipStatus"
          defaultValue="all"
          selections={partnershipStatuses}
          isAllowClear={false}
          hasAll
        />
      </Col>
    </FilterRow>
    <FilterRow>
      <Button type="secondary" onClick={handleOnResetClick(form, onResetClick)} style={{ marginRight: '8px' }}>
        Reset All
      </Button>
      <Button type="primary" onClick={handleOnSearchClick(form, onSearchClick)}>
        Search Buildings
      </Button>
    </FilterRow>
  </Form>
);

export default Form.create()(Filters);
