import axios from 'axios';
import env from 'config/env';
import { getIsUserLoggedIn, getAuthToken } from 'utils/auth';
import history from 'utils/browserHistory';
import { getForbiddenRoute } from 'utils/routes';

const UNAUTHORISED_STATUS_CODE = 401;

const forbidden = getForbiddenRoute();

export const getAuthHeader = () => {
  if (getIsUserLoggedIn()) {
    return {
      Authorization: getAuthToken()
    };
  }
  return {};
};

const api = axios.create({
  baseURL: env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader()
  }
});

const apiErrorHandler = e => {
  if (e.response && e.response.data) {
    if (e.response.data.code) {
      throw e.response.data;
    } else if (e.response.data.message) {
      throw new Error(e.response.data.message);
    } else {
      throw new Error(e.response.data);
    }
  } else if (e.message) {
    throw e;
  } else {
    throw new Error('Unexpected error occured when calling api. Please contact admin.');
  }
};

const apiErrorHandlerWithRedirect = e => {
  const userTokensIssuesErrorCodes = [UNAUTHORISED_STATUS_CODE];
  if (userTokensIssuesErrorCodes.includes(e.response.status)) {
    history.push(forbidden.path);
  }
  apiErrorHandler(e);
};

export const updateApiHeaders = () => {
  api.defaults.headers = {
    'Content-Type': 'application/json',
    ...getAuthHeader()
  };
};

api.interceptors.response.use(res => res.data, apiErrorHandlerWithRedirect);

export default api;

export const nonAuthApi = axios.create({
  baseURL: env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
});

nonAuthApi.interceptors.response.use(res => res.data, apiErrorHandler);
