import React, { useState, useMemo } from 'react';
import { Button, Form, message, Skeleton, Row } from 'antd';
import { withRouter } from 'react-router-dom';

import { postSubmitEnquiry } from 'apis/enquiry';
import { withAppContext } from 'contexts/AppContext/AppContext';

import Card from 'components/Card/Card';
import CreateOrLinkHostModal from 'components/CreateOrLinkHostModal/CreateOrLinkHostModal';
import CloseEnquiryModal from 'components/CloseEnquiryModal/CloseEnquiryModal';

import { DATE_FORMAT } from 'utils/constants';
import { useFetchConstant, useFetchUsersForSelection } from 'utils/hooks';
import { purifyPayload, constructCommentPayload } from 'utils/general';
import { getEnquiriesRoute } from 'utils/routes';

import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import BuildingInfo from './components/BuildingInfo/BuildingInfo';
import OtherInfo from './components/OtherInfo/OtherInfo';
import NewEnquirySummaryModal from './components/NewEnquirySummaryModal/NewEnquirySummaryModal';

const enquiriesRoute = getEnquiriesRoute();

const constructEmptyEnquiry = () => {
  return {
    enquirer: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      contact: {
        countryCode: undefined,
        contactNumber: undefined
      },
      companyName: undefined,
      identity: undefined,
      streetAddress: undefined,
      country: undefined,
      state: undefined,
      city: undefined,
      zipcode: undefined
    },
    source: undefined,
    types: undefined,
    priority: undefined,
    status: undefined,
    detail: undefined,
    remarks: undefined,
    internalRemarks: undefined,
    ticketNumber: undefined,
    buildings: undefined,
    parentTicket: undefined,
    followUpDate: undefined,
    forecast: {
      price: undefined,
      date: undefined
    }
  };
};

const createEnquiry = ({ values, buildings }) => {
  const newEnquiry = purifyPayload({
    enquirer: {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contact: {
        countryCode: values.contactCountryCode,
        contactNumber: values.contactNumber
      },
      companyName: values.companyName,
      identity: values.identity,
      streetAddress: values.streetAddress,
      country: values.country,
      state: values.state,
      city: values.city,
      zipcode: values.zipcode
    },
    assignedTo: values.assignedTo,
    source: values.source,
    types: values.enquiryType,
    priority: values.priority,
    status: values.status,
    detail: values.detail,
    remarks: values.customerRemarks,
    internalRemarks: values.enquiryRemarks,
    parentTicket: values.parentTicketNumber,
    followUpDate: values.followUpDate.format(DATE_FORMAT),
    forecast: {
      price: values.forecastPrice,
      date: values.forecastDate && values.forecastDate.format(DATE_FORMAT)
    },
    buildings,
    linkTo: values.host,
    commentDetails: values.comment ? constructCommentPayload(values.status, values.comment) : undefined
  });

  return postSubmitEnquiry(newEnquiry)
    .then(newEnquiry => {
      message.success('You have successfully created a new enquiry.');
      return newEnquiry;
    })
    .catch(e => {
      console.error(e);
      message.error('Error while submitting enquiry.');
    });
};

const useFetchConstants = isAdmin => {
  const { selections: enquirySources, isLoading: isEnquirySourcesLoading } = useFetchConstant(
    'enquirySources',
    'Error while getting acquisition sources.'
  );
  const { selections: enquiryTypes, isLoading: isEnquiryTypesLoading } = useFetchConstant('enquiryTypes', 'Error while getting enquiry types.');
  const { selections: enquirerIdentities, isLoading: isEnquirerIdentitiesLoading, originalSelection: originalEnquirerIdentities } = useFetchConstant(
    'enquirerIdentities',
    'Error while getting enquiry identities.'
  );
  const { selections: priorityTypes, isLoading: isPriorityTypesLoading } = useFetchConstant('priorities', 'Error while getting priority types.');
  const { selections: enquiryStatuses, originalSelection: originalEnquiryStatuses, isLoading: isEnquiryStatusesLoading } = useFetchConstant(
    'enquiryStatuses',
    'Error while getting enquiry statuses.'
  );
  const { selections: countryCodes, originalSelection: originalCountries, isLoading: isCountryCodesLoading } = useFetchConstant(
    'countries',
    'Error while getting countryCodes.',
    'phoneCode',
    'phoneCode'
  );
  const countries = useMemo(() => {
    return Object.values(originalCountries).map(selection => ({
      value: selection['iso2'],
      label: selection['name']
    }));
  }, [originalCountries]);

  const { selections: buildingTypes, isLoading: isBuildingTypesLoading } = useFetchConstant('buildingTypes', 'Error while getting building types');
  const { selections: buildingTenures, isLoading: isBuildingTenuresLoading } = useFetchConstant(
    'buildingTenures',
    'Error while getting building tenures'
  );
  const { selections: buildingTitles, isLoading: isBuildingTitlesLoading } = useFetchConstant(
    'buildingTitles',
    'Error while getting building titles'
  );
  const { selections: furnishingStatuses, isLoading: isFurnishingStatusesLoading } = useFetchConstant(
    'furnishingStatuses',
    'Error while getting furnishing statuses'
  );
  const { selections: states, isLoading: isStatesLoading } = useFetchConstant('statesMY', 'Error while getting furnishing statuses');
  const { users: listOfPIC, isLoading: isListOfPICLoading } = useFetchUsersForSelection(!!isAdmin);

  const isConstantsLoading = useMemo(
    () =>
      isEnquirySourcesLoading ||
      isEnquiryTypesLoading ||
      isEnquirerIdentitiesLoading ||
      isPriorityTypesLoading ||
      isEnquiryStatusesLoading ||
      isCountryCodesLoading ||
      isBuildingTypesLoading ||
      isBuildingTenuresLoading ||
      isBuildingTitlesLoading ||
      isFurnishingStatusesLoading ||
      isStatesLoading ||
      isListOfPICLoading,
    [
      isEnquirySourcesLoading,
      isEnquiryTypesLoading,
      isPriorityTypesLoading,
      isEnquirerIdentitiesLoading,
      isEnquiryStatusesLoading,
      isCountryCodesLoading,
      isBuildingTypesLoading,
      isBuildingTenuresLoading,
      isBuildingTitlesLoading,
      isFurnishingStatusesLoading,
      isStatesLoading,
      isListOfPICLoading
    ]
  );

  return {
    isConstantsLoading,
    enquirySources,
    enquiryTypes,
    enquirerIdentities,
    originalEnquirerIdentities,
    priorityTypes,
    enquiryStatuses,
    originalEnquiryStatuses,
    countryCodes,
    countries,
    buildingTypes,
    buildingTenures,
    buildingTitles,
    furnishingStatuses,
    states,
    listOfPIC
  };
};

const EnquiryFormCreate = ({ form, history, isAdmin }) => {
  const enquiry = useMemo(() => constructEmptyEnquiry(), []);
  const [buildings, setBuildings] = useState([]);
  const [newlyCreatedEnquiry, setNewlyCreatedEnquiry] = useState({});

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isCreateOrLinkHostModalVisible, setIsCreateOrLinkHostModalVisible] = useState(false);

  const isNewEnquiryModalVisbile = Object.keys(newlyCreatedEnquiry).length > 0;
  const {
    isConstantsLoading,
    enquirySources,
    enquiryTypes,
    enquirerIdentities,
    originalEnquirerIdentities,
    priorityTypes,
    enquiryStatuses,
    originalEnquiryStatuses,
    countryCodes,
    countries,
    buildingTypes,
    buildingTenures,
    buildingTitles,
    furnishingStatuses,
    listOfPIC,
    states
  } = useFetchConstants(isAdmin);

  const handleOnSubmit = e => {
    e.preventDefault();
    const closeStatuses = !isConstantsLoading ? [originalEnquiryStatuses.END.code, originalEnquiryStatuses.DEAL.code] : [];

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        if (closeStatuses.includes(values.status)) {
          setIsConfirmModalVisible(true);
        } else {
          setIsButtonLoading(true);
          createEnquiry({ values, buildings })
            .then(setNewlyCreatedEnquiry)
            .finally(() => {
              setIsButtonLoading(false);
            });
        }
      }
    });
  };

  const handleOnCloseEnquiryWithHost = e => {
    e.preventDefault();

    const handleOnModalClose = () => {
      setIsCreateOrLinkHostModalVisible(false);
      setIsButtonLoading(false);
    };

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        setIsButtonLoading(true);
        createEnquiry({ values, buildings })
          .then(setNewlyCreatedEnquiry)
          .finally(handleOnModalClose);
      } else {
        handleOnModalClose();
      }
    });
  };

  const handleOnCloseEnquiry = e => {
    e.preventDefault();
    const hostIdentityInString = isConstantsLoading || originalEnquirerIdentities.HOST.code;

    const handleOnModalClose = () => {
      setIsConfirmModalVisible(false);
      setIsButtonLoading(false);
    };

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        setIsButtonLoading(true);
        if (values.identity === hostIdentityInString) {
          setIsCreateOrLinkHostModalVisible(true);
          handleOnModalClose();
        } else {
          createEnquiry({ values, buildings })
            .then(setNewlyCreatedEnquiry)
            .finally(handleOnModalClose);
        }
      }
    });
  };

  return (
    <Skeleton active loading={isConstantsLoading}>
      <Card onClose={() => history.push(enquiriesRoute.path)}>
        <Form>
          <GeneralInfo
            form={form}
            canEdit={true}
            countries={countries}
            states={states}
            enquiry={enquiry}
            enquirySources={enquirySources}
            enquiryTypes={enquiryTypes}
            enquirerIdentities={enquirerIdentities}
            listOfPIC={listOfPIC}
            priorityTypes={priorityTypes}
            countryCodes={countryCodes}
            shouldShowPICSelection={isAdmin}
          />
          <BuildingInfo
            canEdit={true}
            buildings={buildings}
            buildingTypes={buildingTypes}
            buildingTenures={buildingTenures}
            buildingTitles={buildingTitles}
            countries={countries}
            states={states}
            furnishingStatuses={furnishingStatuses}
            onBuildingsChange={setBuildings}
          />
          <OtherInfo form={form} canEdit={true} enquiry={enquiry} enquiryStatuses={enquiryStatuses} isCreatingNewEnquiry />

          <Row type="flex" justify="start">
            <Button type="primary" size="large" loading={isButtonLoading} onClick={handleOnSubmit}>
              Save
            </Button>
          </Row>
        </Form>
        <NewEnquirySummaryModal
          enquiry={newlyCreatedEnquiry}
          onClickCreateAnotherEnquiry={() => window.location.reload()}
          visible={isNewEnquiryModalVisbile}
        />
        <CloseEnquiryModal
          visible={isConfirmModalVisible}
          onOk={handleOnCloseEnquiry}
          onCancel={() => {
            setIsButtonLoading(false);
            setIsConfirmModalVisible(false);
          }}
        />
        <CreateOrLinkHostModal
          visible={isCreateOrLinkHostModalVisible}
          form={form}
          onOk={handleOnCloseEnquiryWithHost}
          onCancel={() => {
            setIsButtonLoading(false);
            setIsCreateOrLinkHostModalVisible(false);
          }}
        />
      </Card>
    </Skeleton>
  );
};

export default withAppContext(withRouter(Form.create()(EnquiryFormCreate)));
