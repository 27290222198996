import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Icon } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import { REACT_APP_API_ENDPOINT, REACT_APP_IMAGE_S3_BUCKET_URL } from 'config/env';
import { getAuthHeader } from 'apis/apiHelper';

const MAX_IMAGE_SIZE = 1024 * 1024 * 5;

const Root = styled.div`
  display: flex;
  width: 100% !important;
  height: 100% !important;
  padding: 20px;
`;

const InnerRoot = styled.div`
  margin: auto;
`;

const IconContainer = styled.p`
  font-size: 56px;
  margin-bottom: 0;
  color: #00b9c6 !important;
`;

const Title = styled.p`
  color: #424242;
  margin-bottom: 2px;
`;

const StyledDropzoneS3Uploader = styled(DropzoneS3Uploader)`
  display: block;
  width: 100% !important;
  height: 100% !important;
  border: 1px dashed #d9d9d9 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  text-align: center !important;
  background: #fafafa !important;

  &:hover {
    border-color: ${props => props.theme.color.primary} !important;
  }
`;

const DefaultUpload = () => {
  return (
    <Root>
      <InnerRoot>
        <IconContainer>
          <Icon type="picture" />
        </IconContainer>
        <Title>Click or drag image(s) to this area to upload</Title>
      </InnerRoot>
    </Root>
  );
};

const UploadPhotoContainer = ({ onUploadFinish, children }) => {
  const uploadOptions = {
    server: REACT_APP_API_ENDPOINT,
    signingUrlQueryParams: { uploadType: 'avatar' },
    signingUrlHeaders: { ...getAuthHeader() }
  };

  const handleOnFinish = e => {
    onUploadFinish(e.fileUrl);
  };

  return (
    <StyledDropzoneS3Uploader s3Url={REACT_APP_IMAGE_S3_BUCKET_URL} upload={uploadOptions} onFinish={handleOnFinish} maxSize={MAX_IMAGE_SIZE}>
      {children ? children : <DefaultUpload />}
    </StyledDropzoneS3Uploader>
  );
};

UploadPhotoContainer.propTypes = {
  onUploadFinish: PropTypes.func.isRequired,
  children: PropTypes.node
};

UploadPhotoContainer.defaultProps = {
  onUploadFinish: () => {}
};

export default UploadPhotoContainer;
