import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import styled from 'styled-components/macro';

import UploadPhotoContainer from './components/UploadPhotoContainer/UploadPhotoContainer';
import PhotoDisplayCard from './components/PhotoDisplayCard/PhotoDisplayCard';

const StyledPhotoContainer = styled(Col)`
  margin-bottom: 38px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: 768px) {
    &:last-child {
      margin-bottom: 38px;
    }
  }
`;

const UploadPhotos = ({ className, photos, onCaptionChange, onImageDelete, onImageMakePrimary, onUploadFinish, isFullWidth, hasCaption }) => {
  const photoDisplaySpan = isFullWidth
    ? {
        span: 24
      }
    : {
        span: 24,
        md: 12,
        lg: 8
      };
  const photoUploadSpan = isFullWidth
    ? {
        span: 24
      }
    : {
        span: 24,
        md: photos.length > 0 ? 12 : 24,
        lg: photos.length > 0 ? 8 : 24
      };

  return (
    <Row type="flex" className={className} gutter={{ xs: 0, md: 8, lg: 16 }}>
      {photos.map((photo, index) => (
        <StyledPhotoContainer key={photo.url} {...photoDisplaySpan}>
          <PhotoDisplayCard
            key={index}
            hasCaption={hasCaption}
            imageUrl={photo.url}
            imageCaption={hasCaption ? photo.caption : undefined}
            onCaptionChange={hasCaption ? onCaptionChange(index) : undefined}
            onDelete={onImageDelete(index)}
            onMakePrimaryClick={onImageMakePrimary(index)}
          />
        </StyledPhotoContainer>
      ))}
      <StyledPhotoContainer {...photoUploadSpan}>
        <UploadPhotoContainer onUploadFinish={onUploadFinish} />
      </StyledPhotoContainer>
    </Row>
  );
};

UploadPhotos.propTypes = {
  className: PropTypes.string,
  hasCaption: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  photos: PropTypes.array,
  onCaptionChange: PropTypes.func,
  onImageDelete: PropTypes.func.isRequired,
  onImageMakePrimary: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
};

UploadPhotos.defaultProps = {
  isFullWidth: false,
  hasCaption: true,
  photos: [],
  onCaptionChange: () => {},
  onImageDelete: () => {},
  onImageMakePrimary: () => {},
  onUploadFinish: () => {},
};

export default UploadPhotos;
