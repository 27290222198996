import React, { useState } from 'react';
import { Modal, Row, Col, Button } from 'antd';

import { BareSelect } from 'components/FormSelection/FormSelection';

const ModalActionButtons = ({ onCancel, onConfirm }) => {
  return (
    <Row gutter={8} type="flex">
      <Col>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Col>
      <Col>
        <Button type="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Col>
    </Row>
  );
};

const AssignHostModal = ({ visible, hostOptions, onCancel, onConfirm }) => {
  const [selectedHost, setSelectedHost] = useState(hostOptions[0].value);

  const handleOnHostChange = host => {
    setSelectedHost(host);
  };

  return (
    <Modal visible={visible} maskClosable={false} destroyOnClose footer={null} title="Assign Host" onCancel={onCancel}>
      <BareSelect style={{ width: '100%', marginBottom: 24 }} options={hostOptions} defaultValue={selectedHost} onChange={handleOnHostChange} />
      <ModalActionButtons onCancel={onCancel} onConfirm={onConfirm(selectedHost)} />
    </Modal>
  );
};

export default AssignHostModal;
