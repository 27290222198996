import React, { useEffect, useState } from 'react';
import { Form, Modal, message, notification, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';

import { patchUpdateEnquiryOtherInfo, getEnquiryById } from 'apis/enquiry';

import { DATE_FORMAT } from 'utils/constants';
import { useFetchConstant } from 'utils/hooks';
import { purifyPayload } from 'utils/general';

import CloseEnquiryModal from 'components/CloseEnquiryModal/CloseEnquiryModal';
import CreateOrLinkHostModal from 'components/CreateOrLinkHostModal/CreateOrLinkHostModal';

import QuickUpdateContent from './components/QuickUpdateContent/QuickUpdateContent';

const useFetchEnquiry = enquiryId => {
  const [isEnquiryLoading, setIsEnquiryLoading] = useState(true);
  const [isEnquiryError, setIsEnquiryError] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let enquiry = {};
      if (!!enquiryId) {
        enquiry = await getEnquiryById(enquiryId).catch(e => {
          console.error(e);
          message.error(e.message);
          setIsEnquiryError(true);
          return { error: e };
        });
      }
      setSelectedEnquiry(enquiry);
      setIsEnquiryLoading(!!enquiry.error || false);
    };

    fetchData();
  }, [enquiryId]);

  return { isEnquiryLoading, isEnquiryError, selectedEnquiry, setSelectedEnquiry };
};

const successNotification = () =>
  notification.success({
    duration: 3,
    message: 'Success',
    description: "You've successfully update this ticket."
  });

const errorNotification = () =>
  notification.error({
    duration: 3,
    message: 'Oops!',
    description: 'Curently unable to update this ticket. Please try again later.'
  });

const QuickUpdateModal = ({ form, showModal, selectedEnquiryId, closeModal, onQuickUpdateSuccess }) => {
  const { isEnquiryLoading, isEnquiryError, selectedEnquiry, setSelectedEnquiry } = useFetchEnquiry(selectedEnquiryId);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isCreateOrLinkHostModalVisible, setIsCreateOrLinkHostModalVisible] = useState(false);

  useEffect(() => {
    if (isEnquiryError) {
      closeModal();
    }
  }, [isEnquiryError, closeModal]);

  const { isLoading: isEnquirerIdentitiesLoading, originalSelection: originalEnquirerIdentities } = useFetchConstant(
    'enquirerIdentities',
    'Error while getting enquiry identities.'
  );
  const { isLoading: isEnquiryStatusesLoading, selections: enquiryStatuses, originalSelection: originalEnquiryStatuses } = useFetchConstant(
    'enquiryStatuses',
    'Error while getting enquiry statuses.'
  );

  const enquiryId = selectedEnquiry._id;
  const isConstantsLoading = isEnquirerIdentitiesLoading || isEnquiryStatusesLoading;
  const isEnquiryClosed =
    isEnquiryStatusesLoading ||
    originalEnquiryStatuses.END.code === selectedEnquiry.status ||
    originalEnquiryStatuses.DEAL.code === selectedEnquiry.status;

  const canEdit = !isEnquiryLoading && !isEnquiryClosed;

  const patchEnquiry = ({ enquiryId, values }) => {
    const payload = purifyPayload({
      status: values.status,
      internalRemarks: values.enquiryRemarks,
      forecast: {
        price: values.forecastPrice,
        date: values.forecastDate && values.forecastDate.format(DATE_FORMAT)
      },
      linkTo: values.host
    });

    return patchUpdateEnquiryOtherInfo(enquiryId, payload)
      .then(patchedEnquiry => {
        successNotification();
        onQuickUpdateSuccess();
        return patchedEnquiry;
      })
      .catch(e => {
        console.error(e);
        errorNotification();
      });
  };

  const handleOnUpdateButtonClicked = e => {
    e.preventDefault();
    const closeStatuses = !isConstantsLoading ? [originalEnquiryStatuses.END.code, originalEnquiryStatuses.DEAL.code] : [];
    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        if (closeStatuses.includes(values.status)) {
          setIsConfirmModalVisible(true);
        } else {
          patchEnquiry({ enquiryId, values }).then(updatedEnquiry => setSelectedEnquiry(updatedEnquiry));
        }
      } else {
        errorNotification();
        closeModal();
      }
    });
  };

  const handleOnCloseEnquiryWithHost = e => {
    e.preventDefault();
    const handleOnModalClose = () => {
      setIsCreateOrLinkHostModalVisible(false);
      closeModal();
    };
    const values = form.getFieldsValue();
    patchEnquiry({ enquiryId, values }).finally(handleOnModalClose);
  };

  const handleOnCloseEnquiry = e => {
    e.preventDefault();
    const hostIdentityInString = !isConstantsLoading && originalEnquirerIdentities.HOST.code;

    const handleOnModalClose = () => {
      setIsConfirmModalVisible(false);
    };

    const values = form.getFieldsValue();

    if (selectedEnquiry.enquirer.identity === hostIdentityInString) {
      setIsCreateOrLinkHostModalVisible(true);
      handleOnModalClose();
    } else {
      patchEnquiry({ enquiryId, values }).finally(handleOnModalClose);
      closeModal();
    }
  };
  return (
    <Modal
      title={`Quick update for ${isEnquiryLoading ? 'this ticket' : selectedEnquiry.ticketNumber}`}
      visible={showModal}
      onOk={handleOnUpdateButtonClicked}
      onCancel={closeModal}
      okText="Update this Ticket"
      okButtonProps={{ disabled: !canEdit }}
      destroyOnClose
    >
      {isEnquiryLoading ? (
        <Skeleton />
      ) : (
        <Form>
          <QuickUpdateContent form={form} canEdit={canEdit} enquiry={selectedEnquiry} enquiryStatuses={enquiryStatuses} />
          <CloseEnquiryModal
            visible={isConfirmModalVisible}
            onOk={handleOnCloseEnquiry}
            onCancel={() => {
              setIsConfirmModalVisible(false);
            }}
          />
          <CreateOrLinkHostModal
            visible={isCreateOrLinkHostModalVisible}
            form={form}
            onOk={handleOnCloseEnquiryWithHost}
            onCancel={() => {
              setIsCreateOrLinkHostModalVisible(false);
            }}
          />
        </Form>
      )}
    </Modal>
  );
};

export default withRouter(Form.create()(QuickUpdateModal));
