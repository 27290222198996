import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

// Temporary remove this for cleaning purpose.
// import { checkIsBuildingNameExist } from 'apis/building';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';

/** Temporary remove this for cleaning purpose.
const onCheckIsBuildingNameExist = id => async (rule, value) => {
  const query = { id, name: value };
  const isExist = await checkIsBuildingNameExist(query);
  if (isExist) {
    throw new Error('This name exists!');
  }
};
 */

const GeneralInfo = ({ canEdit, form, building, buildingTypes, buildingRegulationStatuses, buildingStatuses, corporateBodies, buildingTitles }) => {
  return (
    <Card title="General Details">
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormInput
            form={form}
            name="name"
            label="Building Name"
            defaultValue={building.name}
            placeholder="e.g. Kelana Mall"
            requiredErrorMessage="Please enter the building's name"
            isDisabled={!canEdit}
            // Temporary remove this for cleaning purpose.
            // extraRules={[
            //   {
            //     validator: onCheckIsBuildingNameExist(building._id),
            //     message: 'This building name is already in used.'
            //   }
            // ]}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="type"
            label="Building Type"
            selections={buildingTypes}
            defaultValue={building.type}
            placeholder="Select a building type"
            requiredErrorMessage="Please select a building type"
            isDisabled={!canEdit}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="regulationStatus"
            label="Building Regulation Status"
            selections={buildingRegulationStatuses}
            defaultValue={building.regulationStatus}
            placeholder="Select a building regulation status"
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="status"
            label="Building Status"
            selections={buildingStatuses}
            defaultValue={building.status}
            placeholder="Select a building status"
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="corporateBody"
            label="Corporate Body"
            selections={corporateBodies}
            defaultValue={building.corporateBody}
            placeholder="Select a building corporate body"
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="title"
            label="Building Title"
            selections={buildingTitles}
            defaultValue={building.title}
            placeholder="Select a building title"
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormInputNumber
            form={form}
            name="totalNoOfUnits"
            label="Total Number of Unit(s)"
            defaultValue={building.totalNoOfUnits}
            placeholder="e.g. 50"
          />
        </Col>
      </Row>
    </Card>
  );
};

GeneralInfo.propTypes = {
  canEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  buildingTypes: PropTypes.array.isRequired,
  buildingRegulationStatuses: PropTypes.array.isRequired,
  buildingStatuses: PropTypes.array.isRequired,
  corporateBodies: PropTypes.array.isRequired,
  buildingTitles: PropTypes.array.isRequired
};

GeneralInfo.defaultProps = {
  canEdit: true
};

export default GeneralInfo;
