import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StandaloneSearchBox } from '@react-google-maps/api';
import FormInput from 'components/FormInput/FormInput';

const handleOnPlacesChanged = ({ searchBox, onSelectGoogleAddress }) => e => {
  const googleAddress = searchBox.getPlaces()[0];

  if (!!googleAddress) {
    const addressName = googleAddress.name;
    const vicinity = googleAddress.vicinity;
    const address = vicinity ? (vicinity.includes(addressName) ? vicinity : `${addressName}, ${vicinity}`) : addressName;

    const { city, zipCode } = googleAddress.address_components.reduce(
      (result, addressObject) => {
        const city = result.city || (addressObject.types.find(type => type === 'locality') && addressObject.long_name);
        const zipCode = result.zipCode || (addressObject.types.find(type => type === 'postal_code') && addressObject.long_name);

        return { city, zipCode };
      },
      { city: undefined, zipCode: undefined }
    );

    onSelectGoogleAddress({ address, city, zipCode, fullAddress: googleAddress.formatted_address });
  }
};

const LocationAutoSearchGoogleMap = ({
  form,
  name,
  label,
  defaultValue,
  placeholder,
  onSelectGoogleAddress,
  onInputChange,
  isDisabled,
  requiredErrorMessage
}) => {
  const [searchBox, setSearchBox] = useState({});

  return (
    <StandaloneSearchBox onLoad={ref => setSearchBox(ref)} onPlacesChanged={handleOnPlacesChanged({ searchBox, onSelectGoogleAddress })}>
      <FormInput
        form={form}
        name={name}
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete="off"
        requiredErrorMessage={requiredErrorMessage}
        isDisabled={isDisabled}
        onChange={onInputChange}
      />
    </StandaloneSearchBox>
  );
};

LocationAutoSearchGoogleMap.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSelectGoogleAddress: PropTypes.func
};

LocationAutoSearchGoogleMap.defaultProps = {
  label: 'Address',
  defaultValue: undefined,
  placeholder: 'e.g. 31N-3, Kelana Mall, Jalan SS6/14, Kelana Jaya',
  onSelectGoogleAddress: () => {}
};

export default LocationAutoSearchGoogleMap;
