import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, message, Modal, Icon, notification } from 'antd';

import { checkIsObjectEmpty } from 'utils/general';
import { useFetchConstant } from 'utils/hooks';
import { getBuildingsRoute } from 'utils/routes';

import { getBuildingById, postSubmitBuilding, putUpdateBuilding, deleteBuilding } from 'apis/building';

import Card from 'components/Card/Card';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import LocationWithGoogleMap, { useLngLat } from 'components/LocationWithGoogleMap/LocationWithGoogleMap';
import HostListing from './components/HostListing/HostListing';

const useFetchConstants = () => {
  const { selections: sources, isLoading: isSourcesLoading } = useFetchConstant('sources', 'Error while getting sources');
  const { selections: buildingTypes, isLoading: isBuildingTypesLoading } = useFetchConstant('buildingTypes', 'Error while getting building types');
  const { selections: buildingRegulationStatuses, isLoading: isBuildingRegulationStatusesLoading } = useFetchConstant(
    'buildingRegulationStatuses',
    'Error while getting building regulation statuses'
  );
  const { selections: buildingStatuses, isLoading: isBuildingStatusesLoading } = useFetchConstant(
    'buildingStatuses',
    'Error while getting building statuses'
  );
  const { selections: corporateBodies, isLoading: isCorporateBodiesLoading } = useFetchConstant(
    'corporateBodies',
    'Error while getting corporate bodies'
  );
  const { selections: buildingTitles, isLoading: isBuildingTitlesLoading } = useFetchConstant(
    'buildingTitles',
    'Error while getting building titles'
  );

  const isConstantsLoading = useMemo(
    () =>
      isSourcesLoading ||
      isBuildingTypesLoading ||
      isBuildingRegulationStatusesLoading ||
      isBuildingStatusesLoading ||
      isCorporateBodiesLoading ||
      isBuildingTitlesLoading,
    [
      isSourcesLoading,
      isBuildingTypesLoading,
      isBuildingRegulationStatusesLoading,
      isBuildingStatusesLoading,
      isCorporateBodiesLoading,
      isBuildingTitlesLoading
    ]
  );

  return { sources, buildingTypes, buildingRegulationStatuses, buildingStatuses, corporateBodies, buildingTitles, isConstantsLoading };
};

const useFetchBuilding = buildingId => {
  const [isBuildingLoading, setIsBuildingLoading] = useState(true);
  const [building, setBuilding] = useState({});

  useEffect(() => {
    const fetchBuilding = async () => {
      let building = {};

      if (!!buildingId) {
        building = await getBuildingById(buildingId).catch(e => {
          console.error(e);
          message.error(e.message);

          return { error: e };
        });
      }

      setBuilding(building);
      setIsBuildingLoading(!!building.error || false);
    };

    fetchBuilding();
  }, [buildingId]);

  return { isBuildingLoading, building };
};

const handleOnSubmit = ({ form, buildingId, latitude, longitude, setIsButtonLoading, toBuildingListing }) => e => {
  e.preventDefault();
  form.validateFieldsAndScroll({ force: true }, (err, values) => {
    if (!err) {
      setIsButtonLoading(true);
      const newBuilding = {
        name: values.name,
        type: values.type,
        regulationStatus: values.regulationStatus,
        status: values.status,
        corporateBody: values.corporateBody,
        title: values.title,
        totalNoOfUnits: values.totalNoOfUnits,
        streetAddress: values.propertyAddress,
        city: values.propertyCity,
        zipcode: values.propertyZipcode,
        state: values.propertyState,
        country: values.propertyCountry,
        longitude,
        latitude
      };

      if (!!buildingId) {
        putUpdateBuilding(buildingId, newBuilding)
          .then(() => {
            message.success('You have successfully updated the building.');
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      } else {
        postSubmitBuilding(newBuilding)
          .then(() => {
            message.success('You have successfully created a new building.');
            toBuildingListing();
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      }
    }
  });
};

const handleOnDelete = ({ buildingId, setIsButtonLoading, toBuildingListing, building }) => e => {
  setIsButtonLoading(true);

  e.preventDefault();

  let hostsFound = false;

  if (!!building.hosts && building.hosts.length > 0) {
    hostsFound = true;
  }

  const buildingName = building.name.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  Modal.confirm({
    title: 'Are you sure you want to delete ' + buildingName + '?',
    content: hostsFound ? <HostListing hosts={building.hosts} /> : 'This action cannot be undone.',
    icon: <Icon type="warning" theme="twoTone" twoToneColor="red" />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      deleteBuilding(buildingId)
        .then(() => {
          notification.success({
            duration: 3,
            message: buildingName + ' has been deleted.',
            description: hostsFound ? <HostListing hosts={building.hosts} /> : ''
          });
          toBuildingListing();
        })
        .catch(e => {
          console.error(e);
          message.error(e.message);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
    onCancel() {
      setIsButtonLoading(false);
    }
  });
};

const BuildingForm = ({ className, history, match, form }) => {
  const toBuildingListing = () => history.push(getBuildingsRoute().path);
  const buildingId = useMemo(() => match.params.id, [match.params.id]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    sources,
    buildingTypes,
    buildingRegulationStatuses,
    buildingStatuses,
    corporateBodies,
    buildingTitles,
    isConstantsLoading
  } = useFetchConstants();
  const { isBuildingLoading, building } = useFetchBuilding(buildingId);

  const { latitude, longitude, setCoordinates } = useLngLat(building.latitude, building.longitude);

  const hasBuilding = !checkIsObjectEmpty(building);
  const isFromHostSearch = !!sources.find(source => source.code === building.source && source.isLocalData);
  const isLoading = useMemo(() => isConstantsLoading || isBuildingLoading, [isConstantsLoading, isBuildingLoading]);
  const canEdit = !hasBuilding || isFromHostSearch;

  return (
    <Card onClose={toBuildingListing} loading={isLoading}>
      <Form className={className}>
        <GeneralInfo
          canEdit={canEdit}
          form={form}
          building={building}
          buildingTypes={buildingTypes}
          buildingRegulationStatuses={buildingRegulationStatuses}
          buildingStatuses={buildingStatuses}
          corporateBodies={corporateBodies}
          buildingTitles={buildingTitles}
        />
        <Card title="Location Details">
          <LocationWithGoogleMap
            form={form}
            defaultValue={building}
            latitude={latitude}
            longitude={longitude}
            onMapCoordinateChange={setCoordinates}
            isRequired
            isDisabled={!canEdit}
          />
        </Card>

        <Row type="flex" justify="start" gutter={8}>
          <Col>
            <Button
              type="primary"
              size="large"
              loading={isButtonLoading}
              onClick={handleOnSubmit({ form, buildingId, latitude, longitude, setIsButtonLoading, toBuildingListing })}
            >
              Save
            </Button>
          </Col>
          {hasBuilding && (
            <Col>
              <Button
                type="danger"
                size="large"
                loading={isButtonLoading}
                onClick={handleOnDelete({ buildingId, setIsButtonLoading, toBuildingListing, building })}
              >
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default withRouter(Form.create()(BuildingForm));
