import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import BasicHostDistributor from '../images/Basic-Host-Distributor.png';
import GoldHostDistributor from '../images/Gold-Host-Distributor.png';
import NAHostDistributor from '../images/NA-Host-Distributor.png';
import PlatinumHostDistributor from '../images/Platinum-Host-Distributor.png';
import SilverHostDistributor from '../images/Silver-Host-Distributor.png';
import UniqueHostDistributor from '../images/Unique-Host-Distributor.png';

import BasicHostNAPartner from '../images/Basic-Host-NA-Partner.png';
import GoldHostNAPartner from '../images/Gold-Host-NA-Partner.png';
import NAHostNAPartner from '../images/NA-Host-NA-Partner.png';
import PlatinumHostNAPartner from '../images/Platinum-Host-NA-Partner.png';
import SilverHostNAPartner from '../images/Silver-Host-NA-Partner.png';
import UniqueHostNAPartner from '../images/Unique-Host-NA-Partner.png';

import BasicHostReseller from '../images/Basic-Host-Reseller.png';
import GoldHostReseller from '../images/Gold-Host-Reseller.png';
import NAHostReseller from '../images/NA-Host-Reseller.png';
import PlatinumHostReseller from '../images/Platinum-Host-Reseller.png';
import SilverHostReseller from '../images/Silver-Host-Reseller.png';
import UniqueHostReseller from '../images/Unique-Host-Reseller.png';

import { DEFAULT_LAT_LNG } from 'utils/constants';

const getIconForMarker = (host = {}, partnershipStatusesConstant = {}, hostTypesConstant = {}) => {
  const { partnershipStatus, type } = host;
  const icons =
    Object.keys(partnershipStatusesConstant).length > 0 && Object.keys(hostTypesConstant).length > 0
      ? {
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.BASIC.label}`]: BasicHostDistributor,
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.GOLD.label}`]: GoldHostDistributor,
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.NA.label}`]: NAHostDistributor,
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.PLATINUM.label}`]: PlatinumHostDistributor,
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.SILVER.label}`]: SilverHostDistributor,
          [`${partnershipStatusesConstant.DISTRIBUTOR.label}${hostTypesConstant.UNIQUE.label}`]: UniqueHostDistributor,

          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.BASIC.label}`]: BasicHostNAPartner,
          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.GOLD.label}`]: GoldHostNAPartner,
          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.NA.label}`]: NAHostNAPartner,
          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.PLATINUM.label}`]: PlatinumHostNAPartner,
          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.SILVER.label}`]: SilverHostNAPartner,
          [`${partnershipStatusesConstant.NA.label}${hostTypesConstant.UNIQUE.label}`]: UniqueHostNAPartner,

          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.BASIC.label}`]: BasicHostReseller,
          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.GOLD.label}`]: GoldHostReseller,
          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.NA.label}`]: NAHostReseller,
          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.PLATINUM.label}`]: PlatinumHostReseller,
          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.SILVER.label}`]: SilverHostReseller,
          [`${partnershipStatusesConstant.RESELLER.label}${hostTypesConstant.UNIQUE.label}`]: UniqueHostReseller
        }
      : {};
  return Object.keys(icons).length > 0 && icons[`${partnershipStatus}${type}`] ? icons[`${partnershipStatus}${type}`] : NAHostNAPartner;
};

const MapMarker = ({ location, locationHost, partnershipStatusesObj, hostTypesObj, onMarkerClick }) => {
  return (
    <Marker
      key={location.id}
      position={{ lat: location.latitude, lng: location.longitude }}
      onClick={onMarkerClick}
      defaultIcon={{
        url: getIconForMarker(locationHost, partnershipStatusesObj, hostTypesObj),
        scaledSize: new window.google.maps.Size(48, 48)
      }}
    />
  );
};

const BareGoogleMap = ({ buildings, hosts, partnershipStatusesObj, hostTypesObj, center, zoom, hasPin, onBuildingClick, ...otherProps }) => (
  <GoogleMap defaultZoom={8} defaultCenter={DEFAULT_LAT_LNG} zoom={zoom} center={center} {...otherProps}>
    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60} maxZoom={12}>
      {buildings.map(building => {
        return (
          <MapMarker
            key={building.id}
            location={building}
            locationHost={hosts.find(host => String(host.id) === String(building.hostId))}
            partnershipStatusesObj={partnershipStatusesObj}
            hostTypesObj={hostTypesObj}
            onMarkerClick={() => onBuildingClick(building.id)}
          />
        );
      })}
    </MarkerClusterer>
    {hasPin && <Marker position={center} />}
  </GoogleMap>
);

BareGoogleMap.defaultProps = {
  buildings: [],
  center: DEFAULT_LAT_LNG,
  zoom: 8,
  onBuildingClick: () => {}
};

export default withGoogleMap(props => <BareGoogleMap {...props} />);
