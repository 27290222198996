import styled, { css } from 'styled-components';
import { getColor } from 'utils/theme';

const getFontSize = props => {
  switch (props.size) {
    case 'xxlarge':
      return css`
        font-size: 40px;
        @media (min-width: 768px) {
          font-size: 40px;
        }
        @media (min-width: 1600px) {
          font-size: 64px;
        }
      `;
    case 'xlarge':
      return css`
        font-size: 24px;
        @media (min-width: 768px) {
          font-size: 24px;
        }
        @media (min-width: 1600px) {
          font-size: 40px;
        }
      `;
    case 'large':
      return css`
        font-size: 20px;
        @media (min-width: 768px) {
          font-size: 20px;
        }
        @media (min-width: 1600px) {
          font-size: 24px;
        }
      `;
    case 'regular':
      return css`
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        @media (min-width: 1600px) {
          font-size: 18px;
        }
      `;
    case 'small':
      return css`
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 12px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      `;
    default:
      return css`
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        @media (min-width: 1600px) {
          font-size: 18px;
        }
      `;
  }
};


const Description = styled.p`
  text-align: ${props => props.align};
  color: ${getColor};
  font-weight: ${props => props.bold ? 600 : 400};
  ${getFontSize}
`;

Description.defaultProps = {
  align: 'left',
  color: 'black',
  size: 'regular',
  bold: false,
};

export default Description;
