import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { EMAIL_REGEX, CONTACT_NUMBER_REGEX, NOT_UPPERCASE_REGEX, INPUT_TYPE_INPUT, INPUT_TYPE_TEXTAREA } from 'utils/constants';

import FormItem from 'components/FormItem/FormItem';

const generateInputComponent = type => {
  const defaultInput = Input;

  const supportedInput = {
    [INPUT_TYPE_INPUT]: Input,
    [INPUT_TYPE_TEXTAREA]: Input.TextArea
  };

  return supportedInput[type] || defaultInput;
};

const generatePropsBasedOnType = ({ type, rows }) => {
  const typeToProps = {
    [INPUT_TYPE_INPUT]: {},
    [INPUT_TYPE_TEXTAREA]: {
      rows
    }
  };
  const typeProps = typeToProps[type] || {};

  return { ...typeProps };
};

const generateExtraGeneralRules = ({ isEmail, isContactNumber, isNotAllowUpperCase }) => {
  const emailRule = isEmail
    ? [
        {
          pattern: EMAIL_REGEX,
          message: 'Please enter a valid email address.'
        }
      ]
    : [];

  const contactNumberRule = isContactNumber
    ? [
        {
          pattern: CONTACT_NUMBER_REGEX,
          message: 'Please enter a valid contact number.'
        }
      ]
    : [];

  const lowerCaseRule = isNotAllowUpperCase
    ? [
        {
          pattern: NOT_UPPERCASE_REGEX,
          message: 'Please only enter lower case.'
        }
      ]
    : [];

  return [...emailRule, ...contactNumberRule, ...lowerCaseRule];
};

const FormInput = ({
  inputType,
  type,
  form,
  name,
  defaultValue,
  className,
  placeholder,
  rows,
  autoComplete,

  onChange,

  isEmail,
  isContactNumber,
  isNotAllowUpperCase,
  isDisabled,

  extraRules,
  ...props
}) => {
  const InputComponent = generateInputComponent(inputType);
  const propsBasedOnType = generatePropsBasedOnType({ type: inputType, rows });

  const extraGeneralRules = generateExtraGeneralRules({ isEmail, isContactNumber, isNotAllowUpperCase });

  return (
    <FormItem form={form} name={name} defaultValue={defaultValue} extraRules={[...extraRules, ...extraGeneralRules]} {...props}>
      <InputComponent
        autoComplete={autoComplete}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        type={type}
        {...propsBasedOnType}
      />
    </FormItem>
  );
};

FormInput.propTypes = {
  inputType: PropTypes.oneOf([INPUT_TYPE_INPUT, INPUT_TYPE_TEXTAREA]),
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  isEmail: PropTypes.bool,
  isContactNumber: PropTypes.bool,
  isNotAllowUpperCase: PropTypes.bool,
  isDisabled: PropTypes.bool,
  extraRules: PropTypes.array
};

FormInput.defaultProps = {
  inputType: INPUT_TYPE_INPUT,
  defaultValue: '',
  className: '',
  placeholder: '',
  rows: 4,
  autoComplete: undefined,
  onChange: () => {},
  isEmail: false,
  isContactNumber: false,
  isNotAllowUpperCase: false,
  isDisabled: false,
  extraRules: []
};

export default FormInput;
