import React, { useCallback, useState } from 'react';
import { Button, Col, Icon, List, Modal, Row } from 'antd';

import { useEditItem } from 'utils/hooks';
import { getCurrentIndexForPaginatedList } from 'utils/general';

import Card from 'components/Card/Card';
import BuildingModal from './components/BuildingModal/BuildingModal';

import styled from 'styled-components/macro';

const AddBuildingButton = styled(Button)`
  margin-bottom: 16px;
  width: auto !important;
`;

const BuildingRow = ({ building, buildingTypes, onEdit, onDelete }) => {
  const buildingType = buildingTypes.find(type => type.value === building.type);

  return (
    <Row xs={24}>
      <Col sm={17} xs={24}>
        <Row>
          <Col>Host On Hand Unit(s): {building.hostOnHandUnits || '-'}</Col>
          <Col>Building Type: {buildingType ? buildingType.label : '-'}</Col>
        </Row>
      </Col>
      <Col sm={7} xs={24}>
        <Row>
          <Button type="primary" icon="edit" onClick={onEdit} style={{ marginRight: '4px', marginBottom: '4px' }}>
            Edit
          </Button>
          <Button type="secondary" icon="delete" onClick={onDelete}>
            Delete
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

const BuildingInfo = ({ buildings, setBuildings, buildingTypes, buildingRegulationStatuses, buildingStatuses, corporateBodies, buildingTitles }) => {
  const [isBuildingModalVisible, setIsBuildingModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const selectedBuildings = buildings.filter(building => building._id).map(building => building._id);

  const {
    selectedItem: selectedBuilding,
    selectedIndex,
    isEditing,
    updateEditItem: updateSelectedBuilding,
    resetEditItem: resetSelectedBuilding
  } = useEditItem();

  const handleOnAddBuildingButtonClick = useCallback(
    e => {
      e.preventDefault();
      setIsBuildingModalVisible(true);
      resetSelectedBuilding();
    },
    [resetSelectedBuilding]
  );

  const handleOnEditBuilding = useCallback(
    index => e => {
      e.preventDefault();
      setIsBuildingModalVisible(true);
      updateSelectedBuilding(index, buildings[index]);
    },
    [buildings, updateSelectedBuilding, setIsBuildingModalVisible]
  );

  const handleOnDeleteBuilding = useCallback(
    index => e => {
      Modal.confirm({
        title: 'Are you sure want to remove this building?',
        content: 'This building will not be unlinked from this host until you click the "Save" button',
        icon: <Icon type="warning" />,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk() {
          buildings.splice(index, 1);
          setBuildings([...buildings]);
        },
        onCancel() {}
      });
    },
    [buildings, setBuildings]
  );

  const handleOnCancelBuildingModal = useCallback(
    e => {
      e.preventDefault();
      setIsBuildingModalVisible(false);
      resetSelectedBuilding();
    },
    [resetSelectedBuilding]
  );

  const handleOnSaveBuildingModal = useCallback(
    newBuilding => {
      setIsBuildingModalVisible(false);

      if (isEditing) {
        buildings[selectedIndex] = newBuilding;
        setBuildings(buildings);
      } else {
        setBuildings([...buildings, newBuilding]);
      }
    },
    [isEditing, selectedIndex, buildings, setBuildings]
  );

  return (
    <>
      <Card title="Building Info">
        <AddBuildingButton icon="plus" type="primary" onClick={handleOnAddBuildingButtonClick}>
          Add Building
        </AddBuildingButton>
        <List
          size="large"
          bordered
          dataSource={buildings}
          pagination={{
            onChange: page => setCurrentPage(page)
          }}
          renderItem={(item, index) => {
            const currentItemIndex = getCurrentIndexForPaginatedList(index, currentPage);
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Col span={18} style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                      {item.name}
                    </Col>
                  }
                  description={
                    <BuildingRow
                      building={item}
                      buildingTypes={buildingTypes}
                      onEdit={handleOnEditBuilding(currentItemIndex)}
                      onDelete={handleOnDeleteBuilding(currentItemIndex)}
                    />
                  }
                />
              </List.Item>
            );
          }}
        />
      </Card>
      {isBuildingModalVisible && (
        <BuildingModal
          building={selectedBuilding}
          buildingTypes={buildingTypes}
          buildingRegulationStatuses={buildingRegulationStatuses}
          buildingStatuses={buildingStatuses}
          corporateBodies={corporateBodies}
          buildingTitles={buildingTitles}
          isVisible={isBuildingModalVisible}
          onSave={handleOnSaveBuildingModal}
          onCancel={handleOnCancelBuildingModal}
          selectedBuildings={selectedBuildings}
        />
      )}
    </>
  );
};

export default BuildingInfo;
