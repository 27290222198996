import React, { useState, useMemo, useEffect } from 'react';
import { List, Row, Col, Icon, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { getStatistics } from 'apis/enquiry';

import Card from 'components/Card/Card';
import MonthPicker from 'components/MonthPicker/MonthPicker';

const StyledList = styled(List)`
  overflow: scroll;
  height: 300px;
`;

const StatisticsRow = styled(Row)`
  margin-left: 10px;
  align-items: center;
  min-height: 70px;
  border-bottom: 1px solid #e8e8e8;
`;

const StatisticsIcon = styled(Col)`
  margin-left: 10px;
  align-items: center;
  min-height: 100%;
`;

const StatisticsLabel = styled(Col)`
  color: #777777;
  font-weight: 400;
  font-size: 16px;
`;

const StatisticsValue = styled(Col)`
  display: flex;
  justify-content: center;
  color: #424242;
  font-weight: 600;
  font-size: 25px;
`;

const getMomentDate = (month, year) =>
  moment()
    .set('month', month - 1)
    .set('year', year);

const useLoadEnquiryStatisticsData = (month, year, assignedTo) => {
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    const fetchStatistics = async () => {
      const statistics = await getStatistics({ month, year, assignedTo }).catch((e) => {
        notification.error({
          message: 'Error while fetching statistics',
          description: e.message
        });
        return [];
      });

      statistics.forEach(statistic => {
        statistic.label = statistic.label.charAt(0).toUpperCase() + statistic.label.slice(1);
      });

      setStatisticsData(statistics);
    };
    if (assignedTo) {
      fetchStatistics();
    }
  }, [month, year, assignedTo]);

  return statisticsData;
};

const StatisticsCard = ({ assignedTo }) => {
  const title = 'Enquiry Statistics';

  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const momentDate = useMemo(() => getMomentDate(month, year), [month, year]);
  const data = useLoadEnquiryStatisticsData(month, year, assignedTo);

  return (
    <Card
      title={
        <Row type="flex" justify="space-between">
          <Col>{title}</Col>
          <Col>
            <MonthPicker
              defaultValue={momentDate}
              onChange={newDate => {
                setYear(newDate.format('YYYY'));
                setMonth(newDate.format('M'));
              }}
            />
          </Col>
        </Row>
      }
    >
      <StyledList
        itemLayout="horizontal"
        dataSource={data}
        size="small"
        renderItem={item => (
          <StatisticsRow type="flex">
            <StatisticsIcon span={2}>
              <Icon type="file-text" />
            </StatisticsIcon>
            <StatisticsLabel span={17}>{item.label} Enquiries</StatisticsLabel>
            <StatisticsValue span={4}>{item.count}</StatisticsValue>
          </StatisticsRow>
        )}
      />
    </Card>
  );
};

export default StatisticsCard;
