import React from 'react';
import { Row, Col } from 'antd';

import Card from 'components/Card/Card';

import BasicHostDistributor from '../images/Basic-Host-Distributor.png';
import GoldHostDistributor from '../images/Gold-Host-Distributor.png';
import NAHostDistributor from '../images/NA-Host-Distributor.png';
import PlatinumHostDistributor from '../images/Platinum-Host-Distributor.png';
import SilverHostDistributor from '../images/Silver-Host-Distributor.png';
import UniqueHostDistributor from '../images/Unique-Host-Distributor.png';

import BasicHostNAPartner from '../images/Basic-Host-NA-Partner.png';
import GoldHostNAPartner from '../images/Gold-Host-NA-Partner.png';
import NAHostNAPartner from '../images/NA-Host-NA-Partner.png';
import PlatinumHostNAPartner from '../images/Platinum-Host-NA-Partner.png';
import SilverHostNAPartner from '../images/Silver-Host-NA-Partner.png';
import UniqueHostNAPartner from '../images/Unique-Host-NA-Partner.png';

import BasicHostReseller from '../images/Basic-Host-Reseller.png';
import GoldHostReseller from '../images/Gold-Host-Reseller.png';
import NAHostReseller from '../images/NA-Host-Reseller.png';
import PlatinumHostReseller from '../images/Platinum-Host-Reseller.png';
import SilverHostReseller from '../images/Silver-Host-Reseller.png';
import UniqueHostReseller from '../images/Unique-Host-Reseller.png';

const legends = {
  distributor: [
    {
      imgSrc: BasicHostDistributor,
      desc: 'Basic Distributor'
    },
    {
      imgSrc: SilverHostDistributor,
      desc: 'Silver Distributor'
    },
    {
      imgSrc: GoldHostDistributor,
      desc: 'Gold Distributor'
    },
    {
      imgSrc: PlatinumHostDistributor,
      desc: 'Platinum Distributor'
    },
    {
      imgSrc: UniqueHostDistributor,
      desc: 'Unique Distributor'
    },
    {
      imgSrc: NAHostDistributor,
      desc: 'Distributor'
    }
  ],
  hostPartner: [
    {
      imgSrc: BasicHostNAPartner,
      desc: 'Basic Host Partner'
    },
    {
      imgSrc: SilverHostNAPartner,
      desc: 'Silver Host Partner'
    },
    {
      imgSrc: GoldHostNAPartner,
      desc: 'Gold Host Partner'
    },
    {
      imgSrc: PlatinumHostNAPartner,
      desc: 'Platinum Host Partner'
    },
    {
      imgSrc: UniqueHostNAPartner,
      desc: 'Unique Host Partner'
    },
    {
      imgSrc: NAHostNAPartner,
      desc: 'Host Partner'
    }
  ],
  reseller: [
    {
      imgSrc: BasicHostReseller,
      desc: 'Basic Reseller'
    },
    {
      imgSrc: SilverHostReseller,
      desc: 'Silver Reseller'
    },
    {
      imgSrc: GoldHostReseller,
      desc: 'Gold Reseller'
    },
    {
      imgSrc: PlatinumHostReseller,
      desc: 'Platinum Reseller'
    },
    {
      imgSrc: UniqueHostReseller,
      desc: 'Unique Reseller'
    },
    {
      imgSrc: NAHostReseller,
      desc: 'Reseller'
    }
  ]
};

const Legends = () => (
  <Card title="Legends">
    <Row>
      {Object.keys(legends).map(key => (
        <Row key={key} style={{ borderBottom: '0.5px solid rgb(224, 231, 231)', padding: '8px 0' }}>
          <h4 style={{ textTransform: 'capitalize' }}>{key}</h4>
          {legends[key].map(legend => (
            <Col span={12} md={8} key={legend.desc} style={{ fontSize: '10px', padding: '4px 0' }}>
              <img src={legend.imgSrc} alt={`${legend.desc} icon`} width={32} height={32} /> {legend.desc}
            </Col>
          ))}
        </Row>
      ))}
    </Row>
  </Card>
);

export default Legends;
