import React, { useState } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/Header/Header';
import SideBar from 'components/SideBar/SideBar';

import { getDashboardRoute } from 'utils/routes';

import HostSearchLogo from './images/hostsearch-logo.png';

const dashboardRoute = getDashboardRoute();

const { Sider } = Layout;

const StyledLayout = styled(Layout)`
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
`;

const StyledSider = styled(Sider)`
  height: 90vh;
  background: ${props => props.theme.color.white} !important;
`;

const LogoContainer = styled.div`
  background: #ffffff;
  display: block;
  text-align: center;
  height: auto;
`;

const Logo = styled.img`
  height: 90px;
`;

const ContentLayout = styled(Layout)`
  overflow-x: auto;
  padding: 24px;
  max-height: 93vh;
  background-color: #f9f9f9;
`;

const StandardLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <StyledLayout>
      <StyledSider collapsedWidth="0" collapsible collapsed={collapsed} trigger={null}>
        <LogoContainer>
          <Link to={dashboardRoute.path}>
            <Logo src={collapsed ? Logo : HostSearchLogo} alt="host search logo" />
          </Link>
        </LogoContainer>
        <SideBar />
      </StyledSider>
      <Layout>
        <Header onCollapse={() => setCollapsed(!collapsed)} />
        <ContentLayout>{children}</ContentLayout>
      </Layout>
    </StyledLayout>
  );
};

StandardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

StandardLayout.defaultProps = {
  children: `</>`
};

export default withRouter(StandardLayout);
