import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, Tabs, message, Modal, Icon } from 'antd';

import { checkIsObjectEmpty } from 'utils/general';
import { useFetchConstant, useFetchUsersForSelection } from 'utils/hooks';
import { getHostsRoute } from 'utils/routes';

import { getHostById, postSubmitHost, putUpdateHost, deleteHost } from 'apis/host';

import { withAppContext } from 'contexts/AppContext/AppContext';
import Card from 'components/Card/Card';
import { useLngLat } from 'components/LocationWithGoogleMap/LocationWithGoogleMap';

import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import BuildingInfo from './components/BuildingInfo/BuildingInfo';
import MatchedEnquiryInfo from './components/MatchedEnquiryInfo/MatchedEnquiryInfo';

const TabPane = Tabs.TabPane;

const GENERAL_INFO = 'General Info';
const BUILDING_INFO = 'Building Info';
const MATCHED_ENQUIRY_INFO = 'Matched Enquiry Info';

const useFetchConstants = isAdmin => {
  const { selections: sources, isLoading: isSourcesLoading } = useFetchConstant('sources', 'Error while getting sources');

  const { selections: countryCodes, isLoading: isCountryCodesLoading } = useFetchConstant(
    'countries',
    'Error while getting countryCodes.',
    'phoneCode',
    'phoneCode'
  );
  const { selections: hostTypes, isLoading: isHostTypesLoading } = useFetchConstant('hostTypes', 'Error while getting host types');
  const { selections: partnershipStatuses, isLoading: isPartnershipStatusesLoading } = useFetchConstant(
    'partnershipStatuses',
    'Error while getting partnership statuses'
  );
  const { selections: hostStatuses, isLoading: isHostStatusesLoading } = useFetchConstant('hostStatuses', 'Error while getting host statuses');

  const { selections: buildingTypes, isLoading: isBuildingTypesLoading } = useFetchConstant('buildingTypes', 'Error while getting building types');
  const { selections: buildingRegulationStatuses, isLoading: isBuildingRegulationStatusesLoading } = useFetchConstant(
    'buildingRegulationStatuses',
    'Error while getting building regulation statuses'
  );
  const { selections: buildingStatuses, isLoading: isBuildingStatusesLoading } = useFetchConstant(
    'buildingStatuses',
    'Error while getting building statuses'
  );
  const { selections: corporateBodies, isLoading: isCorporateBodiesLoading } = useFetchConstant(
    'corporateBodies',
    'Error while getting corporate bodies'
  );
  const { selections: buildingTitles, isLoading: isBuildingTitlesLoading } = useFetchConstant(
    'buildingTitles',
    'Error while getting building titles'
  );
  const { selections: hostMatchStatuses, isLoading: isHostMatchStatusesLoading } = useFetchConstant(
    'hostMatchStatuses',
    'Error while getting host match statuses'
  );
  const { users: listOfPIC, isLoading: isListOfPICLoading } = useFetchUsersForSelection(!!isAdmin);

  const isConstantsLoading = useMemo(
    () =>
      isSourcesLoading ||
      isCountryCodesLoading ||
      isHostTypesLoading ||
      isPartnershipStatusesLoading ||
      isHostStatusesLoading ||
      isBuildingTypesLoading ||
      isBuildingRegulationStatusesLoading ||
      isBuildingStatusesLoading ||
      isCorporateBodiesLoading ||
      isBuildingTitlesLoading ||
      isHostMatchStatusesLoading ||
      isListOfPICLoading,
    [
      isSourcesLoading,
      isCountryCodesLoading,
      isHostTypesLoading,
      isPartnershipStatusesLoading,
      isHostStatusesLoading,
      isBuildingTypesLoading,
      isBuildingRegulationStatusesLoading,
      isBuildingStatusesLoading,
      isCorporateBodiesLoading,
      isBuildingTitlesLoading,
      isHostMatchStatusesLoading,
      isListOfPICLoading
    ]
  );

  return {
    sources,
    countryCodes,
    hostTypes,
    partnershipStatuses,
    hostStatuses,
    buildingTypes,
    buildingRegulationStatuses,
    buildingStatuses,
    corporateBodies,
    buildingTitles,
    hostMatchStatuses,
    listOfPIC,
    isConstantsLoading
  };
};

const useFetchHost = hostId => {
  const [isHostLoading, setIsHostLoading] = useState(true);
  const [host, setHost] = useState({});
  const [buildings, setBuildings] = useState([]);

  const enquiryMatches = useMemo(() => host.enquiryMatches, [host]);

  const fetchHost = async hostId => {
    let host = {};

    if (!!hostId) {
      host = await getHostById(hostId).catch(e => {
        console.error(e);
        message.error(e.message);

        return { error: e };
      });
    }

    setHost(host);
    setBuildings(host.buildings || []);
    setIsHostLoading(!!host.error || false);
  };

  const refetchHost = () => fetchHost(hostId);

  useEffect(() => {
    fetchHost(hostId);
  }, [hostId]);

  return { host, buildings, setBuildings, enquiryMatches, isHostLoading, refetchHost };
};

const handleOnSubmit = ({ form, hostId, latitude, longitude, buildings, setIsButtonLoading, toHostListing, refetchHost }) => e => {
  e.preventDefault();
  form.validateFieldsAndScroll({ force: true }, (err, values) => {
    if (!err) {
      setIsButtonLoading(true);
      const newHost = {
        name: values.name,
        email: values.email,
        contact: {
          countryCode: values.contactCountryCode,
          contactNumber: values.contactNumber
        },
        type: values.hostType,
        partnershipStatus: values.partnershipStatus,
        status: values.hostStatus,
        assignedTo: values.assignedTo,
        streetAddress: values.propertyAddress,
        city: values.propertyCity,
        zipcode: values.propertyZipcode,
        state: values.propertyState,
        country: values.propertyCountry,
        longitude,
        latitude,
        buildings: buildings.map(building => {
          return { ...building, totalNoOfUnits: building.hostOnHandUnits || building.totalNoOfUnits };
        })
      };

      if (!!hostId) {
        putUpdateHost(hostId, newHost)
          .then(() => {
            message.success('You have successfully updated the host.');
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
            refetchHost();
          });
      } else {
        postSubmitHost(newHost)
          .then(() => {
            message.success('You have successfully created a new host.');
            toHostListing();
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      }
    }
  });
};

const handleOnDelete = ({ hostId, setIsButtonLoading, toHostListing }) => e => {
  setIsButtonLoading(true);

  e.preventDefault();
  Modal.confirm({
    title: 'Are you sure want to delete this host?',
    content: 'This action cannot be undone.',
    icon: <Icon type="warning" theme="twoTone" twoToneColor="red" />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      deleteHost(hostId)
        .then(() => {
          message.success('You have successfully deleted the host.');
          toHostListing();
        })
        .catch(e => {
          console.error(e);
          message.error(e.message);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
    onCancel() {
      setIsButtonLoading(false);
    }
  });
};

const HostForm = ({ className, history, match, form, checkIsUserPIC, isAdmin }) => {
  const toHostListing = () => history.push(getHostsRoute().path);
  const hostId = useMemo(() => match.params.id, [match.params.id]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    sources,
    countryCodes,
    hostTypes,
    partnershipStatuses,
    hostStatuses,
    buildingTypes,
    buildingRegulationStatuses,
    buildingStatuses,
    corporateBodies,
    buildingTitles,
    hostMatchStatuses,
    listOfPIC,
    isConstantsLoading
  } = useFetchConstants(isAdmin);
  const { host, buildings, setBuildings, enquiryMatches, isHostLoading, refetchHost } = useFetchHost(hostId);
  const { latitude, longitude, setCoordinates } = useLngLat(host.latitude, host.longitude);

  const hasHost = !checkIsObjectEmpty(host);
  const isFromHostSearch = !!sources.find(source => source.code === host.source && source.isLocalData);
  const isLoading = isConstantsLoading || isHostLoading;
  const canEdit = !hasHost || isFromHostSearch;
  return (
    <Card onClose={toHostListing} loading={isLoading}>
      <Form className={className}>
        <Tabs defaultActiveKey={GENERAL_INFO}>
          <TabPane tab={GENERAL_INFO} key={GENERAL_INFO} forceRender>
            <GeneralInfo
              form={form}
              host={host}
              latitude={latitude}
              longitude={longitude}
              setCoordinates={setCoordinates}
              countryCodes={countryCodes}
              hostTypes={hostTypes}
              partnershipStatuses={partnershipStatuses}
              hostStatuses={hostStatuses}
              listOfPIC={listOfPIC}
              canEdit={canEdit}
              isAdmin={isAdmin}
              checkIsUserPIC={checkIsUserPIC}
            />
          </TabPane>
          <TabPane tab={BUILDING_INFO} key={BUILDING_INFO} forceRender>
            <BuildingInfo
              buildings={buildings}
              setBuildings={setBuildings}
              buildingTypes={buildingTypes}
              buildingRegulationStatuses={buildingRegulationStatuses}
              buildingStatuses={buildingStatuses}
              corporateBodies={corporateBodies}
              buildingTitles={buildingTitles}
            />
          </TabPane>
          <TabPane tab={MATCHED_ENQUIRY_INFO} key={MATCHED_ENQUIRY_INFO} forceRender>
            <MatchedEnquiryInfo enquiryMatches={enquiryMatches} hostMatchStatuses={hostMatchStatuses} />
          </TabPane>
        </Tabs>
        <Row type="flex" justify="start" gutter={8}>
          <Col>
            <Button
              type="primary"
              size="large"
              loading={isButtonLoading}
              onClick={handleOnSubmit({ form, hostId, latitude, longitude, buildings, setIsButtonLoading, toHostListing, refetchHost })}
            >
              Save
            </Button>
          </Col>
          {hasHost && (
            <Col>
              <Button type="danger" size="large" loading={isButtonLoading} onClick={handleOnDelete({ hostId, setIsButtonLoading, toHostListing })}>
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default withAppContext(withRouter(Form.create()(HostForm)));
