export const purifyPayload = formData => {
  return Object.keys(formData).reduce((newForm, key) => {
    const value = formData[key];
    if (value === '') {
      return newForm;
    }
    return {
      ...newForm,
      [key]: value
    };
  }, {});
};

export const constructFullAddress = (propertyAddressObj, countries, states) => {
  const addressFields = ['streetAddress', 'city', 'state', 'zipcode', 'country'];
  return addressFields.reduce((fullAddress, addressField) => {
    let addressValue = propertyAddressObj[addressField];
    if (addressValue) {
      if (addressField === 'country') {
        const foundCountry = countries.find(country => country.value === addressValue);
        if (foundCountry) {
          addressValue = foundCountry.label;
        }
      } else if (addressField === 'state') {
        const foundState = states.find(state => state.value === addressValue);
        if (foundState) {
          addressValue = foundState.label;
        }
      }
      if (fullAddress) {
        return `${fullAddress}, ${addressValue}`;
      }
      return addressValue;
    }
    return fullAddress;
  }, '');
};

export const checkIsObject = item => {
  return !!item && typeof item === 'object';
};

export const checkIsObjectEmpty = object => {
  return Object.keys(object).length === 0;
};

export const getCurrentIndexForPaginatedList = (index = 0, currentPage = 1, pageSize = 10) => {
  return (currentPage - 1) * pageSize + index;
};

/** API Payload construction */
export const constructCommentPayload = (status, comment) => {
  return {
    status,
    comment
  };
};

/** Constant Helper for getting label */
export const getLabelOfConstant = (selectedConstant, constants) => {
  const foundConstant = constants.find(constant => constant.value === selectedConstant);

  if (!foundConstant) {
    console.warn(`Constant value ${selectedConstant} not found in constants that you pass. Default to the original value.`);
    return selectedConstant;
  }

  return foundConstant.label;
};
