import React from 'react';
import moment from 'moment';
import { DatePicker, Icon, Button } from 'antd';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 15px;
`;

const handleOnPreviousMonthClick = (date, onChange) => () => {
  const newDate = date.subtract(1, 'month').startOf('month');
  handleOnMonthChange(onChange)(newDate);
};

const handleOnNextMonthClick = (date, onChange) => () => {
  const newDate = date.add(1, 'month').startOf('month');
  handleOnMonthChange(onChange)(newDate);
};

const handleOnTodayClick = onChange => () => {
  const newDate = moment().startOf('month');
  handleOnMonthChange(onChange)(newDate);
};

const handleOnMonthChange = onChange => date => {
  date.set('date', 1);
  onChange(date);
};

const MonthPicker = ({ label, defaultValue, disabled, onChange }) => {
  return (
    <>
      {label && <StyledLabel>{label} </StyledLabel>}
      <Button disabled={disabled} onClick={handleOnPreviousMonthClick(defaultValue, onChange)}>
        <Icon type="backward" />
      </Button>
      <DatePicker.MonthPicker
        allowClear={false}
        value={defaultValue}
        onChange={handleOnMonthChange(onChange)}
        placeholder="Select month"
        disabled={disabled}
      />
      <Button disabled={disabled} onClick={handleOnNextMonthClick(defaultValue, onChange)}>
        <Icon type="forward" />
      </Button>
      <Button disabled={disabled} type="secondary" onClick={handleOnTodayClick(onChange)}>
        Today
      </Button>
    </>
  );
};

MonthPicker.defaultProps = {
  label: '',
  defaultValue: moment(),
  disabled: false,
  onChange: () => {}
};

export default MonthPicker;
