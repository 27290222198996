import React from 'react';
import { Card as AntdCard, Row, Icon } from 'antd';
import styled from 'styled-components/macro';

const StyledCard = styled(AntdCard)`
  background-color: ${props => props.theme.color.white} !important;
  margin-bottom: 16px !important;
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
`;

const CloseButtonContainer = styled.div`
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
`;

const CloseButton = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <CloseButtonContainer>
      <Icon type="close" theme="outlined" />
    </CloseButtonContainer>
  </StyledButton>
);

const Card = ({ children, onClose, title, ...props }) => (
  <StyledCard
    title={
      (title && onClose && (
        <Row type="flex" justify="space-between">
          <span>{title}</span>
          <CloseButton onClick={onClose} />
        </Row>
      )) ||
      title
    }
    {...props}
  >
    {!title && onClose && (
      <Row type="flex" justify="space-between">
        <CloseButton onClick={onClose} />
      </Row>
    )}
    {children}
  </StyledCard>
);

export default Card;
