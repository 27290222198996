import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import FormItem from 'components/FormItem/FormItem';

import styled from 'styled-components/macro';

/* styled css */
const StyledFormItem = styled(FormItem)`
  ${props => props.capitalize && `text-transform: capitalize` };
`;
/* styled css */

const CheckboxGroup = Checkbox.Group;

const FormCheckbox = ({
  form,
  name,
  defaultValue,
  className,

  selections,
  onChange,
  isDisabled,
  ...props
}) => (
  <StyledFormItem form={form} name={name} defaultValue={defaultValue} {...props}>
    <CheckboxGroup
      className={className}
      options={selections}
      onChange={onChange}
      disabled={isDisabled}
      />
  </StyledFormItem>
);

FormCheckbox.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  className: PropTypes.string,
  selections: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool
};

FormCheckbox.defaultProps = {
  defaultValue: [],
  className: '',
  isDisabled: false
};

export default FormCheckbox;
