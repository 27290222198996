import { useState, useCallback, useEffect } from 'react';
import { message, notification } from 'antd';

import { getConstants } from 'apis/constant';
import { getUsers } from 'apis/user';

export const useEditItem = () => {
  const [selectedItem, setItem] = useState({});
  const [selectedIndex, setIndex] = useState(-1);

  const updateEditItem = useCallback((index, item) => {
    setItem(item);
    setIndex(index);
  }, []);

  const resetEditItem = useCallback(() => {
    setItem({});
    setIndex(-1);
  }, []);

  return { selectedItem, selectedIndex, isEditing: selectedIndex > -1, updateEditItem, resetEditItem };
};

export const useFetchConstant = (name, errorMessage = 'Error while getting data.', valueKey = 'code', labelKey = 'label') => {
  const [originalSelection, setOriginalSelection] = useState({});
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConstants(name)
      .then(data => {
        setOriginalSelection(data);
        const formattedSelections = Object.values(data).map(selection => ({
          ...selection,
          value: selection[valueKey],
          label: selection[labelKey]
        }));
        setSelections(formattedSelections);
      })
      .catch(e => {
        console.error(e);
        message.error(errorMessage);
      })
      .finally(() => setIsLoading(false));
  }, [name, errorMessage, valueKey, labelKey]);

  return { selections, originalSelection, isLoading };
};

export const useFetchUsersForSelection = (shouldFetch = true) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = () => {
      getUsers()
        .then(userData => {
          const selections = userData.map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user._id }));
          setUsers(selections);
        })
        .catch(e => {
          notification.error({
            message: 'Error while fetching users',
            description: e.message
          });
          return {};
        })
        .finally(() => setIsLoading(false));
    };
    if (shouldFetch) {
      fetchUsers();
    } else {
      setIsLoading(false);
    }
  }, [shouldFetch]);

  return { users, isLoading };
};
