import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import moment from 'moment';

import Comments from 'components/Comments/Comments';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

import { INPUT_TYPE_TEXTAREA, DEFAULT_ENQUIRY_STATUS } from 'utils/constants';

const QuickUpdateContent = ({ canEdit, form, enquiry, enquiryStatuses }) => {
  const todayDate = useMemo(() => moment(), []);
  const defaultStatus = useMemo(() => enquiry.status || DEFAULT_ENQUIRY_STATUS, [enquiry.status]);
  const defaultForecastDate = useMemo(() => (enquiry.forecast && enquiry.forecast.date ? moment(enquiry.forecast.date) : undefined), [
    enquiry.forecast
  ]);
  return (
    <>
      <Row type="flex" gutter={64}>
        <Col span={24}>
          <FormRadioButton
            form={form}
            name="status"
            label="Status"
            selections={enquiryStatuses}
            defaultValue={defaultStatus}
            isDisabled={!canEdit}
            requiredErrorMessage="Please select a status"
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormInputNumber
            form={form}
            name="forecastPrice"
            label="Forecast Price"
            defaultValue={enquiry.forecast && enquiry.forecast.price}
            isPrice={true}
            isDisabled={!canEdit}
          />
        </Col>
        <Col span={12}>
          <FormDatePicker
            form={form}
            name="forecastDate"
            label="Forecast Date"
            defaultValue={defaultForecastDate}
            isDisabled={!canEdit}
            disabledDate={currentDate => currentDate < todayDate}
          />
        </Col>
      </Row>
      {enquiry.internalRemarks && (
        <Row>
          <FormInput
            inputType={INPUT_TYPE_TEXTAREA}
            form={form}
            name="enquiryRemarks"
            label="Old remarks for this enquiry"
            defaultValue={enquiry.internalRemarks}
            isDisabled={true}
          />
        </Row>
      )}
      <Comments ticketNumber={enquiry.ticketNumber} enquiryId={enquiry._id} enquiryStatus={enquiry.status} comments={enquiry.comments} />
    </>
  );
};

QuickUpdateContent.propTypes = {
  canEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  enquiry: PropTypes.object.isRequired,
  enquiryStatuses: PropTypes.array.isRequired
};

QuickUpdateContent.defaultProps = {
  canEdit: true
};

export default QuickUpdateContent;
