import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Icon, Form, message, Modal, Row, Tabs } from 'antd';

import { checkIsObjectEmpty } from 'utils/general';
import { useFetchConstant } from 'utils/hooks';
import { getCampaignHostsRoute } from 'utils/routes';

import { getCampaignHostById, postSubmitCampaignHost, putUpdateCampaignHost, deleteCampaignHost } from 'apis/campaignHost';

import { withAppContext } from 'contexts/AppContext/AppContext';
import Card from 'components/Card/Card';
import { useLngLat } from 'components/LocationWithGoogleMap/LocationWithGoogleMap';

import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import CampaignInfo from './components/CampaignInfo/CampaignInfo';

const TabPane = Tabs.TabPane;

const GENERAL_INFO_TAB_NAME = 'General Info';
const CAMPAIGN_INFO_TAB_NAME = 'Campaign Info';

const useFetchCampaignHost = campaignHostId => {
  const [isCampaignHostLoading, setIsCampaignHostLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [campaignHost, setCampaignHost] = useState({});

  const fetchCampaignHost = async campaignHostId => {
    let campaignHost = {};

    if (!!campaignHostId) {
      campaignHost = await getCampaignHostById(campaignHostId).catch(e => {
        console.error(e);
        message.error(e.message);

        return { error: e };
      });
    }
    setCampaignHost(campaignHost);
    campaignHost.units && setUnits(campaignHost.units);
    setIsCampaignHostLoading(!!campaignHost.error || false);
  };

  const refetchCampaignHost = () => fetchCampaignHost(campaignHostId);

  useEffect(() => {
    fetchCampaignHost(campaignHostId);
  }, [campaignHostId]);

  return { campaignHost, isCampaignHostLoading, units, setUnits, refetchCampaignHost };
};

const checkIsHostJoinInstagrammable = (campaignsObj, joinedCampaigns = []) =>
  !!joinedCampaigns.find(joinedCampaign => String(joinedCampaign) === String(campaignsObj.TOP_3_INSTAGRAMMABLE_UNITS.code));

const handleOnSubmit = ({
  form,
  campaignHostId,
  campaignsObj,
  latitude,
  longitude,
  units,
  setIsButtonLoading,
  toCampaignHostListing,
  refetchCampaignHost
}) => e => {
  e.preventDefault();
  form.validateFieldsAndScroll({ force: true }, (err, values) => {
    if (!err) {
      setIsButtonLoading(true);
      const newCampaignHost = {
        name: values.name,
        email: values.email,
        contact: {
          countryCode: values.contactCountryCode,
          contactNumber: values.contactNumber
        },
        campaigns: values.campaigns,
        logo: values.logo,
        briefDescription: values.briefDescription,
        longDescription: values.longDescription,
        yearEstablished: values.yearEstablished,
        coveredAreas: values.coveredAreas,
        unitsOnHand: values.unitsOnHand,
        website: values.website,
        streetAddress: values.propertyAddress,
        city: values.propertyCity,
        zipcode: values.propertyZipcode,
        state: values.propertyState,
        country: values.propertyCountry,
        paymentStatus: values.paymentStatus,
        longitude,
        latitude,
        units: checkIsHostJoinInstagrammable(campaignsObj, values.campaigns) ? units : []
      };
      if (!!campaignHostId) {
        putUpdateCampaignHost(campaignHostId, newCampaignHost)
          .then(() => {
            message.success('You have successfully updated the host.');
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          })
          .finally(() => {
            setIsButtonLoading(false);
            refetchCampaignHost();
          });
      } else {
        postSubmitCampaignHost(newCampaignHost)
          .then(() => {
            message.success('You have successfully created a new campaign host.');
            toCampaignHostListing();
          })
          .catch(e => {
            console.error(e);
            message.error(e.message);
          }).finally(() => {
            setIsButtonLoading(false);
          });
      }
    }
  });
};

const handleOnDelete = ({ campaignHostId, setIsButtonLoading, toCampaignHostListing }) => e => {
  setIsButtonLoading(true);

  e.preventDefault();
  Modal.confirm({
    title: 'Are you sure want to delete this campaign host?',
    content: 'This action cannot be undone.',
    icon: <Icon type="warning" theme="twoTone" twoToneColor="red" />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      deleteCampaignHost(campaignHostId)
        .then(() => {
          message.success('You have successfully deleted the campaign host.');
          toCampaignHostListing();
        })
        .catch(e => {
          console.error(e);
          message.error(e.message);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
    onCancel() {
      setIsButtonLoading(false);
    }
  });
};

const CampaignHostForm = ({ history, match, form }) => {
  const toCampaignHostListing = () => history.push(getCampaignHostsRoute().path);
  const campaignHostId = useMemo(() => match.params.id, [match.params.id]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { originalSelection: campaignsObj, selections: campaigns } = useFetchConstant('campaigns');
  const { selections: countryCodes, isLoading: isCountryCodesLoading } = useFetchConstant(
    'countries',
    'Error while getting countryCodes.',
    'phoneCode',
    'phoneCode'
  );
  const { campaignHost, isCampaignHostLoading, units, setUnits, refetchCampaignHost } = useFetchCampaignHost(campaignHostId);

  const { latitude, longitude, setCoordinates } = useLngLat(campaignHost.latitude, campaignHost.longitude);

  const hasCampaignHost = !checkIsObjectEmpty(campaignHost);
  const isLoading = isCountryCodesLoading || isCampaignHostLoading;

  return (
    <Card onClose={toCampaignHostListing} loading={isLoading}>
      <Form>
        <Tabs defaultActiveKey={GENERAL_INFO_TAB_NAME}>
          <TabPane tab={GENERAL_INFO_TAB_NAME} key={GENERAL_INFO_TAB_NAME} forceRender>
            <GeneralInfo
              form={form}
              campaignHost={campaignHost}
              latitude={latitude}
              longitude={longitude}
              setCoordinates={setCoordinates}
              countryCodes={countryCodes}
            />
          </TabPane>
          <TabPane tab={CAMPAIGN_INFO_TAB_NAME} key={CAMPAIGN_INFO_TAB_NAME} forceRender>
            <CampaignInfo
              form={form}
              campaigns={campaigns}
              campaignsObj={campaignsObj}
              campaignHost={campaignHost}
              units={units}
              onUnitsChange={setUnits}
            />
          </TabPane>
        </Tabs>
        <Row type="flex" justify="start" gutter={8}>
          <Col>
            <Button
              type="primary"
              size="large"
              loading={isButtonLoading}
              onClick={handleOnSubmit({
                form,
                campaignHostId,
                campaignsObj,
                units,
                latitude,
                longitude,
                setIsButtonLoading,
                toCampaignHostListing,
                refetchCampaignHost
              })}
            >
              Save
            </Button>
          </Col>
          {hasCampaignHost && (
            <Col>
              <Button
                type="danger"
                size="large"
                loading={isButtonLoading}
                onClick={handleOnDelete({ campaignHostId, setIsButtonLoading, toCampaignHostListing })}
              >
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default withAppContext(withRouter(Form.create()(CampaignHostForm)));
