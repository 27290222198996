import React, { useEffect, useState } from 'react';
import { Button, Icon, Table } from 'antd';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getEnquiriesWithAggregation } from 'apis/enquiry';
import { withAppContext } from 'contexts/AppContext/AppContext';

import AssignEnquiryPICModal from 'components/AssignEnquiryPICModal/AssignEnquiryPICModal';
import Card from 'components/Card/Card';
import DownloadCSVModal from 'components/DownloadCSVModal/DownloadCSVModal';

import { useFetchConstant } from 'utils/hooks';
import { constructConstantsLabel, getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';
import { getNewEnquiryRoute, getEnquiryRoute } from 'utils/routes';

import QuickUpdateModal from './components/QuickUpdateModal/QuickUpdateModal';

const newEnquiryRoute = getNewEnquiryRoute();

const EnquiryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ActionButtonContainer = styled.div`
  text-align: center;
`;

const useLoadEnquiries = () => {
  const [currentQuery, setCurrentQuery] = useState({});
  const [enquiries, setEnquiries] = useState([]);
  const [totalOfEnquiries, setTotalOfEnquiries] = useState(0);

  const fetchEnquiries = async newQuery => {
    if (newQuery) {
      setCurrentQuery(newQuery);
    }
    const queryToFetch = newQuery || currentQuery;
    const enquiriesDetails = await getEnquiriesWithAggregation(queryToFetch);

    setEnquiries(enquiriesDetails.enquiries);
    setTotalOfEnquiries(enquiriesDetails.totalCount);
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    fetchEnquiries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { enquiries, setEnquiries, totalOfEnquiries, setTotalOfEnquiries, currentQuery, setCurrentQuery, fetchEnquiries };
};

const constructFilterQuery = filters => {
  const keys = Object.keys(filters);
  const filterQuery = {};
  keys.forEach(key => (filterQuery[key] = filters[key][0]));
  return filterQuery;
};

const getColumns = ({
  enquirerIdentities,
  enquiryStatuses,
  originalEnquiryStatuses,
  setSelectedEnquiry,
  setAssignPICButtonClicked,
  setQuickUpdateButtonClicked,
  isAdmin
}) => {
  const columns = [
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      width: '15%',
      ...getColumnFilterSearchProps('ticketNumber', 'Search ticket number'),
      render: (text, record) => {
        return (
          <a href={getEnquiryRoute(record._id).path} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        );
      }
    },
    {
      title: 'Enquirer Name',
      dataIndex: 'enquirerName',
      key: 'enquirerName',
      ...getColumnFilterSearchProps('enquirerName', 'Search enquirer'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Identity',
      dataIndex: 'enquirerIdentity',
      key: 'enquirerIdentity',
      sorter: true,
      ...getColumnFilterRadioProps('enquirerIdentity', enquirerIdentities),
      render: text => <span style={{ textTransform: 'capitalize' }}>{constructConstantsLabel(enquirerIdentities, text)}</span>
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnFilterSearchProps('companyName', 'Search company name'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      sorter: true,
      ...getColumnFilterRadioProps('status', enquiryStatuses),
      render: text => (text ? <span style={{ textTransform: 'capitalize' }}>{constructConstantsLabel(enquiryStatuses, text)}</span> : <span>-</span>)
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '15%',
      render: text => moment(text).format('YYYY-MM-DD')
    }
  ];

  if (isAdmin) {
    columns.push({
      title: 'PIC',
      dataIndex: 'picName',
      key: 'picName',
      ...getColumnFilterSearchProps('picName', 'Search PIC'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    });
  }

  columns.push({
    title: <div style={{ textAlign: 'center' }}>Actions</div>,
    width: '15%',
    render: (text, record) => {
      const isAllowAssignPIC =
        isAdmin &&
        originalEnquiryStatuses.END &&
        originalEnquiryStatuses.DEAL &&
        record.status !== originalEnquiryStatuses.END.code &&
        record.status !== originalEnquiryStatuses.DEAL.code;

      return (
        <ActionButtonContainer>
          {isAllowAssignPIC && (
            <Button
              type={record.assignedTo ? 'secondary' : 'primary'}
              onClick={() => {
                setSelectedEnquiry(record);
                setAssignPICButtonClicked(true);
              }}
            >
              <Icon type="user-add" />
            </Button>
          )}{' '}
          <Button
            type="primary"
            onClick={() => {
              setSelectedEnquiry(record);
              setQuickUpdateButtonClicked(true);
            }}
          >
            <Icon type="audit" />
          </Button>
        </ActionButtonContainer>
      );
    }
  });

  return columns;
};

const handleOnTableChange = async ({ pagination, filters, sorter }, fetchEnquiries) => {
  const filter = constructFilterQuery(filters);
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(filters && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && columnKey && { sort: encodeURIComponent(JSON.stringify({ [columnKey]: order === 'descend' ? -1 : 1 })) }),
    ...(pagination && current && { pagination: encodeURIComponent(JSON.stringify({ limit: pageSize, currentPage: current })) })
  };
  fetchEnquiries(query);
};

const Enquiry = ({ isAdmin }) => {
  const { enquiries, totalOfEnquiries, fetchEnquiries } = useLoadEnquiries();
  const { selections: enquiryStatuses, originalSelection: originalEnquiryStatuses } = useFetchConstant('enquiryStatuses');
  const { selections: priorities } = useFetchConstant('priorities');
  const { selections: enquirerIdentities } = useFetchConstant('enquirerIdentities');

  const [selectedEnquiry, setSelectedEnquiry] = useState({});
  const [isAssignPICButtonClicked, setAssignPICButtonClicked] = useState(false);
  const [isQuickUpdateButtonClicked, setQuickUpdateButtonClicked] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);

  return (
    <Card>
      <EnquiryHeader>
        <Link to={newEnquiryRoute.path}>
          <Button type="primary" icon="plus">
            Create Enquiry
          </Button>
        </Link>
        <Button loading={showCSVModal} type="primary" icon="download" onClick={() => setShowCSVModal(true)}>
          {showCSVModal ? 'Downloading CSV...' : 'Download CSV'}
        </Button>
      </EnquiryHeader>
      <DownloadCSVModal showModal={showCSVModal} closeModal={() => setShowCSVModal(false)} type="Enquiry" />
      <Table
        onChange={(pagination, filters, sorter) => handleOnTableChange({ pagination, filters, sorter }, fetchEnquiries)}
        rowKey={record => record._id}
        dataSource={enquiries}
        scroll={{ x: 1000 }}
        pagination={{ total: totalOfEnquiries }}
        columns={getColumns({
          enquirerIdentities,
          enquiryStatuses,
          originalEnquiryStatuses,
          priorities,
          setSelectedEnquiry,
          setAssignPICButtonClicked,
          setQuickUpdateButtonClicked,
          isAdmin
        })}
      />
      {isAdmin && (
        <AssignEnquiryPICModal
          showModal={isAssignPICButtonClicked}
          selectedEnquiry={selectedEnquiry}
          closeModal={() => setAssignPICButtonClicked(false)}
          onAssignPICSuccess={() => fetchEnquiries()}
        />
      )}
      {isQuickUpdateButtonClicked && (
        <QuickUpdateModal
          showModal={isQuickUpdateButtonClicked}
          selectedEnquiryId={selectedEnquiry._id}
          closeModal={() => setQuickUpdateButtonClicked(false)}
          onQuickUpdateSuccess={() => fetchEnquiries()}
        />
      )}
    </Card>
  );
};

export default withAppContext(withRouter(Enquiry));
