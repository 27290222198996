import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { DEFAULT_LAT_LNG } from 'utils/constants';

const GoogleMapWithOneMarker = props => {
  const { setCoords, latitude, longitude, zoom } = props;
  const position = latitude && longitude ? { lat: latitude, lng: longitude } : undefined;
  const googleMapProps = {
    zoom,
    center: DEFAULT_LAT_LNG
  };
  if (position) {
    googleMapProps.center = position;
  }
  return (
    <GoogleMap
      {...googleMapProps}
      id="google-map"
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
    >
      {position && (
        <Marker
          position={position}
          draggable={true}
          onDragEnd={e => {
            setCoords({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
          }}
        />
      )}
    </GoogleMap>
  );
};

export default GoogleMapWithOneMarker;
