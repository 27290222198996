import api from './apiHelper';

export const getUsers = (query = {}) => api.get('/user', { params: query });

export const getUsersWithAggregation = (query = {}) => api.get('/user/aggregation', { params: query });

export const getUserById = userId => api.get(`/user/${userId}`);

export const postSubmitUser = userBody => api.post('/user/', userBody);

export const putUpdateUser = (userId, userBody) => api.put(`/user/${userId}`, userBody);

export const deleteUser = userId => api.delete(`/user/${userId}`);
