import React, { useState, useEffect } from 'react';
import { Col, Radio, Row, Table, notification } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getUpcomingEnquiries } from 'apis/enquiry';
import Card from 'components/Card/Card';
import { PRIORITY_COLOR_HIGH, PRIORITY_COLOR_MEDIUM, PRIORITY_COLOR_LOW } from 'utils/constants';
import { getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';

const ENQUIRY_DATE_RANGE_TODAY = 0;
const ENQUIRY_DATE_RANGE_7DAYS = 7;
const ENQUIRY_DATE_RANGE_30DAYS = 30;

const StyledTable = styled(Table)`
  height: 300px;
`;

const constructPriorityDisplay = priority => {
  const PriorityContainer = styled.div`
    display: flex;
  `;

  const PriorityIndicator = styled.div`
    background-color: ${props => props.color};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 8px;
  `;

  const PriorityLabel = styled.div`
    text-transform: capitalize;
    line-height: 8px;
  `;

  let color = PRIORITY_COLOR_LOW;
  if (priority === 'high') {
    color = PRIORITY_COLOR_HIGH;
  } else if (priority === 'medium') {
    color = PRIORITY_COLOR_MEDIUM;
  }

  return (
    <PriorityContainer>
      <PriorityIndicator color={color} />
      <PriorityLabel>{priority}</PriorityLabel>
    </PriorityContainer>
  );
};

const handleOnFilterOfTable = (dataIndex, currentValue, record) =>
  record[dataIndex]
    .toString()
    .toLowerCase()
    .includes(currentValue.toLowerCase());

const getColumns = priorities => [
  {
    title: 'Ticket Number',
    dataIndex: 'ticketNumber',
    key: 'ticketNumber',
    ...getColumnFilterSearchProps('ticketNumber', 'Search ticket number'),
    onFilter: (value, record) => handleOnFilterOfTable('ticketNumber', value, record),
    render: (text, record) => {
      return <Link to={`/enquiries/${record._id}`}>{text}</Link>;
    }
  },
  {
    title: 'Enquirer',
    dataIndex: 'enquirerName',
    key: 'enquirerName',
    ...getColumnFilterSearchProps('enquirerName', 'Search enquirer'),
    onFilter: (value, record) => handleOnFilterOfTable('enquirerName', value, record)
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    ...getColumnFilterRadioProps('priority', priorities),
    onFilter: (value, record) => handleOnFilterOfTable('priority', value, record),
    render: text => (text ? constructPriorityDisplay(text) : <span>-</span>)
  },
  {
    title: 'Follow Up Date',
    dataIndex: 'followUpDate',
    key: 'followUpDate',
    sorter: (prev, next) => prev.followUpDate && next.followUpDate && prev.followUpDate.localeCompare(next.followUpDate)
  }
];

const useLoadEnquiryData = (dateRange, assignedTo) => {
  const [enquiriesData, setEnquiriesData] = useState([]);
  useEffect(() => {
    const fetchUnassignedEnquiries = async () => {
      const enquiryResponse = await getUpcomingEnquiries({ dateRange, assignedTo }).catch((e) => {
        notification.error({
          message: 'Error while fetching upcoming enquiries',
          description: e.message
        });
        return []
      });
      setEnquiriesData(enquiryResponse);
    };
    if (assignedTo) {
      fetchUnassignedEnquiries();
    }
  }, [dateRange, assignedTo]);
  return enquiriesData;
};

const UpcomingEnquiriesCard = ({ priorities, assignedTo }) => {
  const title = 'Upcoming Enquiries';
  const [dateRange, setDateRange] = useState(ENQUIRY_DATE_RANGE_7DAYS);
  const data = useLoadEnquiryData(dateRange, assignedTo);

  return (
    <Card
      title={
        <Row type="flex" justify="space-between">
          <Col>{title}</Col>
          <Col>
            <Radio.Group value={dateRange} onChange={e => setDateRange(e.target.value)} buttonStyle="solid">
              <Radio.Button value={ENQUIRY_DATE_RANGE_TODAY}>Today</Radio.Button>
              <Radio.Button value={ENQUIRY_DATE_RANGE_7DAYS}>7 Days</Radio.Button>
              <Radio.Button value={ENQUIRY_DATE_RANGE_30DAYS}>30 Days</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      }
    >
      <StyledTable rowKey={record => record._id} dataSource={data} columns={getColumns(priorities)} pagination={{ pageSize: 3 }} />
    </Card>
  );
};

export default UpcomingEnquiriesCard;
