import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Modal } from 'antd';
import styled from 'styled-components/macro';

const Title = styled.div`
  margin: -24px;
  padding: 24px;
  font-size: 24px;
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.white};
  font-weight: bold;
`;

const ActionSelection = styled.div`
  cursor: pointer;
  color: ${props => props.theme.color.primary} !important;
  width: auto;
`;

const NewEnquirySummaryModal = ({ enquiry, onClickCreateAnotherEnquiry, visible }) => {
  return (
    <Modal
      bodyStyle={{ fontSize: window.innerWidth > 1200 ? 18 : 14 }}
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={false}
      width="40%"
      title={<Title>{enquiry.ticketNumber}</Title>}
      visible={visible}
    >
      <p>You have successfully created the enquiry!</p>
      <div>
        <ActionSelection>
          <Icon type="plus-square-o" />
          <span onClick={onClickCreateAnotherEnquiry}> Create another enquiry</span>
        </ActionSelection>
        <ActionSelection>
          <Icon type="eye-o" className="icon-eye" />
          <Link to={`/enquiries/${enquiry._id}`}> View the created enquiry</Link>
        </ActionSelection>
        <ActionSelection>
          <Icon type="solution" />
          <Link to="/enquiries"> Go back to Enquiries</Link>
        </ActionSelection>
      </div>
    </Modal>
  );
};

export default NewEnquirySummaryModal;
