import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Tooltip, Input } from 'antd';
import styled from 'styled-components';

const ActionContainer = styled.span`
  padding: 12px 50px;
`;

const MakePrimaryAction = ({ onClick }) => (
  <Tooltip placement="top" title="Make primary photo">
    <ActionContainer onClick={onClick}>
      <Icon type="camera-o" />
    </ActionContainer>
  </Tooltip>
);

const DeleteAction = ({ onClick }) => (
  <ActionContainer onClick={onClick}>
    <Icon type="delete" />
  </ActionContainer>
);

const Caption = ({ value, isEdit, onStatusChange, onCaptionUpdate }) => {
  if (isEdit) {
    return <Input.TextArea autosize autoFocus onBlur={onCaptionUpdate} onPressEnter={onCaptionUpdate} defaultValue={value} />;
  }
  return (
    <div className="ant-card-meta-description" onClick={() => onStatusChange(true)}>
      {value || <em>Enter your caption here</em>}
    </div>
  );
};

const PhotoCardContainer = styled(Card)`
  width: 100%;
`;

const PhotoCardCoverContainer = styled.div`
  @media (min-width: 768px) {
    && {
      height: 300px;
      background-color: #eeeeee;
      display: flex;
    }
  }
`;

const PhotoCardCoverImg = styled.img`
  max-width: 100%;

  @media (min-width: 768px) {
    & {
      max-height: 300px;
      display: block;
      margin: auto;
    }
  }
`;

const PhotoDisplayCard = ({ hasCaption, imageUrl, imageCaption, onCaptionChange, onMakePrimaryClick, onDelete }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleOnEdit = status => {
    setIsEditMode(status);
  };

  const handleOnCaptionUpdate = event => {
    setIsEditMode(false);
    return onCaptionChange(event.target.value);
  };

  return (
    <PhotoCardContainer
      hoverable
      cover={
        <PhotoCardCoverContainer>
          <PhotoCardCoverImg alt={imageCaption || 'image'} src={imageUrl} />
        </PhotoCardCoverContainer>
      }
      actions={[<MakePrimaryAction onClick={onMakePrimaryClick} />, <DeleteAction onClick={onDelete} />]}
    >
      {hasCaption && (
        <Card.Meta
          description={<Caption value={imageCaption} isEdit={isEditMode} onStatusChange={handleOnEdit} onCaptionUpdate={handleOnCaptionUpdate} />}
        />
      )}
    </PhotoCardContainer>
  );
};

PhotoDisplayCard.propTypes = {
  hasCaption: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  imageCaption: PropTypes.string,
  onCaptionChange: PropTypes.func,
  onMakePrimaryClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

PhotoDisplayCard.defaultProps = {
  hasCaption: true,
  onCaptionChange: () => {},
  onMakePrimaryClick: () => {},
  onDelete: () => {}
};

export default PhotoDisplayCard;
