import React, { useEffect, useState } from 'react';
import { Button, Icon, Table } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { withAppContext } from 'contexts/AppContext/AppContext';
import AssignHostPICModal from './components/AssignHostPICModal/AssignHostPICModal';
import SourceIcon from 'components/SourceIcon/SourceIcon';
import { getHostsWithAggregation } from 'apis/host';
import Card from 'components/Card/Card';
import { getDataAndExportCSV } from 'components/DownloadCSVModal/DownloadCSVModal';

import { useFetchConstant } from 'utils/hooks';
import { constructConstantsLabel, getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';
import { getHostRoute, getNewHostRoute } from 'utils/routes';

const newHostRoute = getNewHostRoute();

const HostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CenterAlignContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionButtonContainer = styled.div`
  text-align: center;
`;

const useLoadHosts = () => {
  const [currentQuery, setCurrentQuery] = useState({});
  const [hosts, setHosts] = useState([]);
  const [totalOfHosts, setTotalOfHosts] = useState(0);

  const fetchHosts = async newQuery => {
    if (newQuery) {
      setCurrentQuery(newQuery);
    }
    const queryToFetch = newQuery || currentQuery;
    const hostsDetails = await getHostsWithAggregation(queryToFetch);

    setHosts(hostsDetails.hosts);
    setTotalOfHosts(hostsDetails.totalCount);
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    fetchHosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { hosts, setHosts, totalOfHosts, setTotalOfHosts, currentQuery, setCurrentQuery, fetchHosts };
};

const constructFilterQuery = filters => {
  const keys = Object.keys(filters);
  const filterQuery = {};
  keys.forEach(key => (filterQuery[key] = filters[key][0]));
  return filterQuery;
};

const getColumns = (hostTypes, setSelectedHost, setAssignPICButtonClicked, isAdmin) => {
  let columns = [
    {
      title: 'Host Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...getColumnFilterSearchProps('name', 'Search name'),
      render: (text, record) => {
        return <Link to={getHostRoute(record._id).path}>{text}</Link>;
      }
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      ...getColumnFilterSearchProps('contactNumber', 'Search contact'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnFilterSearchProps('email', 'Search email'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Units On Hand',
      dataIndex: 'totalNoOfUnits',
      key: 'totalNoOfUnits',
      width: '15%',
      sorter: true,
      render: totalNoOfUnits => <CenterAlignContainer>{totalNoOfUnits ? <span>{totalNoOfUnits}</span> : <span>0</span>}</CenterAlignContainer>
    },
    {
      title: 'Host Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      sorter: true,
      ...getColumnFilterRadioProps('type', hostTypes),
      render: text => (text ? <span style={{ textTransform: 'capitalize' }}>{constructConstantsLabel(hostTypes, text)}</span> : <span>-</span>)
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: '10%',
      sorter: true,
      render: text => <SourceIcon icon={text} />
    }
  ];

  if (isAdmin) {
    columns.push(
      {
        title: 'PIC',
        dataIndex: 'picName',
        key: 'picName',
        ...getColumnFilterSearchProps('picName', 'Search PIC'),
        render: text => (text ? <span>{text}</span> : <span>-</span>)
      },
      {
        title: 'Assign PIC',
        width: '10%',
        render: (text, record) => (
          <ActionButtonContainer>
            <Button
              type={record.assignedTo ? 'secondary' : 'primary'}
              onClick={() => {
                setSelectedHost(record);
                setAssignPICButtonClicked(true);
              }}
            >
              <Icon type="user-add" />
            </Button>
          </ActionButtonContainer>
        )
      }
    );
  }

  return columns;
};

const handleOnTableChange = async ({ pagination, filters, sorter }, fetchHosts) => {
  const filter = constructFilterQuery(filters);
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(filters && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && columnKey && { sort: encodeURIComponent(JSON.stringify({ [columnKey]: order === 'descend' ? -1 : 1 })) }),
    ...(pagination && current && { pagination: encodeURIComponent(JSON.stringify({ limit: pageSize, currentPage: current })) })
  };
  fetchHosts(query);
};

const Host = ({ isAdmin, user }) => {
  const { hosts, totalOfHosts, fetchHosts } = useLoadHosts();
  const { selections: hostTypes } = useFetchConstant('hostTypes');

  const [selectedHost, setSelectedHost] = useState({});
  const [isAssignPICButtonClicked, setAssignPICButtonClicked] = useState(false);
  const [isDownloadCSVButtonLoading, setIsDownloadCSVButtonLoading] = useState(false);

  const handleOnDownloadCSVClick = () => {
    const filename = `${isAdmin ? 'All-Host' : `Host-by-PIC-${user.username}`}.csv`;
    getDataAndExportCSV({ setIsLoading: setIsDownloadCSVButtonLoading, type: 'Host', filename });
  };

  return (
    <>
      <Card>
        <HostHeader>
          <Link to={newHostRoute.path}>
            <Button type="primary" icon="plus">
              Create Host
            </Button>
          </Link>
          <Button loading={isDownloadCSVButtonLoading} type="primary" icon="download" onClick={handleOnDownloadCSVClick}>
            {isDownloadCSVButtonLoading ? 'Downloading CSV...' : 'Download CSV'}
          </Button>
        </HostHeader>
        <Table
          onChange={(pagination, filters, sorter) => handleOnTableChange({ pagination, filters, sorter }, fetchHosts)}
          rowKey={record => record._id}
          dataSource={hosts}
          scroll={{ x: 1000 }}
          pagination={{ total: totalOfHosts }}
          columns={getColumns(hostTypes, setSelectedHost, setAssignPICButtonClicked, isAdmin)}
        />
        {isAdmin && (
          <AssignHostPICModal
            showModal={isAssignPICButtonClicked}
            selectedHost={selectedHost}
            closeModal={() => setAssignPICButtonClicked(false)}
            onAssignPICSuccess={fetchHosts}
          />
        )}
      </Card>
    </>
  );
};

export default withAppContext(withRouter(Host));
