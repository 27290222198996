import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { InputNumber } from 'antd';

import FormItem from 'components/FormItem/FormItem';

const StyledInputNumber = styled(InputNumber)`
  width: 100% !important;
`;

const FormInputNumber = ({
  form,
  name,
  defaultValue,
  className,
  placeholder,

  minValue,
  maxValue,
  precision,
  formatter,
  parser,

  onChange,
  isPrice,
  isDisabled,

  extraRules,
  ...props
}) => {
  if (isPrice) {
    minValue = 0;
    precision = 2;
    formatter = value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    parser = value => value.replace(/[^0-9.]/g, '');
  }

  const numberRules = [
    {
      pattern: precision ? /^\d+\.?\d*$/ : /^[0-9]*$/,
      message: precision ? `Only number with ${precision} decimal points is allowed` : `Only number is allowed`
    }
  ];

  return (
    <FormItem form={form} name={name} defaultValue={defaultValue} extraRules={[...extraRules, ...numberRules]} {...props}>
      <StyledInputNumber
        className={className}
        placeholder={placeholder}
        min={minValue}
        max={maxValue}
        precision={precision}
        formatter={formatter}
        parser={parser}
        onChange={onChange}
        disabled={isDisabled}
      />
    </FormItem>
  );
};

FormInputNumber.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  precision: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  onChange: PropTypes.func,
  isPrice: PropTypes.bool,
  isDisabled: PropTypes.bool,
  extraRules: PropTypes.array
};

FormInputNumber.defaultProps = {
  defaultValue: undefined,
  className: '',
  placeholder: '',
  minValue: 0,
  maxValue: undefined,
  precision: 0,
  formatter: undefined,
  parser: undefined,
  onChange: () => {},
  isPrice: false,
  isDisabled: false,
  extraRules: []
};

export default FormInputNumber;
