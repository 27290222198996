import api from './apiHelper';

export const getBuildings = (query = {}) => api.get('/building', { params: query });

export const getBuildingsWithAggregation = (query = {}) => api.get('/building/aggregation', { params: query });

export const getBuildingById = buildingId => api.get(`/building/${buildingId}`);

export const getSearchBuildings = (name, selectedBuildings) =>
  api.get('/building/search', { params: { name, buildingsString: selectedBuildings.join(',') } });

export const postSubmitBuilding = buildingBody => api.post('/building/', buildingBody);

export const putUpdateBuilding = (buildingId, buildingBody) => api.put(`/building/${buildingId}`, buildingBody);

export const deleteBuilding = buildingId => api.delete(`/building/${buildingId}`);

export const checkIsBuildingNameExist = (query = {}) => api.get(`/building/checkBuildingNameExist`, { params: query });
