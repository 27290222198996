import React from 'react';
import { LoadScript } from '@react-google-maps/api';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AppContextProvider } from 'contexts/AppContext/AppContext';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

import StandardLayout from 'layouts/StandardLayout/StandardLayout';

import App from 'pages/App/App';
import ActivityLogs from 'pages/ActivityLogs/ActivityLogs';
import Dashboard from 'pages/Dashboard/Dashboard';
import Enquiry from 'pages/Enquiry/Enquiry';
import EnquiryFormCreate from 'pages/EnquiryForm/EnquiryFormCreate';
import EnquiryFormEdit from 'pages/EnquiryForm/EnquiryFormEdit';
import Host from 'pages/Host/Host';
import HostForm from 'pages/HostForm/HostForm';
import SalesReport from 'pages/SalesReport/SalesReport';
import User from 'pages/User/User';
import UserForm from 'pages/UserForm/UserForm';
import Login from 'pages/Login/Login';
import Building from 'pages/Building/Building';
import BuildingForm from 'pages/BuildingForm/BuildingForm';
import PartnerListings from 'pages/PartnerListings/PartnerListings';
import CampaignHost from 'pages/CampaignHost/CampaignHost';
import CampaignHostForm from 'pages/CampaignHostForm/CampaignHostForm';

import Forbidden403 from 'pages/403/403';
import NotFound404 from 'pages/404/404';

import history from 'utils/browserHistory';
import { original } from 'utils/theme';
import {
  getDashboardRoute,
  getEnquiriesRoute,
  getNewEnquiryRoute,
  getEnquiryRoute,
  getHostsRoute,
  getNewHostRoute,
  getHostRoute,
  getUsersRoute,
  getNewUserRoute,
  getUserRoute,
  getSalesReportRoute,
  getActivityLogsRoute,
  getBuildingsRoute,
  getNewBuildingRoute,
  getBuildingRoute,
  getPartnerListingsRoute,
  getCampaignHostsRoute,
  getNewCampaignHostRoute,
  getCampaignHostRoute,
  getLoginRoute,
  getForbiddenRoute,
  getNotFoundRoute
} from 'utils/routes';

import * as serviceWorker from './serviceWorker';
import './index.css';

const dashboardRoute = getDashboardRoute();
const enquiriesRoute = getEnquiriesRoute();
const newEnquiryRoute = getNewEnquiryRoute();
const enquiryRoute = getEnquiryRoute();
const hostsRoute = getHostsRoute();
const newHostRoute = getNewHostRoute();
const hostRoute = getHostRoute();
const usersRoute = getUsersRoute();
const newUserRoute = getNewUserRoute();
const userRoute = getUserRoute();
const salesReportRoute = getSalesReportRoute();
const activityLogsRoute = getActivityLogsRoute();
const buildingsRoute = getBuildingsRoute();
const newBuildingRoute = getNewBuildingRoute();
const buildingRoute = getBuildingRoute();
const partnerListingsRoute = getPartnerListingsRoute();
const campaignHostsRoute = getCampaignHostsRoute();
const newCampaignHostRoute = getNewCampaignHostRoute();
const campaignHostRoute = getCampaignHostRoute();
const loginRoute = getLoginRoute();
const forbidden403Route = getForbiddenRoute();
const notFound404Route = getNotFoundRoute();

ReactDOM.render(
  <LoadScript id="script-loader" googleMapsApiKey="AIzaSyA7scv5SSve4lERPPPLQcs5u8wcJ5XzSLc" libraries={['places']}>
    <AppContextProvider>
      <ThemeProvider theme={original}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={App} />
            <Route exact path={loginRoute.path} component={Login} />
            <Route exact path={forbidden403Route.path} component={Forbidden403}/>
            <Route exact path={notFound404Route.path} component={NotFound404}/>
            <StandardLayout>
              <Switch>
                <ProtectedRoute exact path={dashboardRoute.path} accessRole={dashboardRoute.role} component={Dashboard} />
                <ProtectedRoute exact path={enquiriesRoute.path} accessRole={enquiriesRoute.role} component={Enquiry} />
                <ProtectedRoute exact path={newEnquiryRoute.path} accessRole={newEnquiryRoute.role} component={EnquiryFormCreate} />
                <ProtectedRoute exact path={enquiryRoute.path} accessRole={enquiryRoute.role} component={EnquiryFormEdit} />
                <ProtectedRoute exact path={hostsRoute.path} accessRole={hostsRoute.role} component={Host} />
                <ProtectedRoute exact path={newHostRoute.path} accessRole={newHostRoute.role} component={HostForm} />
                <ProtectedRoute exact path={hostRoute.path} accessRole={hostRoute.role} component={HostForm} />
                <ProtectedRoute exact path={usersRoute.path} accessRole={usersRoute.role} component={User} />
                <ProtectedRoute exact path={newUserRoute.path} accessRole={newUserRoute.role} component={UserForm} />
                <ProtectedRoute exact path={userRoute.path} accessRole={userRoute.role} component={UserForm} />
                <ProtectedRoute exact path={salesReportRoute.path} accessRole={salesReportRoute.role} component={SalesReport} />
                <ProtectedRoute exact path={activityLogsRoute.path} accessRole={activityLogsRoute.role} component={ActivityLogs} />
                <ProtectedRoute exact path={buildingsRoute.path} accessRole={buildingsRoute.role} component={Building} />
                <ProtectedRoute exact path={newBuildingRoute.path} accessRole={newBuildingRoute.role} component={BuildingForm} />
                <ProtectedRoute exact path={buildingRoute.path} accessRole={buildingRoute.role} component={BuildingForm} />
                <ProtectedRoute exact path={partnerListingsRoute.path} accessRole={partnerListingsRoute.role} component={PartnerListings} />
                <ProtectedRoute exact path={campaignHostsRoute.path} accessRole={campaignHostsRoute.role} component={CampaignHost} />
                <ProtectedRoute exact path={newCampaignHostRoute.path} accessRole={newCampaignHostRoute.role} component={CampaignHostForm} />
                <ProtectedRoute exact path={campaignHostRoute.path} accessRole={campaignHostRoute.role} component={CampaignHostForm} />
                <Redirect from="*" to={notFound404Route.path} />
              </Switch>
            </StandardLayout>
            <Redirect from="*" to={notFound404Route.path} />
          </Switch>
        </Router>
      </ThemeProvider>
    </AppContextProvider>
  </LoadScript>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
