// This component is used to generate hidden field for antd so we can use the value
// without displaying any UI
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const FormHidden = ({ form, name, defaultValue }) => {
  return (
    <>
      {form.getFieldDecorator(name, {
        initialValue: defaultValue
      })(<Input type="hidden" />)}
    </>
  );
};

FormHidden.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any
};

export default FormHidden;
