import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import UploadPhotoContainer from './components/UploadPhotoContainer/UploadPhotoContainer';

const Root = styled.div`
  width: 100%;
  height: 300px;

  @media (min-width: 768px) {
    height: 100%;
  }
`;

const ImageContainer = styled.div`
  padding: 10px;
`;

const Image = styled.img`
  max-width: 100%;
  min-height: 100px;
  min-width: 100px;
  cursor: pointer;

  @media (min-width: 768px) {
    & {
      max-height: 300px;
      display: block;
      margin: auto;
    }
  }
`;

const HelperText = styled.p`
  color: ${props => props.theme.color.lightGrey};
  font-size: 12px;
  font-style: italic;
`;

const UploadSinglePhoto = ({ onUploadFinish, photo }) => {
  return (
    <Root>
      {photo ? (
        <UploadPhotoContainer onUploadFinish={onUploadFinish}>
          <ImageContainer>
            <Image src={photo} />
            <HelperText>Click here to upload again</HelperText>
          </ImageContainer>
        </UploadPhotoContainer>
      ) : (
        <UploadPhotoContainer onUploadFinish={onUploadFinish} />
      )}
    </Root>
  );
};

UploadSinglePhoto.propTypes = {
  onUploadFinish: PropTypes.func.isRequired,
  photo: PropTypes.string
};

UploadSinglePhoto.defaultProps = {
  onUploadFinish: () => {},
  photo: ''
};

export default UploadSinglePhoto;
