import React from 'react';
import PropTypes from 'prop-types';

import HostPlatformIcon from './images/hostplatform-icon.png';
import HostSearchIcon from './images/hostsearch-icon.png';

import styled from 'styled-components/macro';

const CenterAlignContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const supportedIcons = {
  hostplatform: {
    imgSrc: HostPlatformIcon,
    alt: 'hostplatform-icon'
  },
  hostsearch: {
    imgSrc: HostSearchIcon,
    alt: 'hostsearch-icon'
  }
};

const SourceIcon = ({ icon }) => {
  const foundIcon = supportedIcons[icon];
  
  return foundIcon ? (
    <CenterAlignContainer>
      <img src={foundIcon.imgSrc} alt={foundIcon.alt} height="24px" />
    </CenterAlignContainer>
  ) : <span>No Icon</span>;
};

SourceIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(supportedIcons)).isRequired,
};

export default SourceIcon;
