import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button, Col, Form, List, Modal, Row, Tabs } from 'antd';

import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';
import FormInput from 'components/FormInput/FormInput';
import FormBuildingSuggestions from 'components/FormBuildingSuggestions/FormBuildingSuggestions';
import Card from 'components/Card/Card';
import LocationWithGoogleMap, { useLngLat } from 'components/LocationWithGoogleMap/LocationWithGoogleMap';

import { useEditItem } from 'utils/hooks';
import { purifyPayload, getCurrentIndexForPaginatedList } from 'utils/general';

const { TabPane } = Tabs;

const AIRBNB_STATUS_SELECTIONS = [{ value: 1, label: 'Banned' }, { value: 0, label: 'Not Banned' }];

const ModalActionButtons = ({ canEdit, onSave, onCancel }) => {
  return (
    <Row gutter={8} type="flex">
      <Col>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Col>
      {canEdit && (
        <Col>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Col>
      )}
    </Row>
  );
};

ModalActionButtons.propTypes = {
  canEdit: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

ModalActionButtons.defaultProps = {
  canEdit: true,
  onSave: () => {},
  onCancel: () => {}
};

const BuildingInfoTab = ({
  form,
  canEdit,
  defaultValue,
  buildingTypes,
  buildingTenures,
  buildingTitles,
  latitude,
  longitude,
  onMapCoordinateChange,
  buildingId,
  setBuildingId,
  selectedBuildings
}) => {
  const updateBuildingId = selectedBuildingId => {
    if (!!selectedBuildingId) {
      setBuildingId(selectedBuildingId);
    } else if (buildingId !== '') {
      setBuildingId('');
    }
  };

  const handleOnSelectBuilding = selectedProperty => {
    form.setFieldsValue({
      name: selectedProperty.name,
      propertyAddress: selectedProperty.streetAddress,
      propertyCity: selectedProperty.city,
      propertyZipcode: selectedProperty.zipcode,
      propertyCountry: selectedProperty.country,
      propertyState: selectedProperty.state,
      totalNoOfUnits: selectedProperty.totalNoOfUnits,
      type: selectedProperty.type,
      title: selectedProperty.title
    });
    onMapCoordinateChange(selectedProperty.longitude, selectedProperty.latitude);
    updateBuildingId(selectedProperty._id);
  };
  let defaultIsAirbnbBanned;
  if (defaultValue.isAirbnbBanned) {
    defaultIsAirbnbBanned = 1;
  } else if (defaultValue.isAirbnbBanned !== undefined) {
    defaultIsAirbnbBanned = 0;
  }

  return (
    <Row>
      <Card>
        <Row gutter={16}>
          <Col span={24} md={24}>
            <FormBuildingSuggestions
              form={form}
              defaultValue={defaultValue.name}
              onInputChange={updateBuildingId}
              onSelectBuilding={handleOnSelectBuilding}
              isDisabled={!canEdit}
              selectedBuildings={selectedBuildings}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="type"
              label="Building Type"
              defaultValue={defaultValue.type}
              selections={buildingTypes}
              isDisabled={!canEdit}
              size="large"
              placeholder="Select building type"
              requiredErrorMessage="Please select a building type"
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="title"
              label="Building Title"
              defaultValue={defaultValue.title}
              selections={buildingTitles}
              isDisabled={!canEdit}
              size="large"
              placeholder="Select building title"
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="tenure"
              label="Tenure"
              defaultValue={defaultValue.tenure}
              selections={buildingTenures}
              isDisabled={!canEdit}
              size="large"
              placeholder="Select tenure"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="totalNoOfUnits"
              label="Total No. of Units"
              size="large"
              defaultValue={defaultValue.totalNoOfUnits}
              isDisabled={!canEdit}
              placeholder="e.g. 50"
            />
          </Col>
          <Col span={24} md={12}>
            <FormRadioButton
              form={form}
              name="isAirbnbBanned"
              label="Airbnb Ban Status"
              selections={AIRBNB_STATUS_SELECTIONS}
              defaultValue={defaultIsAirbnbBanned}
              isDisabled={!canEdit}
            />
          </Col>
        </Row>
      </Card>
      <Card>
        <LocationWithGoogleMap
          form={form}
          defaultValue={defaultValue}
          latitude={latitude}
          longitude={longitude}
          onMapCoordinateChange={onMapCoordinateChange}
          isDisabled={!canEdit}
        />
      </Card>
    </Row>
  );
};

BuildingInfoTab.propTypes = {
  form: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  defaultValue: PropTypes.object,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onMapCoordinateChange: PropTypes.func,
  onMapMarkerMove: PropTypes.func
};

BuildingInfoTab.defaultProps = {
  canEdit: true,
  defaultValue: {},
  onMapCoordinateChange: () => {},
  onMapMarkerMove: () => {}
};

const UnitRow = ({ unit, onEdit, onDelete, canEdit }) => {
  const editIcon = canEdit ? 'edit' : 'eye';
  const editText = canEdit ? 'Edit' : 'View';

  return (
    <Row xs={24}>
      <Col sm={17} xs={24}>
        <Row>
          <Col>Room Size: {unit.size || '-'} sqft</Col>
          <Col>Status: {unit.furnishingStatus || '-'}</Col>
        </Row>
      </Col>
      <Col sm={7} xs={24}>
        <Row>
          <Button type="primary" icon={editIcon} onClick={onEdit} style={{ marginRight: '4px', marginBottom: '4px' }}>
            {editText}
          </Button>
          {canEdit && (
            <Button type="secondary" icon="delete" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

UnitRow.propTypes = {
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  unit: PropTypes.object.isRequired
};

UnitRow.defaultProps = {
  canEdit: true,
  onEdit: () => {},
  onDelete: () => {},
  unit: {}
};

const AddUnitButton = styled(Button)`
  margin-bottom: 16px;
  width: auto !important;
`;

const UnitModal = ({ form, defaultValue, furnishingStatuses, isVisible, canEdit, onSave, onCancel }) => {
  const handleOnSave = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const purifiedValues = purifyPayload(values);
      onSave(purifiedValues);
    });
  };
  return (
    <Modal width="1000px" maskClosable={false} visible={isVisible} onCancel={onCancel} footer={null} destroyOnClose>
      <Form onSubmit={handleOnSave}>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="name"
              label="Unit Name"
              size="large"
              defaultValue={defaultValue.name}
              isDisabled={!canEdit}
              placeholder="e.g. Deluxe Penthouse"
              requiredErrorMessage="Please enter the unit's name"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="size"
              label="Room Size (sqft)"
              size="large"
              defaultValue={defaultValue.size}
              isDisabled={!canEdit}
              extraRules={[
                {
                  pattern: /^[0-9]*$/,
                  message: 'Unit size must be a number'
                }
              ]}
              placeholder="e.g. 1000"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="noOfBedrooms"
              label="Number of Bedrooms"
              size="large"
              defaultValue={defaultValue.noOfBedrooms}
              isDisabled={!canEdit}
              extraRules={[
                {
                  pattern: /^[0-9]*$/,
                  message: 'Number of bedrooms must be a number'
                }
              ]}
              placeholder="e.g. 5"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="noOfBathrooms"
              label="Number of Bathrooms"
              size="large"
              defaultValue={defaultValue.noOfBathrooms}
              isDisabled={!canEdit}
              extraRules={[
                {
                  pattern: /^[0-9]*$/,
                  message: 'Number of bathrooms must be a number'
                }
              ]}
              placeholder="e.g. 4"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name="noOfCarParks"
              label="Number of Car Parks"
              size="large"
              defaultValue={defaultValue.noOfCarParks}
              isDisabled={!canEdit}
              extraRules={[
                {
                  pattern: /^[0-9]*$/,
                  message: 'Number of units must be a number'
                }
              ]}
              placeholder="e.g. 3"
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="furnishingStatus"
              label="Furnishing Status"
              defaultValue={defaultValue.furnishingStatus}
              selections={furnishingStatuses}
              isDisabled={!canEdit}
              size="large"
              placeholder="Select furnishing status"
              requiredErrorMessage="Please select a furnishing status"
            />
          </Col>
        </Row>
        <ModalActionButtons onSave={handleOnSave} onCancel={onCancel} />
      </Form>
    </Modal>
  );
};

UnitModal.propTypes = {
  form: PropTypes.object.isRequired,
  defaultValue: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

UnitModal.defaultProps = {
  defaultValue: {},
  isVisible: false,
  canEdit: true,
  onSave: () => {},
  onCancel: () => {}
};
const UnitModalWithForm = Form.create()(UnitModal);

const UnitInfoTab = ({ form, canEdit, units, furnishingStatuses, onEditUnit, onAddUnit, onDeleteUnit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { selectedItem: selectedUnit, selectedIndex, isEditing, updateEditItem: updateEditUnit, resetEditItem: resetEditUnit } = useEditItem();

  const handleOnModalCancel = useCallback(
    e => {
      e.preventDefault();
      setIsModalVisible(false);
      resetEditUnit();
    },
    [resetEditUnit]
  );
  const handleOnAddButtonClick = useCallback(
    e => {
      e.preventDefault();
      setIsModalVisible(true);
      resetEditUnit();
    },
    [resetEditUnit]
  );
  const handleOnUnitSave = useCallback(
    newUnit => {
      setIsModalVisible(false);
      if (isEditing) {
        onEditUnit(selectedIndex, newUnit);
      } else {
        onAddUnit(newUnit);
      }
    },
    [isEditing, onEditUnit, onAddUnit, selectedIndex]
  );
  const handleOnEditClick = useCallback(
    index => e => {
      e.preventDefault();
      updateEditUnit(index, units[index]);
      setIsModalVisible(true);
    },
    [units, updateEditUnit]
  );
  const handleOnDeleteClick = useCallback(
    index => e => {
      e.preventDefault();
      onDeleteUnit(index);
    },
    [onDeleteUnit]
  );
  return (
    <Row>
      <Card>
        {canEdit && (
          <AddUnitButton icon="plus" type="primary" onClick={handleOnAddButtonClick}>
            Add Unit
          </AddUnitButton>
        )}
        <List
          size="large"
          bordered
          dataSource={units}
          pagination={{
            onChange: page => setCurrentPage(page)
          }}
          renderItem={(item, index) => {
            const foundFurnishingStatus = furnishingStatuses.find(furnishingStatus => furnishingStatus.value === item.furnishingStatus);
            const currentItemIndex = getCurrentIndexForPaginatedList(index, currentPage);

            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Col span={18} style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                      {item.name}
                    </Col>
                  }
                  description={
                    <UnitRow
                      unit={{
                        ...item,
                        furnishingStatus: foundFurnishingStatus ? foundFurnishingStatus.label : item.furnishingStatus
                      }}
                      onEdit={handleOnEditClick(currentItemIndex)}
                      onDelete={handleOnDeleteClick(currentItemIndex)}
                      canEdit={canEdit}
                    />
                  }
                />
              </List.Item>
            );
          }}
        />
      </Card>
      <UnitModalWithForm
        defaultValue={selectedUnit}
        furnishingStatuses={furnishingStatuses}
        isVisible={isModalVisible}
        canEdit={canEdit}
        onCancel={handleOnModalCancel}
        onSave={handleOnUnitSave}
      />
    </Row>
  );
};

const BuildingModal = ({
  defaultValue,
  buildingTypes,
  buildingTenures,
  buildingTitles,
  furnishingStatuses,
  canEdit,
  onSave,
  onCancel,
  visible,
  form,
  selectedBuildings
}) => {
  const [units, setUnits] = useState(defaultValue.units || []);
  const [buildingId, setBuildingId] = useState(defaultValue._id);
  const { latitude, longitude, setCoordinates } = useLngLat(defaultValue.latitude, defaultValue.longitude);

  const handleOnMapCoordinateChange = useCallback(
    (latitude, longitude) => {
      setCoordinates(latitude, longitude);
    },
    [setCoordinates]
  );
  const handleOnSave = useCallback(
    e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        const buildingPayload = purifyPayload({
          ...values,
          _id: buildingId,
          isAirbnbBanned: !!values.isAirbnbBanned,
          streetAddress: values.propertyAddress,
          city: values.propertyCity,
          state: values.propertyState,
          zipcode: values.propertyZipcode,
          country: values.propertyCountry,
          latitude,
          longitude,
          units
        });
        onSave(buildingPayload);
        form.resetFields();
      });
    },
    [form, latitude, longitude, onSave, units, buildingId]
  );
  const handleOnAddUnit = useCallback(
    newUnit => {
      setUnits([...units, newUnit]);
    },
    [units]
  );
  const handleOnEditUnit = useCallback(
    (index, editedUnit) => {
      units[index] = editedUnit;
      setUnits(units);
    },
    [units]
  );
  const handleOnDeleteUnit = useCallback(
    index => {
      units.splice(index, 1);
      setUnits([...units]);
    },
    [units]
  );

  return (
    <Modal width="1000px" maskClosable={false} visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
      <Form onSubmit={handleOnSave}>
        <Tabs defaultActiveKey="buildingInformation">
          <TabPane tab="Building Information" key="buildingInformation" forceRender>
            <BuildingInfoTab
              form={form}
              canEdit={canEdit}
              defaultValue={defaultValue}
              buildingTypes={buildingTypes}
              buildingTenures={buildingTenures}
              buildingTitles={buildingTitles}
              latitude={latitude}
              longitude={longitude}
              onMapCoordinateChange={handleOnMapCoordinateChange}
              buildingId={buildingId}
              setBuildingId={setBuildingId}
              selectedBuildings={selectedBuildings}
            />
          </TabPane>
          <TabPane tab="Unit Information" key="unitInformation" forceRender>
            <UnitInfoTab
              form={form}
              canEdit={canEdit}
              units={units}
              furnishingStatuses={furnishingStatuses}
              onAddUnit={handleOnAddUnit}
              onEditUnit={handleOnEditUnit}
              onDeleteUnit={handleOnDeleteUnit}
            />
          </TabPane>
        </Tabs>
        <ModalActionButtons onSave={handleOnSave} onCancel={onCancel} />
      </Form>
    </Modal>
  );
};

export default Form.create()(BuildingModal);
