import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Row, Col, Form, Icon, Button, message, notification } from 'antd';
import { Link } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import FormInput from 'components/FormInput/FormInput';
import { getDashboardRoute } from 'utils/routes';

import bgImage from './images/background.svg';
import hasLogo from './images/icon.svg';

const dashboardRoute = getDashboardRoute();

const Root = styled.div`
  background-image: url('${bgImage}');
  background-size: cover;
  background-position-y: bottom;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
`;

const HostSearchLogoCol = styled(Col)`
  padding-left: 24px;
  padding-top: 10px;
`;

const TitleRow = styled(Row)`
  margin-top: 15vh;
`;

const TitleLabel = styled.label`
  color: white;
  font-size: 28px;
`;

const TitleNameLabel = styled.label`
  font-weight: 700;
`;

const LoginContainer = styled.div`
  padding: 24px;
  background-color: #ffffff;
`;
const UserLoginIcon = <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />;

const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
`;

const Login = ({ form, isLoggedIn, isUserLoading, onLogin, history }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && !isUserLoading) {
      if (isLoggedIn) {
        message.info('You already logged in.');
        history.push(dashboardRoute.path);
      }
    }
  }, [isLoggedIn, isUserLoading, isLoading, history]);

  const handleOnFormSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);
        onLogin(values.username.toLowerCase(), values.password)
          .then(() => {
            history.push(dashboardRoute.path);
          })
          .catch((errRes) => {
            if (errRes.code === '40001') {
              notification.error({
                duration: null,
                message: 'Your password is expired!',
                description: errRes.message
              });
            } else {
              form.setFields({
                username: { errors: [new Error('Invalid username or password')] },
                password: { errors: [new Error('Invalid username or password')] }
              });
            }
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div>
      <Root />
      <Row type="flex" justify="start">
        <HostSearchLogoCol>
          <Link to="/">
            <img alt="logo" src={hasLogo} width={180} />
          </Link>
        </HostSearchLogoCol>
      </Row>
      <TitleRow type="flex" justify="center">
        <Col>
          <TitleLabel>
            Welcome to <TitleNameLabel>HostSearch</TitleNameLabel>
          </TitleLabel>
        </Col>
      </TitleRow>
      <Row type="flex" justify="center">
        <Col lg={6} md={10} sm={15} span={20}>
          <LoginContainer>
            <Form onSubmit={handleOnFormSubmit}>
              <FormInput
                form={form}
                prefix={UserLoginIcon}
                placeholder="Username/Email"
                type="username"
                name="username"
                requiredErrorMessage="This is required"
              />
              <FormInput
                form={form}
                prefix={UserLoginIcon}
                placeholder="Password"
                type="password"
                name="password"
                requiredErrorMessage="This is required"
              />
              <Row>
                <LoginButton htmlType="submit" type="primary" disabled={isLoading} loading={isLoading}>
                  Login
                </LoginButton>
              </Row>
            </Form>
          </LoginContainer>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create()(withAppContext(Login));
