import React, { useState, useEffect } from 'react';
import { Col, Row, Select } from 'antd';
import styled from 'styled-components';

import { withAppContext } from 'contexts/AppContext/AppContext';
import Card from 'components/Card/Card';
import { useFetchConstant, useFetchUsersForSelection } from 'utils/hooks';

import StatisticsCard from './components/StatisticsCard/StatisticsCard';
import UpcomingEnquiriesCard from './components/UpcomingEnquiriesCard/UpcomingEnquiriesCard';
import AssignPICCard from './components/AssignPICCard/AssignPICCard';

const { Option } = Select;

const PICSelector = styled(Select)`
  padding-bottom: 16px !important;
`;

const Dashboard = ({ isAdmin, user }) => {
  const [assignedTo, setAssignedTo] = useState('');
  const { users: listOfPIC } = useFetchUsersForSelection(!!isAdmin);
  useEffect(() => {
    setAssignedTo(user._id);
  }, [isAdmin, user]);
  const { selections: priorities } = useFetchConstant('priorities');

  return (
    <>
      <Card>
        <Row type="flex" justify="end">
          {isAdmin && (
            <PICSelector
              showSearch
              style={{ width: 200 }}
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={newAssignedTo => setAssignedTo(newAssignedTo)}
              value={assignedTo}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {listOfPIC &&
                listOfPIC.map(pic => (
                  <Option key={pic.value} value={pic.value}>
                    {pic.label}
                  </Option>
                ))}
            </PICSelector>
          )}
        </Row>
        <Row gutter={8}>
          <Col span={24} lg={12}>
            <StatisticsCard assignedTo={assignedTo} />
          </Col>
          <Col span={24} lg={12}>
            <UpcomingEnquiriesCard priorities={priorities} assignedTo={assignedTo} />
          </Col>
        </Row>
      </Card>

      {isAdmin && (
        <Card>
          <AssignPICCard priorities={priorities} />{' '}
        </Card>
      )}
    </>
  );
};

export default withAppContext(Dashboard);
