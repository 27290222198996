import React from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { withAppContext } from 'contexts/AppContext/AppContext';
import {
  getDashboardRoute,
  getEnquiriesRoute,
  getCampaignHostsRoute,
  getHostsRoute,
  getUsersRoute,
  getSalesReportRoute,
  getActivityLogsRoute,
  getBuildingsRoute,
  getPartnerListingsRoute,
  getLoginRoute
} from 'utils/routes';

const dashboardRoute = { ...getDashboardRoute(), key: 'dashboard' };
const enquiriesRoute = { ...getEnquiriesRoute(), key: 'enquiries' };
const campaignHostsRoute = { ...getCampaignHostsRoute(), key: 'campaignHosts' };
const hostsRoute = { ...getHostsRoute(), key: 'hosts' };
const usersRoute = { ...getUsersRoute(), key: 'users' };
const salesReportRoute = { ...getSalesReportRoute(), key: 'reports' };
const activityLogsRoute = { ...getActivityLogsRoute(), key: 'activitylogs' };
const buildingsRoute = { ...getBuildingsRoute(), key: 'buildings' };
const loginRoute = { ...getLoginRoute(), key: 'login' };
const partnerListingsRoute = { ...getPartnerListingsRoute(), key: 'partner-listings' };

const MenuLink = styled(Link)`
  display: inline !important;
`;

const MenuItem = ({ icon, path, label, onClick }) => {
  return (
    <>
      <Icon type={icon} />
      <MenuLink to={path} onClick={onClick}>
        <span>{label}</span>
      </MenuLink>
    </>
  );
};

const getCurrentPathMenuKey = pathname => {
  switch (true) {
    case pathname.startsWith(dashboardRoute.path):
      return dashboardRoute.key;

    case pathname.startsWith(enquiriesRoute.path):
      return enquiriesRoute.key;

    case pathname.startsWith(partnerListingsRoute.path):
      return partnerListingsRoute.key;

    case pathname.startsWith(campaignHostsRoute.path):
      return campaignHostsRoute.key;

    case pathname.startsWith(hostsRoute.path):
      return hostsRoute.key;

    case pathname.startsWith(usersRoute.path):
      return usersRoute.key;

    case pathname.startsWith(salesReportRoute.path):
      return salesReportRoute.key;

    case pathname.startsWith(buildingsRoute.path):
      return buildingsRoute.key;

    case pathname.startsWith(activityLogsRoute.path):
      return activityLogsRoute.key;

    default:
      return '';
  }
};

const SideBar = ({ location, onLogout, checkCanUserAccess }) => {
  const currentKey = getCurrentPathMenuKey(location.pathname);

  return (
    <Menu
      onClick={() => {}}
      style={{ width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      defaultSelectedKeys={[currentKey]}
      selectedKeys={[currentKey]}
      mode="inline"
    >
      {checkCanUserAccess(dashboardRoute.role) && (
        <Menu.Item key={dashboardRoute.key}>
          <MenuItem icon="layout" path={dashboardRoute.path} label="Dashboard" />
        </Menu.Item>
      )}
      {checkCanUserAccess(enquiriesRoute.role) && (
        <Menu.Item key={enquiriesRoute.key}>
          <MenuItem icon="solution" path={enquiriesRoute.path} label="Enquiries" />
        </Menu.Item>
      )}
      {checkCanUserAccess(hostsRoute.role) && (
        <Menu.Item key={hostsRoute.key}>
          <MenuItem icon="team" path={hostsRoute.path} label="Hosts" />
        </Menu.Item>
      )}
      {checkCanUserAccess(buildingsRoute.role) && (
        <Menu.Item key={buildingsRoute.key}>
          <MenuItem icon="home" path={buildingsRoute.path} label="Buildings" />
        </Menu.Item>
      )}
      {checkCanUserAccess(usersRoute.role) && (
        <Menu.Item key={usersRoute.key}>
          <MenuItem icon="user" path={usersRoute.path} label="Users" />
        </Menu.Item>
      )}
      {checkCanUserAccess(campaignHostsRoute.role) && (
        <Menu.Item key={campaignHostsRoute.key}>
          <MenuItem icon="team" path={campaignHostsRoute.path} label="Campaign Hosts" />
        </Menu.Item>
      )}
      {checkCanUserAccess(partnerListingsRoute.role) && (
        <Menu.Item key={partnerListingsRoute.key}>
          <MenuItem icon="search" path={partnerListingsRoute.path} label="Partner Listings" />
        </Menu.Item>
      )}
      {checkCanUserAccess(salesReportRoute.role) && (
        <Menu.Item key={salesReportRoute.key}>
          <MenuItem icon="bar-chart" path={salesReportRoute.path} label="Sales Report" />
        </Menu.Item>
      )}
      {checkCanUserAccess(activityLogsRoute.role) && (
        <Menu.Item key={activityLogsRoute.key}>
          <MenuItem icon="control" path={activityLogsRoute.path} label="Activity Logs" />
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <MenuItem icon="logout" path={loginRoute.path} label="Logout" onClick={onLogout} />
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(withAppContext(SideBar));
