import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

const FormDatePicker = ({
  form,
  name,
  defaultValue,
  className,

  disabledDate,

  isDisabled,

  requiredErrorMessage,
  ...props
}) => {
  const isAllowClear = !requiredErrorMessage;

  return (
  <FormItem form={form} name={name} defaultValue={defaultValue} requiredErrorMessage={requiredErrorMessage} {...props}>
    <DatePicker
      className={className}
      disabledDate={disabledDate}
      allowClear={isAllowClear}
      disabled={isDisabled}
    />
  </FormItem>
)};

FormDatePicker.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(moment),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  requiredErrorMessage: PropTypes.string
};

FormDatePicker.defaultProps = {
  defaultValue: undefined,
  className: '',
  isDisabled: false,
  requiredErrorMessage: ''
};

export default FormDatePicker;