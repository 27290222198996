import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { getDashboardRoute, getLoginRoute } from 'utils/routes';

const dashboardRoute = getDashboardRoute();
const loginRoute = getLoginRoute();

const App = ({ isLoggedIn, isUserLoading, history }) => {
  useEffect(() => {
    if (!isUserLoading) {
      if (isLoggedIn) {
        history.replace(dashboardRoute.path);
      } else {
        history.replace(loginRoute.path);
      }
    }
  }, [isLoggedIn, isUserLoading, history]);
  return <Skeleton loading={true} />;
};

export default withRouter(withAppContext(App));
