import React, { useState, useEffect } from 'react';
import { Button, Icon, Table, notification } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getUnassignedEnquiries, getEnquiryById } from 'apis/enquiry';

import AssignEnquiryPICModal from 'components/AssignEnquiryPICModal/AssignEnquiryPICModal';
import Card from 'components/Card/Card';

import { PRIORITY_COLOR_HIGH, PRIORITY_COLOR_MEDIUM, PRIORITY_COLOR_LOW } from 'utils/constants';
import { getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';

const ActionButtonContainer = styled.div`
  text-align: center;
`;

const constructPriorityDisplay = priority => {
  const PriorityContainer = styled.div`
    display: flex;
  `;

  const PriorityIndicator = styled.div`
    background-color: ${props => props.color};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 8px;
  `;

  const PriorityLabel = styled.div`
    text-transform: capitalize;
    line-height: 8px;
  `;

  let color = PRIORITY_COLOR_LOW;
  if (priority === 'high') {
    color = PRIORITY_COLOR_HIGH;
  } else if (priority === 'medium') {
    color = PRIORITY_COLOR_MEDIUM;
  }

  return (
    <PriorityContainer>
      <PriorityIndicator color={color} />
      <PriorityLabel>{priority}</PriorityLabel>
    </PriorityContainer>
  );
};

const sorter = (prev, next) => {
  return prev && next && prev.localeCompare(next);
};

const handleOnFilterOfTable = (dataIndex, currentValue, record) =>
  record[dataIndex]
    .toString()
    .toLowerCase()
    .includes(currentValue.toLowerCase());

const getColumns = (priorities, isAssignPICButtonClicked, setAssignPICButtonClicked, setSelectedEnquiry) => [
  {
    title: 'Ticket Number',
    dataIndex: 'ticketNumber',
    key: 'ticketNumber',
    ...getColumnFilterSearchProps('ticketNumber', 'Search ticket number'),
    onFilter: (value, record) => handleOnFilterOfTable('ticketNumber', value, record),
    render: (text, record) => {
      return <Link to={`/enquiries/${record._id}`}>{text}</Link>;
    }
  },
  {
    title: 'Enquirer Name',
    dataIndex: 'enquirerName',
    key: 'enquirerName',
    ...getColumnFilterSearchProps('enquirerName', 'Search enquirer'),
    onFilter: (value, record) => handleOnFilterOfTable('enquirerName', value, record)
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
    ...getColumnFilterSearchProps('companyName', 'Search company name'),
    onFilter: (value, record) => handleOnFilterOfTable('companyName', value, record),
    render: text => text || <span>-</span>
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    ...getColumnFilterRadioProps('priority', priorities),
    render: text => (text ? constructPriorityDisplay(text) : <span>-</span>)
  },
  {
    title: 'Follow Up Date',
    dataIndex: 'followUpDate',
    key: 'followUpDate',
    sorter: (prev, next) => sorter(prev.followUpDate, next.followUpDate)
  },
  {
    title: 'Assign PIC',
    width: '10%',
    dataIndex: 'picName',
    key: 'picName',
    render: (picName, record) => (
      <ActionButtonContainer>
        <Button
          type={picName ? "secondary" : "primary"}
          onClick={() => {
            setSelectedEnquiry(record);
            setAssignPICButtonClicked(!isAssignPICButtonClicked);
          }}
        >
          <Icon type="user-add" />
        </Button>
      </ActionButtonContainer>
    )
  }
];

const useLoadUnassignedEnquiriesData = () => {
  const [unassignedEnquiries, setUnassignedEnquiries] = useState([]);
  useEffect(() => {
    const fetchUnassignedEnquiries = async () => {
      const enquiryResponse = await getUnassignedEnquiries().catch(e => {
        notification.error({
          message: 'Error while fetching unassigned enquiries',
          description: e.message
        });
        return [];
      });
      setUnassignedEnquiries(enquiryResponse);
    };
    fetchUnassignedEnquiries();
  }, []);
  return unassignedEnquiries;
};

const useLoadEnquiriesNewlyAssignedEnquiryData = (isSuccessfullyAssignedPIC, selectedEnquiry = {}) => {
  const [newlyAssignedEnquiry, setNewlyAssignedEnquiry] = useState({});

  useEffect(() => {
    const fetchNewlyAssignedEnquiry = async () => {
      if (isSuccessfullyAssignedPIC) {
        const newlyAssignedEnquiry = await getEnquiryById(selectedEnquiry._id);
        setNewlyAssignedEnquiry(newlyAssignedEnquiry);
      }
    };
    fetchNewlyAssignedEnquiry();
  }, [isSuccessfullyAssignedPIC, selectedEnquiry]);
  return newlyAssignedEnquiry;
};

const getConstructedEnquiries = (enquiries = [], newlyAssignedEnquiry = {}, selectedEnquiry = {}) => {
  return enquiries.map(enquiry =>
    String(enquiry._id) === String(selectedEnquiry._id)
      ? {
          _id: newlyAssignedEnquiry._id,
          enquirer: newlyAssignedEnquiry.enquirer && {
            firstName: newlyAssignedEnquiry.enquirer.firstName || undefined,
            lastName: newlyAssignedEnquiry.enquirer.lastName || undefined,
            companyName: newlyAssignedEnquiry.enquirer.companyName || undefined
          },
          priority: newlyAssignedEnquiry.priority,
          followUpDate: newlyAssignedEnquiry.followUpDate || undefined,
          ticketNumber: newlyAssignedEnquiry.ticketNumber,
          companyName: (newlyAssignedEnquiry.enquirer && newlyAssignedEnquiry.enquirer.companyName) || undefined,
          enquirerName:
            (newlyAssignedEnquiry.enquirer && `${newlyAssignedEnquiry.enquirer.firstName} ${newlyAssignedEnquiry.enquirer.lastName || '' }`) || '-',
          picName: `${newlyAssignedEnquiry.assignedTo}`
        }
      : enquiry
  );
};

const AssignPICCard = ({ priorities }) => {
  const [isAssignPICButtonClicked, setAssignPICButtonClicked] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState({});
  const [isSuccessfullyAssignedPIC, setIsSuccessfullyAssignedPIC] = useState(false);
  const enquiries = useLoadUnassignedEnquiriesData();
  const newlyAssignedEnquiry = useLoadEnquiriesNewlyAssignedEnquiryData(isSuccessfullyAssignedPIC, selectedEnquiry, enquiries);
  const data =
    isSuccessfullyAssignedPIC && Object.keys(newlyAssignedEnquiry).length > 0 ? getConstructedEnquiries(enquiries, newlyAssignedEnquiry, selectedEnquiry) : enquiries;
  return (
    <>
      <Card title="Pending PIC Assignment">
        <Table
          rowKey={record => record._id}
          dataSource={data}
          scroll={{ x: 800 }}
          columns={getColumns(priorities, isAssignPICButtonClicked, setAssignPICButtonClicked, setSelectedEnquiry)}
          pagination={{ pageSize: 5 }}
        />
      </Card>
      <AssignEnquiryPICModal
        showModal={isAssignPICButtonClicked}
        selectedEnquiry={selectedEnquiry}
        closeModal={() => {
          setAssignPICButtonClicked(!isAssignPICButtonClicked);
          setIsSuccessfullyAssignedPIC(false);
        }}
        onAssignPICSuccess={() => setIsSuccessfullyAssignedPIC(true)}
      />
    </>
  );
};

export default AssignPICCard;
