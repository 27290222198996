import api from './apiHelper';

export const postSubmitEnquiry = enquiryBody => api.post('/enquiry/', enquiryBody);

export const postEnquiryComment = (enquiryId, comment) => api.post(`/enquiry/${enquiryId}/comment`, comment);

export const getEnquiryById = enquiryId => api.get(`/enquiry/${enquiryId}`);

export const getParentEnquiries = enquiryId => api.get('/enquiry/parentTickets', { params: { enquiryId } });

export const getEnquiries = (query = {}) => api.get('/enquiry', { params: query });

export const getEnquiriesWithAggregation = (query = {}) => api.get('/enquiry/aggregation', { params: query });

export const getUnassignedEnquiries = () => api.get('enquiry/unassigned');

export const getUpcomingEnquiries = params => api.get(`enquiry/upcoming`, { params });

export const getStatistics = params => api.get(`enquiry/statistics`, { params });

export const putUpdateEnquiry = (enquiryId, enquiryBody) => api.put(`/enquiry/${enquiryId}`, enquiryBody);

export const patchUpdateEnquiryOtherInfo = (enquiryId, enquiryBody) => api.patch(`/enquiry/${enquiryId}/otherInfo`, enquiryBody);
