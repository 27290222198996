import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';

import FormItem from 'components/FormItem/FormItem';

const FormAutoComplete = ({
  form,
  name,
  defaultValue,
  selections,
  placeholder,
  className,
  size,

  onSearch,
  onSelect,

  isDisabled,

  ...props
}) => (
  <FormItem form={form} name={name} defaultValue={defaultValue} {...props}>
    <AutoComplete
      placeholder={placeholder}
      size={size}
      disabled={isDisabled}
      dataSource={selections}
      onSearch={onSearch}
      onSelect={onSelect}
    >
      {selections.map(selection => (
        <AutoComplete.Option key={selection.key || selection.value} value={selection.value}>
          {selection.label || selection.value}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  </FormItem>
);

FormAutoComplete.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large']),
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool
};

FormAutoComplete.defaultProps = {
  defaultValue: undefined,
  placeholder: '',
  className: '',
  size: 'default',
  selections: [],
  onSearch: () => {},
  onSelect: () => {},
  isDisabled: false
};

export default FormAutoComplete;
