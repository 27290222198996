import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { getSearchBuildings } from 'apis/building';

import FormAutoComplete from 'components/FormAutoComplete/FormAutoComplete';

const useFetchBuildingSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [rawBuildingsData, setRawBuildingsData] = useState([]);

  const onKeywordChange = useCallback((newKeyword, selectedBuildings = [], onInputChange) => {
    getSearchBuildings(newKeyword, selectedBuildings).then(data => {
      setSuggestions(
        data.map(building => {
          return {
            key: building._id,
            value: building.name,
            label: building.name
          };
        })
      );
      setRawBuildingsData(data);
      onInputChange();
    });
  }, []);

  return { suggestions, rawBuildingsData, onKeywordChange };
};

const FormBuildingSuggestions = ({ form, defaultValue, onInputChange, onSelectBuilding, selectedBuildings, ...props }) => {
  const { suggestions: buildingSuggestions, rawBuildingsData, onKeywordChange } = useFetchBuildingSuggestions();

  const handleOnSelectionChange = (selectedBuildingName, options) => {
    // Because form field always set back to id if I use id as value, will need to use key to store id and value to store building name
    const selectedBuildingId = options.key;
    const selectedBuilding = rawBuildingsData.find(building => building._id === selectedBuildingId);

    onSelectBuilding(selectedBuilding);
  };

  return (
    <FormAutoComplete
      form={form}
      name="name"
      label="Building Name"
      requiredErrorMessage="Please enter the building name"
      placeholder="e.g. Kelana Mall"
      size="large"
      defaultValue={defaultValue}
      selections={buildingSuggestions}
      onSearch={key => onKeywordChange(key, selectedBuildings, onInputChange)}
      onSelect={handleOnSelectionChange}
      {...props}
    />
  );
};

FormBuildingSuggestions.propTypes = {
  form: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  onSelectBuilding: PropTypes.func.isRequired,
  onInputChange: PropTypes.func
};

FormBuildingSuggestions.defaultProps = {
  defaultValue: undefined,
  onInputChange: () => {},
  selectedBuildings: []
};

export default FormBuildingSuggestions;
