import React from 'react';
import { Modal } from 'antd';

const CloseEnquiryModal = ({ onOk, onCancel, visible }) => {
  return (
    <Modal title="Are you sure want to continue?" visible={visible} destroyOnClose onOk={onOk} onCancel={onCancel}>
      Once you change the status to <span style={{ color: '#00b9c6' }}>Closed</span>, this enquiry can't be edited anymore.
    </Modal>
  );
};

export default CloseEnquiryModal;
