import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { PropTypes } from 'prop-types';
import { getConstants } from 'apis/constant';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import LocationAutoSearchGoogleMap from 'components/LocationAutoSearchGoogleMap/LocationAutoSearchGoogleMap';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import debounce from 'lodash.debounce';

class Location extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onCountryChange: PropTypes.func.isRequired,
    onCoordinateChange: PropTypes.func,
    data: PropTypes.object,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    isDisabled: false,
    isRequired: false,
    onCoordinateChange: () => {},
    onCountryChange: () => {}
  };

  state = {
    countrySelections: [],
    stateSelections: []
  };

  async componentDidMount() {
    return Promise.all([this.fetchCountry(), this.fetchStates()]);
  }

  fetchCountry = () => {
    return getConstants('countries').then(data => {
      const countries = Object.values(data).map(country => {
        return {
          value: country.iso2,
          label: country.name
        };
      });
      this.setState({
        countrySelections: countries
      });
    });
  };

  fetchStates = () => {
    return getConstants('statesMY').then(data => {
      const states = Object.values(data).map(state => {
        return {
          value: state.code,
          label: state.label
        };
      });
      this.setState({
        stateSelections: states
      });
    });
  };

  getStateValue = stateCode => {
    const { stateSelections } = this.state;
    return stateSelections.find(state => state.value === stateCode).value;
  };

  getCountryValue = countryCode => {
    const { countrySelections } = this.state;
    return countrySelections.find(country => country.value === countryCode).value;
  };

  handleOnSelectGoogleAddress = ({ address, city, zipCode }) => {
    const { form } = this.props;

    form.setFieldsValue({ propertyAddress: address });
    this.handleOnFormChange({ fieldName: 'propertyAddress', fieldValue: address })();

    if (!!city) {
      form.setFieldsValue({ propertyCity: city });
      this.handleOnFormChange({ fieldName: 'propertyCity', fieldValue: city })();
    }
    if (!!zipCode) {
      form.setFieldsValue({ propertyZipcode: zipCode });
      this.handleOnFormChange({ fieldName: 'propertyZipcode', fieldValue: zipCode })();
    }
  };

  handleOnFormChange = ({ fieldName, fieldValue, extraAction = () => {} }) => event => {
    const { form } = this.props;
    extraAction(event);

    fieldValue = fieldValue || this.extractFieldValue(event);
    const allFieldsValue = form.getFieldsValue();
    allFieldsValue[fieldName] = fieldValue;

    const hasAddressFilled =
      allFieldsValue.propertyAddress &&
      allFieldsValue.propertyCity &&
      allFieldsValue.propertyCountry &&
      allFieldsValue.propertyState &&
      allFieldsValue.propertyZipcode;
    // Update the field value because onChange has not update the form value yet
    if (hasAddressFilled) {
      this.debounceHandleOnMapSearch(allFieldsValue);
    }
  };

  extractFieldValue = event => {
    let fieldValue = event;
    if (typeof fieldValue === 'object' && fieldValue.target) {
      fieldValue = fieldValue.target.value;
    }
    return fieldValue;
  };

  handleOnMapSearch = addressInformation => {
    const { onCoordinateChange } = this.props;

    geocodeByAddress(
      `${addressInformation.propertyAddress}, ${addressInformation.propertyCity}, ${addressInformation.propertyZipcode} ${this.getStateValue(
        addressInformation.propertyState
      )}, ${this.getCountryValue(addressInformation.propertyCountry)}`
    )
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onCoordinateChange(latLng.lat, latLng.lng);
      })
      .catch(error => {});
  };

  debounceHandleOnMapSearch = debounce(this.handleOnMapSearch, 500);

  handleOnCountryChange = value => {
    const { form, onCountryChange } = this.props;
    form.setFieldsValue({ propertyState: undefined });
    onCountryChange(value);
  };

  render() {
    const { form, data, isDisabled, isRequired } = this.props;
    const { countrySelections, stateSelections } = this.state;

    return (
      <React.Fragment>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <LocationAutoSearchGoogleMap
              label="Address"
              name="propertyAddress"
              defaultValue={data.streetAddress}
              form={form}
              onSelectGoogleAddress={this.handleOnSelectGoogleAddress}
              onInputChange={this.handleOnFormChange({ fieldName: 'propertyAddress' })}
              isDisabled={isDisabled}
              requiredErrorMessage={isRequired ? 'Please enter an address' : undefined}
            />
          </Col>
        </Row>
        <Row gutter={12} type="flex" justify="space-between">
          <Col xl={12} xs={24}>
            <FormSelection
              label="Country"
              name="propertyCountry"
              placeholder="Select country"
              defaultValue={data.country && data.country}
              selections={countrySelections}
              form={form}
              onChange={this.handleOnFormChange({ fieldName: 'propertyCountry', extraAction: this.handleOnCountryChange })}
              isDisabled={isDisabled}
              requiredErrorMessage={isRequired ? 'Please select a country' : undefined}
            />
          </Col>
          <Col xl={12} xs={24}>
            <FormSelection
              label="State"
              name="propertyState"
              placeholder="Select state"
              defaultValue={data.state && data.state}
              selections={stateSelections}
              form={form}
              onChange={this.handleOnFormChange({ fieldName: 'propertyState' })}
              isDisabled={isDisabled}
              requiredErrorMessage={isRequired ? 'Please select a state' : undefined}
            />
          </Col>
        </Row>
        <Row gutter={12} type="flex" justify="space-between">
          <Col xl={12} xs={24}>
            <FormInput
              label="City"
              name="propertyCity"
              placeholder="e.g. Petaling Jaya"
              defaultValue={data.city}
              form={form}
              onChange={this.handleOnFormChange({ fieldName: 'propertyCity' })}
              isDisabled={isDisabled}
              requiredErrorMessage={isRequired ? 'Please enter a city' : undefined}
            />
          </Col>
          <Col xl={12} xs={24}>
            <FormInput
              label="ZIP Code"
              name="propertyZipcode"
              placeholder="e.g. 47301"
              defaultValue={data.zipcode}
              form={form}
              onChange={this.handleOnFormChange({ fieldName: 'propertyZipcode' })}
              isDisabled={isDisabled}
              extraRules={[
                {
                  max: 6,
                  message: 'ZIP Code has a maximum of 6 numbers'
                },
                {
                  min: 5,
                  message: 'ZIP Code has a minimum of 5 numbers'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: 'ZIP Code must be a number'
                }
              ]}
              requiredErrorMessage={isRequired ? 'Please enter a ZIP code' : undefined}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Location;
