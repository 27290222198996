import React from 'react';
import { Button, Row, Col, Icon, Input, Radio } from 'antd';
import styled from 'styled-components';

const { Group } = Radio;

const FilterRadioContainer = styled.div`
  padding: 8px;
`;

const FilterRadioButton = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`;

const FilterSearchContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;
const handleOnConfirm = confirm => () => {
  confirm();
};

const handleOnReset = clearFilters => () => {
  clearFilters();
};

const FilterRadio = ({ filterSelections, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <FilterRadioContainer>
    <Group
      onChange={e => {
        const value = e.target.value;
        setSelectedKeys(value ? [value] : []);
      }}
      value={selectedKeys[0]}
    >
      {filterSelections.map(selection => (
        <Row key={selection.label}>
          <FilterRadioButton value={selection.value}>{selection.label}</FilterRadioButton>
        </Row>
      ))}
    </Group>
    <Row gutter={8} type="flex" justify="space-between">
      <Col>
        <Button type="primary" onClick={handleOnConfirm(confirm)}>
          Search
        </Button>
      </Col>
      <Col>
        <Button onClick={handleOnReset(clearFilters)}>Reset</Button>
      </Col>
    </Row>
  </FilterRadioContainer>
);

const FilterSearch = ({ placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <FilterSearchContainer>
    <Input
      placeholder={placeholder}
      value={selectedKeys[0]}
      onChange={e => {
        const value = e.target.value;
        setSelectedKeys(value ? [value] : []);
      }}
      onPressEnter={handleOnConfirm(confirm)}
    />
    <Button style={{ margin: '0 8px' }} type="primary" onClick={handleOnConfirm(confirm)}>
      Search
    </Button>
    <Button onClick={handleOnReset(clearFilters)}>Reset</Button>
  </FilterSearchContainer>
);

export const getColumnFilterRadioProps = (dataIndex, filterSelections) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <FilterRadio
      filterSelections={filterSelections}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
  filterIcon: filtered => <Icon type="filter" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
  onFilter: (value, record) => {
    return String(record[dataIndex])
      .toLowerCase()
      .includes(value.toLowerCase());
  }
});

export const getColumnFilterSearchProps = (dataIndex, placeholder) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <FilterSearch
      placeholder={placeholder}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
  filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
  onFilter: (value, record) => {
    return String(record[dataIndex])
      .toLowerCase()
      .includes(value.toLowerCase());
  }
});

export const constructConstantsLabel = (constants, text) => {
  const foundConst = constants.find(type => type.value === text);
  return foundConst && foundConst.label;
};

export const constructArrayConstantsLabel = (constants, values) => {
  const labels = values.map(value => {
    const foundValue = constants.find(constant => String(constant.code) === String(value));
    return foundValue ? foundValue.label : value;
  });
  return labels.join(', ');
};
