/* ----------------------------General Constants---------------------------- */
/* ---------------The constants that generally use everywhere--------------- */
// General
export const DATE_FORMAT = 'YYYY-MM-DD';
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const NOT_UPPERCASE_REGEX = /^([^A-Z]*)$/;

// Form Input
export const INPUT_TYPE_INPUT = 'input';
export const INPUT_TYPE_TEXTAREA = 'textarea';

// Form Radio Button
export const RADIO_BUTTON_STYLE_OUTLINE = 'outline';
export const RADIO_BUTTON_STYLE_SOLID = 'solid';

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// Map related
export const DEFAULT_CONTACT_COUNTRY_CODE = '+60';
export const DEFAULT_LAT_LNG = { lat: 3.14567, lng: 101.697471 };

// Priority Color Code
export const PRIORITY_COLOR_HIGH = '#f5222d';
export const PRIORITY_COLOR_MEDIUM = '#faad14';
export const PRIORITY_COLOR_LOW = '#52c41a';

/* ----------------------------Specific Constants---------------------------- */
/* ---------------The constants that generally use in one page--------------- */
// Enquiry Form
export const DEFAULT_PRIORITY_TYPE = 'low';
export const DEFAULT_ENQUIRY_STATUS = 'new';
