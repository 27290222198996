import React, { useEffect } from 'react';
import DataSet from '@antv/data-set';
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts';
import PropTypes from 'prop-types';

const PieChart = ({ dimension, field, graphData, height, isCustomColor }) => {
  useEffect(() => {
    // Refer to this issue: https://github.com/alibaba/BizCharts/issues/279#issuecomment-400438180
    window.dispatchEvent(new Event('resize'));
  }, []);

  const { DataView } = DataSet;
  const dv = new DataView();
  dv.source(graphData).transform({
    type: 'percent',
    field,
    dimension,
    as: 'percent'
  });

  const colors = isCustomColor
    ? [
        'color',
        color => {
          return color;
        }
      ]
    : dimension;
  const legendExtraProps = isCustomColor
    ? {
        custom: true,
        items: graphData.map(a => {
          return {
            value: a.type,
            marker: {
              symbol: 'circle',
              fill: a.color,
              radius: 5
            }
          };
        })
      }
    : {};

  return (
    <Chart height={height} data={dv} forceFit>  
      <Coord type={'theta'} radius={0.75} innerRadius={0.6} />
      <Axis name="percent" />
      <Geom
        type="intervalStack"
        position="percent"
        color={colors}
        tooltip={[
          `${dimension}*${field}`,
          (name, count) => {
            return {
              name,
              value: `${count}`
            };
          }
        ]}
        style={{
          lineWidth: 1,
          stroke: '#fff'
        }}
      >
        <Legend
          clickable={false}
          position="bottom-center"
          textStyle={{
            fontSize: '14'
          }}
          {...legendExtraProps}
        />
        <Tooltip showTitle={false} itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>' />
        <Label
          content="percent"
          formatter={(val, item) => {
            return `${item.point[dimension]}: ${(Number(val) * 100).toFixed(2) + '%'}`;
          }}
        />
      </Geom>
    </Chart>
  );
};

PieChart.propTypes = {
  graphData: PropTypes.array.isRequired,
  dimension: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  height: PropTypes.number,
};

PieChart.defaultProps = {
  height: 400
}

export default PieChart;
