import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import { RADIO_BUTTON_STYLE_OUTLINE, RADIO_BUTTON_STYLE_SOLID } from 'utils/constants';

import FormItem from 'components/FormItem/FormItem';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const FormRadioButton = ({
  form,
  name,
  defaultValue,
  selections,
  className,
  buttonStyle,
  buttonsToDisabled,
  
  onChange,

  isDisabled,

  ...props
}) => (
  <FormItem form={form} name={name} defaultValue={defaultValue} {...props}>
    <RadioGroup className={className} buttonStyle={buttonStyle} onChange={onChange} disabled={isDisabled}>
      {selections.map(selection => (
        <RadioButton key={selection.value} value={selection.value} disabled={buttonsToDisabled.includes(selection.value)}>
          {selection.label || selection.value}
        </RadioButton>
      ))}
    </RadioGroup>
  </FormItem>
);

FormRadioButton.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  selections: PropTypes.array.isRequired,
  className: PropTypes.string,
  buttonStyle: PropTypes.oneOf([ RADIO_BUTTON_STYLE_OUTLINE, RADIO_BUTTON_STYLE_SOLID ]),
  buttonsToDisabled: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

FormRadioButton.defaultProps = {
  defaultValue: '',
  className: '',
  buttonStyle: RADIO_BUTTON_STYLE_SOLID,
  buttonsToDisabled: [],
  onChange: () => {},
  isDisabled: false
};

export default FormRadioButton;
