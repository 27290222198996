import api from './apiHelper';
import { constructFieldsToString } from './helpers';

export const getHosts = (query = {}, fields) => api.get('/host', { params: { ...query, ...(!!fields && constructFieldsToString(fields)) } });

export const getHostsWithAggregation = (query = {}) => api.get('/host/aggregation', { params: query });

export const getHostById = hostId => api.get(`/host/${hostId}`);

export const postSubmitHost = hostBody => api.post('/host/', hostBody);

export const putUpdateHost = (hostId, hostBody) => api.put(`/host/${hostId}`, hostBody);

export const deleteHost = hostId => api.delete(`/host/${hostId}`);
