import React from 'react';
import { Form, Modal, notification } from 'antd';
import { withRouter } from 'react-router-dom';

import { putUpdateHost } from 'apis/host';
import { useFetchUsersForSelection } from 'utils/hooks';

import FormSelection from 'components/FormSelection/FormSelection';

const AssignHostPICModal = ({ form, showModal, selectedHost, closeModal, onAssignPICSuccess }) => {
  const { users: listOfPIC } = useFetchUsersForSelection();

  const handleOnAssignPICButtonClicked = e => {
    e.preventDefault();

    const id = selectedHost._id
    const originalPIC = selectedHost.assignedTo;

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const successNotification = () =>
          notification.success({
            duration: 3,
            message: 'Success',
            description: "You've successfully assigned the PIC to this host."
          });

        if (values.pic !== originalPIC) {
          putUpdateHost(id, { assignedTo: values.pic }).then(() => {
            successNotification();
            closeModal();
            onAssignPICSuccess();
          });
        } else {
          successNotification();
          closeModal();
        }
      }
    });
  };

  return (
    <Modal
      title={`Assign PIC for ${selectedHost.name}`}
      visible={showModal}
      onOk={handleOnAssignPICButtonClicked}
      onCancel={closeModal}
      okText="Assign"
      destroyOnClose
    >
      <Form>
        <p>
          <b>Name: </b> {selectedHost.name}
        </p>
        <p>
          <b>Contact Number: </b> {selectedHost.contactNumber}
        </p>
        <p>
          <b>Email: </b> {selectedHost.email}
        </p>
        <FormSelection
          form={form}
          name="pic"
          defaultValue={selectedHost.assignedTo}
          selections={listOfPIC}
          placeholder="Select a PIC"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          requiredErrorMessage="Please select the person in charge"
        />
      </Form>
    </Modal>
  );
};

export default withRouter(Form.create()(AssignHostPICModal));
