import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, message, Row } from 'antd';
import moment from 'moment';

import { getParentEnquiries } from 'apis/enquiry';

import Card from 'components/Card/Card';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

import { INPUT_TYPE_TEXTAREA, DEFAULT_CONTACT_COUNTRY_CODE, DEFAULT_PRIORITY_TYPE } from 'utils/constants';

const useFetchParentEnquiries = enquiryId => {
  const [parentEnquiries, setParentEnquiries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const parentEnquiries = await getParentEnquiries(enquiryId)
        .then(data => {
          return data.map(parentEnquiry => {
            return { value: parentEnquiry._id, label: parentEnquiry.ticketNumber };
          });
        })
        .catch(e => {
          console.error(e);
          message.error(e.message);
          return [];
        });

      setParentEnquiries(parentEnquiries);
    };

    fetchData();
  }, [enquiryId]);

  return parentEnquiries;
};

const EnquiryInfo = ({ canEdit, form, enquiry, enquirySources, enquiryTypes, listOfPIC, priorityTypes, shouldShowPICSelection }) => {
  const todayDate = useMemo(() => moment(), []);
  const defaultFollowUpDate = useMemo(() => moment(enquiry.followUpDate || moment().add(3, 'day')), [enquiry.followUpDate]);
  const defaultPriority = useMemo(() => enquiry.priority || DEFAULT_PRIORITY_TYPE, [enquiry.priority]);
  const parentEnquiries = useFetchParentEnquiries(enquiry._id);

  const isEdit = useMemo(() => !!enquiry.ticketNumber, [enquiry.ticketNumber]);

  return (
    <Card title="General Details">
      {isEdit && (
        <Row>
          <FormInput form={form} name="ticketNumber" label="Ticket Number" isDisabled defaultValue={enquiry.ticketNumber} />
        </Row>
      )}
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormSelection
            form={form}
            name="parentTicketNumber"
            label="Parent Ticket Number"
            selections={parentEnquiries}
            defaultValue={enquiry.parentTicket}
            isDisabled={isEdit}
            placeholder={isEdit ? 'There is no parent ticket' : 'Select a parent ticket'}
          />
        </Col>
        <Col span={12}>
          <FormSelection
            form={form}
            name="source"
            label="Source"
            selections={enquirySources}
            defaultValue={enquiry.source}
            isDisabled={!canEdit}
            placeholder="Select an enquiry source"
            requiredErrorMessage="Please select an enquiry source"
          />
        </Col>
      </Row>
      <Row>
        <FormCheckbox
          form={form}
          name="enquiryType"
          label="Enquiry Type"
          selections={enquiryTypes}
          defaultValue={enquiry.types}
          isDisabled={!canEdit}
          requiredErrorMessage="Please select at least 1 enquiry type"
        />
      </Row>
      <Row>
        <FormInput
          inputType={INPUT_TYPE_TEXTAREA}
          form={form}
          name="customerRemarks"
          label="Customer Remarks"
          defaultValue={enquiry.remarks}
          isDisabled={!canEdit}
          placeholder="Enter remarks regarding the customer"
        />
      </Row>
      <Row type="flex" gutter={64} align="middle">
        <Col span={8}>
          <FormDatePicker
            form={form}
            name="followUpDate"
            label="Follow Up Date"
            defaultValue={defaultFollowUpDate}
            isDisabled={!canEdit}
            requiredErrorMessage="Please select a follow up date"
            disabledDate={currentDate => currentDate < todayDate}
          />
        </Col>
        <Col span={8}>
          <FormRadioButton
            form={form}
            name="priority"
            label="Priority"
            selections={priorityTypes}
            defaultValue={defaultPriority}
            isDisabled={!canEdit}
            requiredErrorMessage="Please select a priority"
          />
        </Col>
        {shouldShowPICSelection && (
          <Col span={8}>
            <FormSelection
              form={form}
              name="assignedTo"
              label="PIC"
              selections={listOfPIC}
              defaultValue={enquiry.assignedTo}
              isDisabled={!canEdit}
              placeholder="Select a PIC"
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

const CustomerInfo = ({ canEdit, form, enquiry, enquirerIdentities, states, countries, countryCodes }) => {
  const defaultContactCountryCode = useMemo(() => enquiry.enquirer.contact.countryCode || DEFAULT_CONTACT_COUNTRY_CODE, [
    enquiry.enquirer.contact.countryCode
  ]);

  return (
    <Card title="Enquirer Details">
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormInput
            form={form}
            name="firstName"
            label="First Name"
            placeholder="e.g. John/Jane"
            defaultValue={enquiry.enquirer.firstName}
            isDisabled={!canEdit}
            requiredErrorMessage="Please enter the customer's first name"
          />
        </Col>
        <Col span={12}>
          <FormInput
            form={form}
            name="lastName"
            label="Last Name"
            defaultValue={enquiry.enquirer.lastName}
            isDisabled={!canEdit}
            placeholder="e.g. Doe"
            requiredErrorMessage="Please enter the customer's last name"
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormInput
            form={form}
            name="email"
            label="Email"
            defaultValue={enquiry.enquirer.email}
            isDisabled={!canEdit}
            placeholder="e.g. enquiry@hostastay.com"
            isEmail
          />
        </Col>
        <Col span={12}>
          <FormLabel hasRequiredErrorMessage>Contact Number</FormLabel>
          <Row type="flex">
            <Col span={4}>
              <FormSelection
                form={form}
                name="contactCountryCode"
                selections={countryCodes}
                defaultValue={defaultContactCountryCode}
                isDisabled={!canEdit}
                requiredErrorMessage="Please select a country code"
              />
            </Col>
            <Col span={20}>
              <FormInput
                form={form}
                name="contactNumber"
                defaultValue={enquiry.enquirer.contact.contactNumber}
                isDisabled={!canEdit}
                placeholder="e.g. 3 7886 7829"
                requiredErrorMessage="Please enter the customer's contact number"
                isContactNumber
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormInput
            form={form}
            name="companyName"
            label="Company Name"
            defaultValue={enquiry.enquirer.companyName}
            isDisabled={!canEdit}
            placeholder="e.g. HostAStay"
          />
        </Col>
        <Col span={12}>
          <FormSelection
            form={form}
            name="identity"
            label="Identity"
            selections={enquirerIdentities}
            defaultValue={enquiry.enquirer.identity}
            isDisabled={!canEdit}
            placeholder="Select the customer's identity"
            requiredErrorMessage="Please select the customer's identity"
          />
        </Col>
      </Row>
      <Row>
        <FormInput
          form={form}
          name="streetAddress"
          label="Company Address / Housing Address"
          defaultValue={enquiry.enquirer.streetAddress}
          isDisabled={!canEdit}
          placeholder="e.g. 50A-2, Kelana Mall, Jalan SS6/14, Kelana Jaya"
        />
      </Row>
      <Row gutter={12} type="flex" justify="space-between">
        <Col xl={12} xs={24}>
          <FormSelection
            label="Country"
            name="country"
            placeholder="Select the customer's country"
            defaultValue={enquiry.enquirer.country}
            isDisabled={!canEdit}
            selections={countries}
            form={form}
          />
        </Col>
        <Col xl={12} xs={24}>
          <FormSelection
            label="State"
            name="state"
            placeholder="Select the customer's state"
            defaultValue={enquiry.enquirer.state}
            isDisabled={!canEdit}
            selections={states}
            form={form}
          />
        </Col>
      </Row>
      <Row gutter={12} type="flex" justify="space-between">
        <Col xl={12} xs={24}>
          <FormInput
            label="City"
            name="city"
            placeholder="e.g. Petaling Jaya"
            defaultValue={enquiry.enquirer.city}
            isDisabled={!canEdit}
            form={form}
          />
        </Col>
        <Col xl={12} xs={24}>
          <FormInput
            label="ZIP Code"
            name="zipcode"
            placeholder="e.g. 47301"
            defaultValue={enquiry.enquirer.zipcode}
            isDisabled={!canEdit}
            form={form}
            extraRules={[
              {
                max: 6,
                message: 'ZIP Code has a maximum of 6 numbers'
              },
              {
                min: 5,
                message: 'ZIP Code has a minimum of 5 numbers'
              },
              {
                pattern: /^[0-9]*$/,
                message: 'ZIP Code must be a number'
              }
            ]}
          />
        </Col>
      </Row>
    </Card>
  );
};

const GeneralInfo = ({
  canEdit,
  form,
  states,
  countries,
  enquiry,
  enquirySources,
  enquiryTypes,
  enquirerIdentities,
  priorityTypes,
  listOfPIC,
  countryCodes,
  shouldShowPICSelection = false
}) => {
  return (
    <>
      <Row>
        <EnquiryInfo
          canEdit={canEdit}
          form={form}
          enquiry={enquiry}
          enquirySources={enquirySources}
          enquiryTypes={enquiryTypes}
          listOfPIC={listOfPIC}
          priorityTypes={priorityTypes}
          shouldShowPICSelection={shouldShowPICSelection}
        />
      </Row>
      <Row>
        <CustomerInfo
          canEdit={canEdit}
          form={form}
          enquirerIdentities={enquirerIdentities}
          states={states}
          countries={countries}
          enquiry={enquiry}
          countryCodes={countryCodes}
        />
      </Row>
    </>
  );
};

GeneralInfo.propTypes = {
  canEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  enquiry: PropTypes.object.isRequired,
  enquirySources: PropTypes.array.isRequired,
  enquiryTypes: PropTypes.array.isRequired,
  enquirerIdentities: PropTypes.array.isRequired,
  priorityTypes: PropTypes.array.isRequired,
  listOfPIC: PropTypes.array.isRequired,
  countryCodes: PropTypes.array.isRequired,
  shouldShowPICSelection: PropTypes.bool
};

GeneralInfo.defaultProps = {
  canEdit: true,
  shouldShowPICSelection: false
};

export default GeneralInfo;
