import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Empty, Row, Col } from 'antd';

import Card from 'components/Card/Card';
import PropertyCard from 'components/PropertyCard/PropertyCard';
import PropertyAdd from 'components/PropertyAdd/PropertyAdd';

import { constructFullAddress } from 'utils/general';
import { useEditItem } from 'utils/hooks';

import BuildingModal from './components/BuildingModal/BuildingModal';

const BuildingInfo = ({
  canEdit,
  buildings,
  countries,
  states,
  onBuildingsChange,
  buildingTypes,
  buildingTenures,
  buildingTitles,
  furnishingStatuses
}) => {
  const {
    selectedItem: selectedBuilding,
    selectedIndex,
    isEditing,
    updateEditItem: updateEditBuilding,
    resetEditItem: resetEditBuilding
  } = useEditItem();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const selectedBuildings = buildings.filter(building => building._id).map(building => building._id);

  const handleOnBuildingSave = useCallback(
    newBuilding => {
      if (isEditing) {
        buildings[selectedIndex] = newBuilding;
        onBuildingsChange(buildings);
      } else {
        onBuildingsChange([...buildings, newBuilding]);
      }
      setIsModalVisible(false);
    },
    [buildings, onBuildingsChange, selectedIndex, isEditing]
  );
  const handleOnAddButtonClick = useCallback(
    e => {
      e.preventDefault();
      resetEditBuilding();
      setIsModalVisible(!isModalVisible);
    },
    [isModalVisible, resetEditBuilding]
  );
  const handleOnBuildingDelete = useCallback(
    index => e => {
      e.preventDefault();

      buildings.splice(index, 1);
      onBuildingsChange([...buildings]);
    },
    [buildings, onBuildingsChange]
  );
  const handleOnBuildingEdit = useCallback(
    index => e => {
      e.preventDefault();
      setIsModalVisible(true);
      updateEditBuilding(index, buildings[index]);
    },
    [buildings, updateEditBuilding]
  );
  const handleOnBuildingModalClose = useCallback(e => {
    e.preventDefault();
    setIsModalVisible(false);
  }, []);

  return (
    <Card title="Building Details">
      <Row type="flex" gutter={16}>
        {buildings.length > 0 &&
          buildings.map((building, index) => (
            <Col key={index} md={8}>
              <PropertyCard
                canEdit={canEdit}
                propertyName={building.name}
                propertyAddress={constructFullAddress(building, countries, states)}
                onDeleteClick={handleOnBuildingDelete(index)}
                onEditClick={handleOnBuildingEdit(index)}
              />
            </Col>
          ))}
        {canEdit && (
          <Col md={8}>
            <PropertyAdd onClick={handleOnAddButtonClick} />
          </Col>
        )}
        {!canEdit && !buildings.length > 0 && (
          <Col style={{ margin: '0 auto' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No buildings added to this enquiry</span>} />
          </Col>
        )}
        {isModalVisible && (
          <BuildingModal
            defaultValue={selectedBuilding}
            buildingTypes={buildingTypes}
            buildingTenures={buildingTenures}
            buildingTitles={buildingTitles}
            furnishingStatuses={furnishingStatuses}
            canEdit={canEdit}
            onSave={handleOnBuildingSave}
            onCancel={handleOnBuildingModalClose}
            visible={isModalVisible}
            selectedBuildings={selectedBuildings}
          />
        )}
      </Row>
    </Card>
  );
};

BuildingInfo.propTypes = {
  canEdit: PropTypes.bool,
  buildings: PropTypes.array,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  onBuildingsChange: PropTypes.func.isRequired,
  buildingTypes: PropTypes.array.isRequired,
  buildingTenures: PropTypes.array.isRequired,
  buildingTitles: PropTypes.array.isRequired,
  furnishingStatuses: PropTypes.array.isRequired
};

BuildingInfo.defaultProps = {
  canEdit: true,
  buildings: []
};

export default BuildingInfo;
