import React, { useEffect, useState, useMemo } from 'react';
import { Button, Table, Skeleton } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getBuildingsWithAggregation } from 'apis/building';

import SourceIcon from 'components/SourceIcon/SourceIcon';
import Card from 'components/Card/Card';

import { useFetchConstant } from 'utils/hooks';
import { constructConstantsLabel, getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';
import { getNewBuildingRoute, getBuildingRoute } from 'utils/routes';

const newBuildingRoute = getNewBuildingRoute();

const BuildingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const constructFilterQuery = filters => {
  const keys = Object.keys(filters);
  const filterQuery = {};
  keys.forEach(key => (filterQuery[key] = filters[key][0]));
  return filterQuery;
};

const handleOnTableChange = async ({ pagination, filters, sorter }, fetchBuildings) => {
  const filter = constructFilterQuery(filters);
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(filters && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && columnKey && { sort: encodeURIComponent(JSON.stringify({ [columnKey]: order === 'descend' ? -1 : 1 })) }),
    ...(pagination && current && { pagination: encodeURIComponent(JSON.stringify({ limit: pageSize, currentPage: current })) })
  };
  fetchBuildings(query);
};

const useLoadBuildings = () => {
  const [currentQuery, setCurrentQuery] = useState({});
  const [buildings, setBuildings] = useState([]);
  const [totalOfBuildings, setTotalOfBuildings] = useState(0);

  const fetchBuildings = async newQuery => {
    if (newQuery) {
      setCurrentQuery(newQuery);
    }
    const queryToFetch = newQuery || currentQuery;
    const buildingsDetails = await getBuildingsWithAggregation(queryToFetch);
    setBuildings(buildingsDetails.buildings);
    setTotalOfBuildings(buildingsDetails.totalCount);
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    fetchBuildings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { buildings, setBuildings, totalOfBuildings, setTotalOfBuildings, currentQuery, setCurrentQuery, fetchBuildings };
};

const getColumns = (buildingTypes, buildingRegulationStatuses, buildingStatuses) => [
  {
    title: 'Building Name',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    ...getColumnFilterSearchProps('name', 'Search building by name'),
    render: (text, record) => {
      return <Link to={getBuildingRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    title: 'Building Type',
    dataIndex: 'type',
    key: 'type',
    ...getColumnFilterRadioProps('type', buildingTypes),
    render: text => (text ? <span>{constructConstantsLabel(buildingTypes, text)}</span> : <span>-</span>)
  },
  {
    title: 'Total No. of Units',
    dataIndex: 'totalNoOfUnits',
    key: 'totalNoOfUnits',
    sorter: true,
    render: text => (text ? <span>{text}</span> : <span>-</span>)
  },
  {
    title: 'Building Regulation Status',
    dataIndex: 'regulationStatus',
    key: 'regulationStatus',
    ...getColumnFilterRadioProps('regulationStatus', buildingRegulationStatuses),
    render: text => (text ? <span>{constructConstantsLabel(buildingRegulationStatuses, text)}</span> : <span>-</span>)
  },
  {
    title: 'Building Status',
    dataIndex: 'status',
    key: 'status',
    ...getColumnFilterRadioProps('status', buildingStatuses),
    render: text => (text ? <span>{constructConstantsLabel(buildingStatuses, text)}</span> : <span>-</span>)
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    sorter: true,
    render: text => <SourceIcon icon={text} />
  }
];

const Building = () => {
  const { buildings, totalOfBuildings, fetchBuildings } = useLoadBuildings();
  const { selections: buildingRegulationStatuses, isLoading: isBuildingRegulationStatusesLoading } = useFetchConstant(
    'buildingRegulationStatuses',
    'Error while getting building regulation statuses.'
  );
  const { selections: buildingStatuses, isLoading: isBuildingStatusesLoading } = useFetchConstant(
    'buildingStatuses',
    'Error while getting building statuses.'
  );
  const { selections: buildingTypes, isLoading: isBuildingTypesLoading } = useFetchConstant('buildingTypes', 'Error while getting building types.');

  const isLoading = useMemo(() => isBuildingRegulationStatusesLoading || isBuildingStatusesLoading || isBuildingTypesLoading, [
    isBuildingRegulationStatusesLoading,
    isBuildingStatusesLoading,
    isBuildingTypesLoading
  ]);

  return (
    <Card>
      <Skeleton active loading={isLoading}>
        <BuildingHeader>
          <Link to={newBuildingRoute.path}>
            <Button type="primary" icon="plus">
              Create Building
            </Button>
          </Link>
          {/* <Button type="primary" icon="download">
            Download CSV
          </Button> */}
        </BuildingHeader>
        <Table
          rowKey={record => record._id}
          dataSource={buildings}
          scroll={{ x: 1000 }}
          columns={getColumns(buildingTypes, buildingRegulationStatuses, buildingStatuses)}
          onChange={(pagination, filters, sorter) => handleOnTableChange({ pagination, filters, sorter }, fetchBuildings)}
          pagination={{ total: totalOfBuildings }}
        />
      </Skeleton>
    </Card>
  );
};

export default withRouter(Building);
