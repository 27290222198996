import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getUsersWithAggregation } from 'apis/user';

import Card from 'components/Card/Card';
import { getDataAndExportCSV } from 'components/DownloadCSVModal/DownloadCSVModal';

import { constructConstantsLabel, getColumnFilterRadioProps, getColumnFilterSearchProps } from 'utils/table';
import { getUserRoute, getNewUserRoute } from 'utils/routes';
import { useFetchConstant } from 'utils/hooks';

const newUserRoute = getNewUserRoute();

const UserHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const useLoadUsers = () => {
  const [currentQuery, setCurrentQuery] = useState({});
  const [users, setUsers] = useState([]);
  const [totalOfUsers, setTotalOfUsers] = useState(0);

  const fetchUsers = async newQuery => {
    if (newQuery) {
      setCurrentQuery(newQuery);
    }
    const queryToFetch = newQuery || currentQuery;
    const usersDetails = await getUsersWithAggregation(queryToFetch);
    setUsers(usersDetails.users);
    setTotalOfUsers(usersDetails.totalCount);
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    fetchUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { users, setUsers, totalOfUsers, setTotalOfUsers, currentQuery, setCurrentQuery, fetchUsers };
};

const constructFilterQuery = filters => {
  const keys = Object.keys(filters);
  const filterQuery = {};
  keys.forEach(key => (filterQuery[key] = filters[key][0]));
  return filterQuery;
};

const getColumns = roles => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...getColumnFilterSearchProps('name', 'Search name'),
    render: (text, record) => {
      return <Link to={getUserRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ...getColumnFilterSearchProps('username', 'Search username'),
    render: text => (text ? <span>{text}</span> : <span>-</span>)
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '20%',
    ...getColumnFilterSearchProps('email', 'Search email'),
    render: text => (text ? <span>{text}</span> : <span>-</span>)
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: '15%',
    sorter: true,
    ...getColumnFilterRadioProps('role', roles),
    render: text => (text ? <span style={{ textTransform: 'capitalize' }}>{constructConstantsLabel(roles, text)}</span> : <span>-</span>)
  }
];

const handleOnTableChange = async ({ pagination, filters, sorter }, fetchUsers) => {
  const filter = constructFilterQuery(filters);
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(filters && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && columnKey && { sort: encodeURIComponent(JSON.stringify({ [columnKey]: order === 'descend' ? -1 : 1 })) }),
    ...(pagination && current && { pagination: encodeURIComponent(JSON.stringify({ limit: pageSize, currentPage: current })) })
  };
  fetchUsers(query);
};

const User = () => {
  const { users, totalOfUsers, fetchUsers } = useLoadUsers();
  const { selections: roles } = useFetchConstant('roles');
  const [isDownloadCSVButtonLoading, setIsDownloadCSVButtonLoading] = useState(false);

  const handleOnDownloadCSVClick = () => {
    const filename = 'All-User.csv';
    getDataAndExportCSV({ setIsLoading: setIsDownloadCSVButtonLoading, type: 'User', filename });
  };

  return (
    <Card>
      <UserHeader>
        <Link to={newUserRoute.path}>
          <Button type="primary" icon="plus">
            Create User
          </Button>
        </Link>
        <Button loading={isDownloadCSVButtonLoading} type="primary" icon="download" onClick={handleOnDownloadCSVClick}>
          {isDownloadCSVButtonLoading ? 'Downloading CSV...' : 'Download CSV'}
        </Button>
      </UserHeader>
      <Table
        onChange={(pagination, filters, sorter) => handleOnTableChange({ pagination, filters, sorter }, fetchUsers)}
        rowKey={record => record._id}
        dataSource={users}
        scroll={{ x: 1000 }}
        pagination={{ total: totalOfUsers }}
        columns={getColumns(roles)}
      />
    </Card>
  );
};

export default withRouter(User);
