import api from './apiHelper';

export const getCampaignHostsWithAggregation = (query = {}) => api.get('/campaignHost/aggregation', { params: query });

export const getCampaignHostById = campaignHostId => api.get(`/campaignHost/${campaignHostId}`);

export const postSubmitCampaignHost = campaignHostBody => api.post('/campaignHost/', campaignHostBody);

export const putUpdateCampaignHost = (campaignHostId, campaignHostBody) => api.put(`/campaignHost/${campaignHostId}`, campaignHostBody);

export const deleteCampaignHost = campaignHostId => api.delete(`/campaignHost/${campaignHostId}`);
