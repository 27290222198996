import React from 'react';
import { Icon, Layout, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { withAppContext } from 'contexts/AppContext/AppContext';

const { Header: LayoutHeader } = Layout;
// const NO_IMAGE_PLACEHOLDER = 'https://dashboard-operator-image.s3.amazonaws.com/e1b4d0a0-3114-4122-85ff-ef0f54022485_notfound.png';

const StyledLayoutHeader = styled(LayoutHeader)`
  padding: 0 20px;
  background-color: ${props => props.theme.color.primary};
  background-position: right;
`;

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SideBarCollapseIcon = styled(Icon)`
  color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 64px;
`;

const StyledProfileLink = styled(Link)`
  color: #ffffff;
  padding: 0 8px;
  :hover {
    color: #ffffff;
  }
`;

/* NOTE: Temporary commented until user can add photo */
// const StyledAvatar = styled.img`
//   margin-right: 10px;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   object-fit: cover;
//   filter: brightness(90%);

//   :hover {
//     filter: brightness(100%);
//   }
// `;

const Header = ({ onCollapse, user }) => {
  return (
    <StyledLayoutHeader>
      <StyledHeaderContent>
        <SideBarCollapseIcon onClick={onCollapse} type="bars" />
        <StyledProfileLink to={`/userProfile/${user._id}`}>
          <Tooltip title="User Profile">
            {/* NOTE: Temporary commented until user can add photo */}
            {/* <StyledAvatar src={NO_IMAGE_PLACEHOLDER} id="image" title="User profile" alt="User Icon" /> */}
            Hi, {user.firstName}!
          </Tooltip>
        </StyledProfileLink>
      </StyledHeaderContent>
    </StyledLayoutHeader>
  );
};

export default withAppContext(Header);
