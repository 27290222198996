import React from 'react';
import { message, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import {
  getLoginRoute,
  getForbiddenRoute,
} from 'utils/routes';

const loginRoute = getLoginRoute();
const forbidden = getForbiddenRoute();

const ProtectedRoute = ({ accessRole, checkCanUserAccess, isLoggedIn, isUserLoading, component, path, ...otherRouteProps }) => {
  if (isUserLoading) {
    return <Skeleton loading={isUserLoading} />;
  } else if (accessRole && !isLoggedIn) {
    message.error('Please login before accessing this page');
    return <Redirect to={loginRoute.path} />;
  } else if (accessRole && !checkCanUserAccess(accessRole)) {
    message.error('You are not allowed to access this page');
    return <Redirect to={forbidden.path} />;
  } else {
    return <Route path="path" component={component} {...otherRouteProps} />;
  }
};

ProtectedRoute.propTypes = {
  accessRole: PropTypes.string,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  checkCanUserAccess: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isUserLoading: PropTypes.bool.isRequired
};

export default withAppContext(ProtectedRoute);
