import React from 'react';
import styled from 'styled-components/macro';

import { Icon, Button } from 'antd';

const StyledButton = styled(Button)`
  height: 250px !important;
  width: 100%;
`;

const PropertyAdd = ({ className, onClick, ...props }) => {
  return (
    <StyledButton className={className} onClick={onClick}>
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Add property</div>
      </div>
    </StyledButton>
  );
};

export default PropertyAdd;