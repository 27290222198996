import api from './apiHelper';

export const getEnquiryReport = params => api.get(`report/enquiry`, { params });

export const getSourceOfEnquiryReport = params => api.get(`report/source`, { params });

export const getDealUnitsReport = params => api.get(`report/dealUnit`, { params });

export const getHostStatistics = params => api.get(`report/host`, { params });

export const getBuildingStatistics = params => api.get(`report/building`, { params });

export const getUnitStatistics = params => api.get(`report/unit`, { params });
