import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import FormItem from 'components/FormItem/FormItem';

import styled from 'styled-components/macro';

const { Option } = Select;

/* styled css */
const StyledFormItem = styled(FormItem)`
  width: 100%;
  font-size: 14px;
`;
/* styled css */

const FormSelection = ({
  form,
  name,
  defaultValue,
  selections,
  placeholder,
  className,

  onChange,
  onSearch,
  filterOption,

  hasAll,
  hasMultipleMode,
  isAllowClear,
  isDisabled,

  requiredErrorMessage,
  ...props
}) => {
  const allowClear = isAllowClear && !requiredErrorMessage;
  const searchModeProps =
    onSearch || filterOption
      ? {
          showSearch: true,
          defaultActiveFirstOption: false,
          onSearch,
          filterOption
        }
      : {};

  return (
    <StyledFormItem form={form} name={name} defaultValue={defaultValue} requiredErrorMessage={requiredErrorMessage} {...props}>
      <Select
        className={className}
        mode={hasMultipleMode ? 'multiple' : 'default'}
        placeholder={placeholder}
        optionFilterProp="children"
        {...searchModeProps}
        onChange={onChange}
        allowClear={allowClear}
        disabled={isDisabled}
      >
        {hasAll && (
          <Option key="all" value="all">
            All
          </Option>
        )}
        {selections.map(selection => (
          <Option key={selection.value} value={selection.value}>
            {selection.label || selection.value}
          </Option>
        ))}
      </Select>
    </StyledFormItem>
  );
};

FormSelection.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  hasAll: PropTypes.bool,
  hasMultipleMode: PropTypes.bool,
  isAllowClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  requiredErrorMessage: PropTypes.string
};

FormSelection.defaultProps = {
  defaultValue: undefined,
  placeholder: '',
  className: '',
  onChange: () => {},
  onSearch: () => {},
  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  hasAll: false,
  hasMultipleMode: false,
  isAllowClear: true,
  isDisabled: false,
  requiredErrorMessage: ''
};

export default FormSelection;

export const BareSelect = ({ style, options, defaultValue, onChange, isDisabled }) => {
  return (
    <Select
      style={style}
      defaultValue={defaultValue}
      showSearch
      filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      disabled={isDisabled}
      onChange={onChange}
    >
      {options.map(option => (
        <Option value={option.value} label={option.label} key={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

BareSelect.propTypes = {
  label: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

BareSelect.defaultProps = {
  value: undefined,
  placeholder: '',
  onChange: undefined,
  isDisabled: false
};
