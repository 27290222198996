import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'antd';
import { Link } from 'react-router-dom';

import { DEFAULT_CONTACT_COUNTRY_CODE } from 'utils/constants';
import { checkIsObject } from 'utils/general';
import { getColumnFilterSearchProps } from 'utils/table';
import { getEnquiryRoute } from 'utils/routes';

import Card from 'components/Card/Card';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import LocationWithGoogleMap from 'components/LocationWithGoogleMap/LocationWithGoogleMap';

const BasicDetails = ({ form, host, countryCodes, hostTypes, partnershipStatuses, hostStatuses, listOfPIC, canEdit, isAdmin }) => {
  const defaultContactCountryCode = useMemo(() => (!!host.contact && host.contact.countryCode) || DEFAULT_CONTACT_COUNTRY_CODE, [host.contact]);

  return (
    <Card title="General Details">
      <Row>
        <FormInput
          form={form}
          name="name"
          label="Host Name"
          defaultValue={host.name}
          placeholder="e.g. Janson Chah"
          requiredErrorMessage="Please enter the host's name"
          isDisabled={!canEdit}
        />
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={12}>
          <FormInput
            form={form}
            name="email"
            label="Email"
            defaultValue={host.email}
            placeholder="e.g. host@hostastay.com"
            isEmail
            isDisabled={!canEdit}
          />
        </Col>
        <Col span={12}>
          <FormLabel>Contact Number</FormLabel>
          <Row type="flex">
            <Col span={4}>
              <FormSelection
                form={form}
                name="contactCountryCode"
                selections={countryCodes}
                defaultValue={defaultContactCountryCode}
                isDisabled={!canEdit}
              />
            </Col>
            <Col span={20}>
              <FormInput
                form={form}
                name="contactNumber"
                defaultValue={!!host.contact ? host.contact.contactNumber : undefined}
                placeholder="e.g. 3 7886 7829"
                isContactNumber
                isDisabled={!canEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="hostType"
            label="Host Type"
            selections={hostTypes}
            defaultValue={host.type}
            placeholder="Select a host type"
            isDisabled={!canEdit}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="partnershipStatus"
            label="Partnership Status"
            selections={partnershipStatuses}
            defaultValue={host.partnershipStatus}
            placeholder="Select a host partnership status"
            isDisabled={!canEdit}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="hostStatus"
            label="Host Status"
            selections={hostStatuses}
            defaultValue={host.status}
            placeholder="Select a host status"
          />
        </Col>
        {isAdmin && (
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="assignedTo"
              label="PIC"
              selections={listOfPIC}
              defaultValue={checkIsObject(host.assignedTo) ? host.assignedTo._id : host.assignedTo}
              placeholder="Select a PIC"
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

const EnquiriesListing = ({ host, isAdmin, checkIsUserPIC }) => {
  const getEnquiriesListingColumns = () => [
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      width: '15%',
      ...getColumnFilterSearchProps('ticketNumber', 'Search ticket number'),
      render: (text, record) => {
        return isAdmin || checkIsUserPIC(record.assignedTo) ? <Link to={getEnquiryRoute(record._id).path}>{text}</Link> : text;
      }
    },
    {
      title: 'Building Name',
      dataIndex: 'building.name',
      key: 'building.name',
      ...getColumnFilterSearchProps('buildingName', 'Search building'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Host On Hand Unit(s)',
      dataIndex: 'building.totalNoOfUnits',
      key: 'building.totalNoOfUnits',
      sorter: (prev, next) => (prev.building ? prev.building.totalNoOfUnits : 0) - (next.building ? next.building.totalNoOfUnits : 0),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    },
    {
      title: 'Enquiry PIC',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      ...getColumnFilterSearchProps('assignedTo', 'Search Enquiry PIC'),
      render: text => (text ? <span>{text}</span> : <span>-</span>)
    }
  ];

  return (
    <Card title="Enquiries">
      <Table
        rowKey={record => `${record._id} ${record.building ? record.building.name : ''}`}
        dataSource={host.enquiries}
        scroll={{ x: 1000 }}
        columns={getEnquiriesListingColumns()}
      />
    </Card>
  );
};

const GeneralInfo = ({
  form,
  host,
  latitude,
  longitude,
  setCoordinates,
  countryCodes,
  hostTypes,
  partnershipStatuses,
  hostStatuses,
  listOfPIC,
  canEdit,
  isAdmin,
  checkIsUserPIC
}) => {
  return (
    <>
      <Row>
        <BasicDetails
          form={form}
          host={host}
          countryCodes={countryCodes}
          hostTypes={hostTypes}
          partnershipStatuses={partnershipStatuses}
          hostStatuses={hostStatuses}
          listOfPIC={listOfPIC}
          canEdit={canEdit}
          isAdmin={isAdmin}
        />
      </Row>
      <Row>
        <Card title="Address">
          <LocationWithGoogleMap
            form={form}
            defaultValue={host}
            latitude={latitude}
            longitude={longitude}
            onMapCoordinateChange={setCoordinates}
            isDisabled={!canEdit}
          />
        </Card>
      </Row>
      <Row>
        <EnquiriesListing host={host} isAdmin={isAdmin} checkIsUserPIC={checkIsUserPIC} />
      </Row>
    </>
  );
};

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired,
  host: PropTypes.object.isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCoordinates: PropTypes.func.isRequired,
  countryCodes: PropTypes.array.isRequired,
  hostTypes: PropTypes.array.isRequired,
  partnershipStatuses: PropTypes.array.isRequired,
  hostStatuses: PropTypes.array.isRequired,
  listOfPIC: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  isAdmin: PropTypes.bool,
  checkIsUserPIC: PropTypes.func.isRequired
};

GeneralInfo.defaultProps = {
  canEdit: true,
  isAdmin: false
};

export default GeneralInfo;
