import React, { useState } from 'react';
import { Button, Col, Icon, Input, Row, Select } from 'antd';
import styled from 'styled-components';

import { useFetchUsersForSelection } from 'utils/hooks';

const { Group: InputGroup, Search } = Input;
const { Option } = Select;

const SearchButton = styled(Button)`
  width: 46px;
  margin-left: -5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const getSearchBarByCondition = ({ selectedFilter, placeholder, users, selectedUser, setSelectedUser, onSearch }) => {
  if (selectedFilter === 'all') {
    return (
      <Row type="flex">
        <Col span={21}>
          <Input disabled placeholder={placeholder} style={{ width: '100%' }} />
        </Col>
        <Col span={3}>
          <SearchButton type="primary" onClick={onSearch}>
            <Icon type="search" />
          </SearchButton>
        </Col>
      </Row>
    );
  } else if (selectedFilter === 'createdBy') {
    return (
      <Row type="flex">
        <Col span={21}>
          <Select
            placeholder={placeholder}
            showSearch
            allowClear
            style={{ width: '100%' }}
            onChange={value => setSelectedUser(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {users && users.map(user => <Option value={user.value}>{user.label}</Option>)}
          </Select>
        </Col>
        <Col span={3}>
          <SearchButton type="primary" onClick={() => onSearch(selectedUser)}>
            <Icon type="search" />
          </SearchButton>
        </Col>
      </Row>
    );
  }
  return <Search enterButton style={{ width: '100%' }} placeholder={placeholder} onSearch={value => onSearch(value)} />;
};

const FilterSearchBar = ({ filterTypesConstants, placeholder, selectedFilter, setSelectedFilter, onSearch }) => {
  const { users } = useFetchUsersForSelection();
  const [selectedUser, setSelectedUser] = useState('');
  const selections = Object.values(filterTypesConstants).map(filter => ({ label: filter.label, value: filter.value }));

  return (
    <InputGroup>
      <Row type="flex" justify="end">
        <Col span={8}>
          <Select
            defaultValue="all"
            style={{ width: '100%' }}
            onChange={value => setSelectedFilter(value)}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {selections.map(selection => (
              <Option key={selection.value} value={selection.value}>
                {selection.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={16}>{getSearchBarByCondition({ selectedFilter, placeholder, users, selectedUser, setSelectedUser, onSearch })}</Col>
      </Row>
    </InputGroup>
  );
};

export default FilterSearchBar;
