import React, { useState } from 'react';
import { Alert, Comment, Divider, Button, List, Input, Skeleton } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { postEnquiryComment } from 'apis/enquiry';
import { constructCommentPayload, getLabelOfConstant } from 'utils/general';
import { useFetchConstant } from 'utils/hooks';

const { TextArea } = Input;

const StatusTag = styled.span`
  padding: 0 4px;
  border: 1px solid ${props => props.borderColor};
  border-radius: 3px;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
`;

const getStatusTagStyling = status => {
  switch (status) {
    case 'new':
      return {
        borderColor: '#adc6ff',
        bgColor: '#f0f5ff',
        color: '#2f54eb'
      };
    case 'deal':
      return {
        borderColor: '#b7eb8f',
        bgColor: '#f6ffed',
        color: '#52c41a'
      };
    case 'end':
      return {
        borderColor: '#d3adf7',
        bgColor: '#f9f0ff',
        color: '#722ed1'
      };
    default:
      return {
        borderColor: '#ffd591',
        bgColor: '#fff7e6',
        color: '#fa8c16'
      };
  }
};

const constructListDataSourceFormat = (comments, enquiryStatusesConstant) => {
  return comments.map(comment => ({
    content: <span>{comment.comment}</span>,
    datetime: (
      <span>
        <StatusTag {...getStatusTagStyling(comment.status)}>{getLabelOfConstant(comment.status, enquiryStatusesConstant)}</StatusTag> ·{' '}
        {moment(comment.commentedAt).fromNow()}
      </span>
    )
  }));
};

const CommentList = ({ comments, enquiryStatusesConstant }) => {
  const dataSource = constructListDataSourceFormat(comments, enquiryStatusesConstant);
  return <List dataSource={dataSource} itemLayout="horizontal" renderItem={props => <Comment {...props} />} />;
};

const Editor = ({ onChange, onSubmit, isSubmitting, value }) => (
  <div>
    <TextArea rows={4} onChange={onChange} value={value} placeholder="Add remarks for this enquiry" />
    <Button type="primary" block={true} loading={isSubmitting} onClick={onSubmit}>
      Add Remark
    </Button>
  </div>
);

const Comments = ({ ticketNumber, enquiryId, enquiryStatus, comments }) => {
  const { isLoading: isEnquiryStatusesLoading, selections: enquiryStatuses } = useFetchConstant(
    'enquiryStatuses',
    'Error while getting enquiry statuses.'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [currentComments, setCurrentComments] = useState(comments);

  const handleOnCommentPost = async () => {
    if (newComment) {
      setIsSubmitting(true);
      const payload = constructCommentPayload(enquiryStatus, newComment);
      const updatedEnquiry = await postEnquiryComment(enquiryId, payload);
      setCurrentComments([...updatedEnquiry.comments]);
      setNewComment('');
      setIsSubmitting(false);
    }
  };

  return isEnquiryStatusesLoading ? (
    <Skeleton />
  ) : (
    <div>
      <Divider orientation="left">Remarks for {ticketNumber}</Divider>
      <Alert
        message={
          <span>
            You'll be adding remarks with the status of <b>{getLabelOfConstant(enquiryStatus, enquiryStatuses)}</b>.<br />
            If you want to leave remarks using new status, please update the status and save this ticket first.
          </span>
        }
        type="info"
        showIcon
      />

      {currentComments.length > 0 && <CommentList comments={currentComments} enquiryStatusesConstant={enquiryStatuses} />}
      <Comment
        content={<Editor onChange={e => setNewComment(e.target.value)} onSubmit={handleOnCommentPost} submitting={isSubmitting} value={newComment} />}
      />
    </div>
  );
};

Comments.propTypes = {
  ticketNumber: PropTypes.string.isRequired,
  enquiryId: PropTypes.string.isRequired,
  enquiryStatus: PropTypes.string.isRequired,
  comments: PropTypes.array
};

Comments.defaultProps = {
  comments: []
};

export default Comments;
