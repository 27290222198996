import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components/macro';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { getLoginRoute } from 'utils/routes';

import placeHolderImage from './images/403.svg';

const loginPath = getLoginRoute().path;

const MainContainer = styled.div`
  background-color: white !important;
  height: 100vh !important;
  width: 100% !important;
  background-image: none;
`;

const StyledRow = styled(Row)`
  margin: auto;
  padding: 40px;
  width: 100%;
  height: 100vh !important;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
`;

const ErrorPlaceholder = styled.img`
  width: 100%;
  max-height: 400px;

  @media screen and (max-width: 767px) {
    height: 200px;
  }
`;

const ErrorContentContainer = styled.div`
  margin: auto;
  height: 400px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    height: 300px;
  }
`;

const ErrorTitle = styled.span`
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    font-size: 56px !important;
    line-height: 56px;
    text-align: center;
  }
`;

const ErrorSubtitle = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
  text-align: left;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

const Forbidden403 = ({ onLogout }) => {
  return (
    <MainContainer>
      <StyledRow type="flex" align="middle">
        <StyledCol span={24} md={10}>
          <ErrorPlaceholder src={placeHolderImage} alt="forbidden-page" />
        </StyledCol>
        <Col span={0} md={1} />
        <StyledCol span={24} md={11}>
          <ErrorContentContainer>
            <ErrorTitle>403 Forbidden</ErrorTitle>
            <ErrorSubtitle>We are sorry, but you do not have access to this page or resource. Please contact the administrator.</ErrorSubtitle>
            <ErrorSubtitle>
              If you constantly getting this error, try to{' '}
              <Link onClick={onLogout} to={loginPath}>
                logout
              </Link>{' '}
              and login again.
            </ErrorSubtitle>
            <Button type="primary" size="large" style={{ width: 'auto', margin: '0 auto' }}>
              <Link to="/">Back to Home</Link>
            </Button>
          </ErrorContentContainer>
        </StyledCol>
      </StyledRow>
    </MainContainer>
  );
};

export default withAppContext(Forbidden403);
