import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, message, Row, Tabs } from 'antd';
import { withRouter } from 'react-router-dom';

import { putUpdateEnquiry, getEnquiryById } from 'apis/enquiry';
import { withAppContext } from 'contexts/AppContext/AppContext';

import Card from 'components/Card/Card';
import CreateOrLinkHostModal from 'components/CreateOrLinkHostModal/CreateOrLinkHostModal';
import CloseEnquiryModal from 'components/CloseEnquiryModal/CloseEnquiryModal';

import { DATE_FORMAT } from 'utils/constants';
import { purifyPayload, checkIsObjectEmpty } from 'utils/general';
import { useFetchConstant, useFetchUsersForSelection } from 'utils/hooks';

import BuildingInfo from './components/BuildingInfo/BuildingInfo';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import OtherInfo from './components/OtherInfo/OtherInfo';
import MatchedHostInfo from './components/MatchedHostInfo/MatchedHostInfo';

const TabPane = Tabs.TabPane;

const GENERAL_INFO = 'General Info';
const BUILDING_INFO = 'Building Info';
const OTHER_INFO = 'Other Info';
const MATCHED_HOST_INFO = 'Matched Host Info';

const useFetchEnquiry = (enquiryId, setBuildings, setHostsMatched) => {
  const [isEnquiryLoading, setIsEnquiryLoading] = useState(true);
  const [enquiry, setEnquiry] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let enquiry = {};

      if (!!enquiryId) {
        enquiry = await getEnquiryById(enquiryId).catch(e => {
          console.error(e);
          message.error(e.message);

          return { error: e };
        });
      }

      setEnquiry(enquiry);
      setBuildings(enquiry.buildings);
      setHostsMatched(enquiry.hostsMatched || []);
      setIsEnquiryLoading(!!enquiry.error || false);
    };

    fetchData();
  }, [enquiryId, setBuildings, setHostsMatched]);

  return { isEnquiryLoading, enquiry, setEnquiry };
};

const useFetchConstants = isAdmin => {
  const { selections: enquirySources, isLoading: isEnquirySourcesLoading } = useFetchConstant(
    'enquirySources',
    'Error while getting acquisition sources.'
  );
  const { selections: enquiryTypes, isLoading: isEnquiryTypesLoading } = useFetchConstant('enquiryTypes', 'Error while getting enquiry types.');
  const { selections: enquirerIdentities, isLoading: isEnquirerIdentitiesLoading, originalSelection: originalEnquirerIdentities } = useFetchConstant(
    'enquirerIdentities',
    'Error while getting enquiry identities.'
  );
  const { selections: priorityTypes, isLoading: isPriorityTypesLoading } = useFetchConstant('priorities', 'Error while getting priority types.');
  const { selections: enquiryStatuses, originalSelection: originalEnquiryStatuses, isLoading: isEnquiryStatusesLoading } = useFetchConstant(
    'enquiryStatuses',
    'Error while getting enquiry statuses.'
  );
  const { selections: countryCodes, originalSelection: originalCountries, isLoading: isCountryCodesLoading } = useFetchConstant(
    'countries',
    'Error while getting countryCodes.',
    'phoneCode',
    'phoneCode'
  );
  const countries = useMemo(() => {
    return Object.values(originalCountries).map(selection => ({
      value: selection['iso2'],
      label: selection['name']
    }));
  }, [originalCountries]);
  const { selections: buildingTypes, isLoading: isBuildingTypesLoading } = useFetchConstant('buildingTypes', 'Error while getting building types');
  const { selections: buildingTenures, isLoading: isBuildingTenuresLoading } = useFetchConstant(
    'buildingTenures',
    'Error while getting building tenures'
  );
  const { selections: buildingTitles, isLoading: isBuildingTitlesLoading } = useFetchConstant(
    'buildingTitles',
    'Error while getting building titles'
  );
  const { selections: furnishingStatuses, isLoading: isFurnishingStatusesLoading } = useFetchConstant(
    'furnishingStatuses',
    'Error while getting furnishing statuses'
  );
  const { selections: states, isLoading: isStatesLoading } = useFetchConstant('statesMY', 'Error while getting furnishing statuses');
  const { selections: hostMatchStatuses, originalSelection: originalHostMatchStatuses, isLoading: isHostMatchStatusesLoading } = useFetchConstant(
    'hostMatchStatuses',
    'Error while getting host match statuses'
  );
  const { selections: hostTypes, isLoading: isHostTypesLoading } = useFetchConstant('hostTypes', 'Error while getting host types');
  const { users: listOfPIC, isLoading: isListOfPICLoading } = useFetchUsersForSelection(!!isAdmin);

  const isConstantsLoading = useMemo(
    () =>
      isEnquirySourcesLoading ||
      isEnquiryTypesLoading ||
      isEnquirerIdentitiesLoading ||
      isPriorityTypesLoading ||
      isEnquiryStatusesLoading ||
      isCountryCodesLoading ||
      isBuildingTypesLoading ||
      isBuildingTenuresLoading ||
      isBuildingTitlesLoading ||
      isFurnishingStatusesLoading ||
      isStatesLoading ||
      isHostMatchStatusesLoading ||
      isHostTypesLoading ||
      isListOfPICLoading,
    [
      isEnquirySourcesLoading,
      isEnquiryTypesLoading,
      isEnquirerIdentitiesLoading,
      isPriorityTypesLoading,
      isEnquiryStatusesLoading,
      isCountryCodesLoading,
      isBuildingTypesLoading,
      isBuildingTenuresLoading,
      isBuildingTitlesLoading,
      isFurnishingStatusesLoading,
      isStatesLoading,
      isHostMatchStatusesLoading,
      isHostTypesLoading,
      isListOfPICLoading
    ]
  );

  return {
    isConstantsLoading,
    enquirySources,
    enquiryTypes,
    enquirerIdentities,
    originalEnquirerIdentities,
    priorityTypes,
    enquiryStatuses,
    originalEnquiryStatuses,
    countryCodes,
    countries,
    buildingTypes,
    buildingTenures,
    buildingTitles,
    furnishingStatuses,
    states,
    hostMatchStatuses,
    originalHostMatchStatuses,
    hostTypes,
    listOfPIC
  };
};

const updateEnquiry = onUpdateSuccess => ({ enquiryId, values, buildings, hostsMatched }) => {
  const formattedHostsMatched = hostsMatched.map(host => ({ host: host.host._id, status: host.status }));

  const updatedEnquiry = purifyPayload({
    ticketNumber: values.ticketNumber,
    enquirer: {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contact: {
        countryCode: values.contactCountryCode,
        contactNumber: values.contactNumber
      },
      companyName: values.companyName,
      identity: values.identity,
      streetAddress: values.streetAddress,
      country: values.country,
      state: values.state,
      city: values.city,
      zipcode: values.zipcode
    },
    assignedTo: values.assignedTo,
    source: values.source,
    types: values.enquiryType,
    priority: values.priority,
    status: values.status,
    detail: values.detail,
    remarks: values.customerRemarks,
    internalRemarks: values.enquiryRemarks,
    buildings,
    parentTicket: values.parentTicketNumber || undefined,
    followUpDate: values.followUpDate.format(DATE_FORMAT),
    forecast: {
      price: values.forecastPrice,
      date: values.forecastDate && values.forecastDate.format(DATE_FORMAT)
    },
    hostsMatched: formattedHostsMatched,
    linkTo: values.host
  });

  return putUpdateEnquiry(enquiryId, updatedEnquiry)
    .then(updatedEnquiry => {
      message.success('You have successfully updated the enquiry.');
      onUpdateSuccess(updatedEnquiry);
      return updatedEnquiry;
    })
    .catch(e => {
      console.error(e);
      message.error('Error while updating enquiry.');
    });
};

const EnquiryFormEdit = ({ className, isAdmin, ...props }) => {
  const { form, history, match } = props;

  const {
    isConstantsLoading,
    enquirySources,
    enquiryTypes,
    enquirerIdentities,
    originalEnquirerIdentities,
    priorityTypes,
    enquiryStatuses,
    originalEnquiryStatuses,
    countryCodes,
    countries,
    buildingTypes,
    buildingTenures,
    buildingTitles,
    furnishingStatuses,
    listOfPIC,
    hostMatchStatuses,
    originalHostMatchStatuses,
    hostTypes,
    states
  } = useFetchConstants(isAdmin);

  const [buildings, setBuildings] = useState([]);
  const [hostsMatched, setHostsMatched] = useState([]);
  const { isEnquiryLoading, enquiry, setEnquiry } = useFetchEnquiry(match.params.id, setBuildings, setHostsMatched);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isCreateOrLinkHostModalVisible, setIsCreateOrLinkHostModalVisible] = useState(false);

  const isLoading = isConstantsLoading || isEnquiryLoading;
  const hasEnquiry = !checkIsObjectEmpty(enquiry);
  const isEnquiryClosed = isLoading || originalEnquiryStatuses.END.code === enquiry.status || originalEnquiryStatuses.DEAL.code === enquiry.status;
  const canEdit = !hasEnquiry || !isEnquiryClosed;
  const enquiryId = enquiry._id;
  const updateEnquiryWithOnUpdateSuccess = updateEnquiry(updatedEnquiry => {
    setEnquiry(updatedEnquiry);
  });

  const handleOnSubmit = e => {
    e.preventDefault();
    const closeStatuses = !isConstantsLoading ? [originalEnquiryStatuses.END.code, originalEnquiryStatuses.DEAL.code] : [];

    if (hostsMatched.filter(host => host.status === originalHostMatchStatuses.ACCEPTED_BY_HOST_AND_OWNER.code).length > 1) {
      message.error('Only one host can have the status: "Signed by Host and Owner"');
      return;
    }

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const updateEnquiryWithFinally = () =>
          updateEnquiryWithOnUpdateSuccess({ enquiryId, values, buildings, hostsMatched }).finally(() => {
            setIsButtonLoading(false);
          });

        if (closeStatuses.includes(values.status)) {
          setIsConfirmModalVisible(true);
        } else {
          setIsButtonLoading(true);
          updateEnquiryWithFinally();
        }
      } else {
        message.error(Object.values(err)[0].errors[0].message);
      }
    });
  };

  const handleOnCloseEnquiryWithHost = e => {
    e.preventDefault();

    const handleOnModalClose = () => {
      setIsCreateOrLinkHostModalVisible(false);
      setIsButtonLoading(false);
    };

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        setIsButtonLoading(true);
        updateEnquiryWithOnUpdateSuccess({ enquiryId, values, buildings, hostsMatched }).finally(handleOnModalClose);
      } else {
        handleOnModalClose();
      }
    });
  };

  const handleOnCloseEnquiry = e => {
    e.preventDefault();
    const hostIdentityInString = isConstantsLoading || originalEnquirerIdentities.HOST.code;

    const handleOnModalClose = () => {
      setIsConfirmModalVisible(false);
      setIsButtonLoading(false);
    };

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        setIsButtonLoading(true);
        if (values.identity === hostIdentityInString) {
          setIsCreateOrLinkHostModalVisible(true);
          handleOnModalClose();
        } else {
          updateEnquiryWithOnUpdateSuccess({ enquiryId, values, buildings, hostsMatched }).finally(handleOnModalClose);
        }
      }
    });
  };

  return (
    <Card onClose={() => history.push('/enquiries')} loading={isLoading}>
      <Form className={className}>
        <Tabs defaultActiveKey={GENERAL_INFO}>
          <TabPane tab={GENERAL_INFO} key={GENERAL_INFO} forceRender>
            <GeneralInfo
              form={form}
              canEdit={canEdit}
              countries={countries}
              states={states}
              enquiry={enquiry}
              enquirySources={enquirySources}
              enquiryTypes={enquiryTypes}
              enquirerIdentities={enquirerIdentities}
              listOfPIC={listOfPIC}
              priorityTypes={priorityTypes}
              countryCodes={countryCodes}
              shouldShowPICSelection={isAdmin}
            />
          </TabPane>
          <TabPane tab={BUILDING_INFO} key={BUILDING_INFO} forceRender>
            <BuildingInfo
              canEdit={canEdit}
              buildings={buildings}
              buildingTypes={buildingTypes}
              buildingTenures={buildingTenures}
              buildingTitles={buildingTitles}
              countries={countries}
              states={states}
              furnishingStatuses={furnishingStatuses}
              onBuildingsChange={setBuildings}
            />
          </TabPane>
          <TabPane tab={OTHER_INFO} key={OTHER_INFO} forceRender>
            <OtherInfo form={form} canEdit={canEdit} enquiry={enquiry} enquiryStatuses={enquiryStatuses} />
          </TabPane>
          <TabPane tab={MATCHED_HOST_INFO} key={MATCHED_HOST_INFO} forceRender>
            <MatchedHostInfo
              canEdit={canEdit}
              hostsMatched={hostsMatched}
              hostMatchStatuses={hostMatchStatuses}
              originalHostMatchStatuses={originalHostMatchStatuses}
              hostTypes={hostTypes}
              onHostsMatchedChange={setHostsMatched}
            />
          </TabPane>
        </Tabs>
        {canEdit && (
          <Row type="flex" justify="start">
            <Button type="primary" size="large" loading={isButtonLoading} onClick={handleOnSubmit}>
              Save
            </Button>
          </Row>
        )}
      </Form>
      <CloseEnquiryModal
        visible={isConfirmModalVisible}
        onOk={handleOnCloseEnquiry}
        onCancel={() => {
          setIsButtonLoading(false);
          setIsConfirmModalVisible(false);
        }}
      />
      <CreateOrLinkHostModal
        visible={isCreateOrLinkHostModalVisible}
        form={form}
        onOk={handleOnCloseEnquiryWithHost}
        onCancel={() => {
          setIsButtonLoading(false);
          setIsCreateOrLinkHostModalVisible(false);
        }}
      />
    </Card>
  );
};

export default withAppContext(withRouter(Form.create()(EnquiryFormEdit)));
