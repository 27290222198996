import React, { useEffect, useState } from 'react';
import { Col, Empty, Row, Skeleton, Table } from 'antd';
import { getDistance } from 'geolib';
import styled from 'styled-components/macro';

import { getPartnerListingHosts, getPartnerListingBuildings } from 'apis/partnerListing';
import Card from 'components/Card/Card';

import { DEFAULT_LAT_LNG } from 'utils/constants';
import { useFetchConstant } from 'utils/hooks';
import { getColumnFilterSearchProps } from 'utils/table';

import GoogleMap from './components/GoogleMap/GoogleMap';
import Filters from './components/Filters/Filters';
import HostAndBuildingInfoModal from './components/HostAndBuildingInfoModal/HostAndBuildingInfoModal';
import Legends from './components/Legends/Legends';

const SEARCH_RADIUS = 10000; // 10KM;

const FilterContainer = styled.div`
  border: 0.5px solid rgb(224, 231, 231);
  padding: 16px;
`;

const HostNameLabel = styled.span`
  text-transform: capitalize;
  color: ${props => props.theme.color.primary};
  cursor: pointer;
`;

const useFetchConstants = () => {
  const { selections: states } = useFetchConstant('statesMY', 'Error while fetching states', 'label');
  const { selections: partnershipStatuses, originalSelection: partnershipStatusesObj } = useFetchConstant(
    'partnershipStatuses',
    'Error while fetching partnership statuses',
    'label'
  );
  const { selections: hostTypes, originalSelection: hostTypesObj } = useFetchConstant('hostTypes', 'Error while fetching host types', 'label');
  return { states, partnershipStatuses, hostTypes, partnershipStatusesObj, hostTypesObj };
};

const useLoadHostsAndBuildingsFromHP = () => {
  const [buildingsFromHP, setBuildingsFromHP] = useState([]);
  const [hostsFromHP, setHostsFromHP] = useState([]);
  const [isTimeOut, setIsTimeOut] = useState(false);

  const fetchBuildings = async () => {
    setTimeout(() => setIsTimeOut(true), 65000);
    const buildingsDetails = await getPartnerListingBuildings();
    setBuildingsFromHP(buildingsDetails);
  };

  const fetchHosts = async () => {
    setTimeout(() => setIsTimeOut(true), 65000);
    const hostDetails = await getPartnerListingHosts().then(hostDetails =>
      hostDetails.map(hostDetail => ({ ...hostDetail, value: hostDetail.id, label: hostDetail.name }))
    );
    setHostsFromHP(hostDetails);
  };

  useEffect(() => {
    fetchBuildings();
    fetchHosts();
  }, []);
  return [buildingsFromHP, hostsFromHP, isTimeOut];
};

const getMapCenterCoordinate = (buildingsFromHP, buildings, addressMapCenter) => {
  if (!!addressMapCenter) {
    return addressMapCenter;
  } else if (buildings.length > 0) {
    return { lat: buildings[0].latitude, lng: buildings[0].longitude };
  } else if (buildingsFromHP.length > 0) {
    return { lat: buildingsFromHP[0].latitude, lng: buildingsFromHP[0].longitude };
  }
  return DEFAULT_LAT_LNG;
};

const handleOnFilterOfTable = (dataIndex, currentValue, record) =>
  record[dataIndex]
    .toString()
    .toLowerCase()
    .includes(currentValue.toLowerCase());

const getColumns = handleOnHostNameClick => [
  {
    title: 'Host Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (prev, next) => prev.name && next.name && prev.name.localeCompare(next.name),
    ...getColumnFilterSearchProps('name', 'Search host name'),
    onFilter: (value, record) => handleOnFilterOfTable('name', value, record),
    render: (text, record) => (text ? <HostNameLabel onClick={() => handleOnHostNameClick(record)}>{text}</HostNameLabel> : <span>-</span>)
  },
  {
    title: 'Total No. of Buildings',
    dataIndex: 'totalNoOfBuildings',
    key: 'totalNoOfBuildings',
    sorter: (prev, next) => prev.totalNoOfBuildings && next.totalNoOfBuildings && prev.totalNoOfBuildings - next.totalNoOfBuildings,
    render: text => (text ? <span>{text}</span> : <span>0</span>)
  }
];

const handleOnSearchClick = (rawBuildings, rawHosts, setBuildings, setHosts, setAddressMapCenter) => (
  addressLatLng,
  selectedState,
  inputHostId,
  selectedHostType,
  selectedPartnershipStatus
) => {
  const buildings = [...rawBuildings];
  const hosts = [...rawHosts];

  const filteredHostsByName = hosts.filter(host => String(inputHostId) === 'all' || String(host.id) === String(inputHostId));
  const filteredHostsByType = filteredHostsByName.filter(
    host => String(selectedHostType) === 'all' || String(host.type) === String(selectedHostType)
  );
  const filteredHostsByTypeAndPartnershipStatus = filteredHostsByType.filter(
    host => String(selectedPartnershipStatus) === 'all' || String(host.partnershipStatus) === String(selectedPartnershipStatus)
  );
  const filteredBuildings = buildings.filter(
    building =>
      (String(selectedState) === 'all' || String(building.state) === String(selectedState)) &&
      filteredHostsByTypeAndPartnershipStatus.filter(filteredHost => String(filteredHost.id) === String(building.hostId)).length > 0
  );
  const filteredBuildingsByRadius = filteredBuildings.filter(
    building =>
      !addressLatLng ||
      getDistance(
        { latitude: addressLatLng.lat, longitude: addressLatLng.lng },
        {
          latitude: building.latitude,
          longitude: building.longitude
        }
      ) <= SEARCH_RADIUS
  );
  const filteredHostsByBuildings = filteredHostsByTypeAndPartnershipStatus.filter(
    filteredHost => filteredBuildingsByRadius.filter(filteredBuilding => String(filteredBuilding.hostId) === String(filteredHost.id)).length > 0
  );

  setBuildings(filteredBuildingsByRadius);
  setHosts(filteredHostsByBuildings);
  setAddressMapCenter(addressLatLng);
};

const handleOnResetClick = (rawBuildings, rawHosts, setBuildings, setHosts, setAddressMapCenter) => () => {
  setBuildings(rawBuildings);
  setHosts(rawHosts);
  setAddressMapCenter();
};

const handleOnHostNameClick = (buildings, setSelectedInfo, setShouldShowHostAndBuildingInfoModal, setIsHostTableRowClicked) => record => {
  const filteredBuilding = buildings.find(building => String(building.hostId) === String(record._id));
  setSelectedInfo({ buildingDetails: filteredBuilding, hostDetails: record });
  setShouldShowHostAndBuildingInfoModal(true);
  setIsHostTableRowClicked(true);
};

const handleOnBuildingClick = (buildings, hosts, setSelectedInfo, setShouldShowHostAndBuildingInfoModal, setIsHostTableRowClicked) => buildingId => {
  const selectedBuilding = buildings.find(building => String(building.id) === String(buildingId));
  setSelectedInfo({ buildingDetails: selectedBuilding, hostDetails: hosts.find(host => String(host.id) === String(selectedBuilding.hostId)) });
  setShouldShowHostAndBuildingInfoModal(true);
  setIsHostTableRowClicked(false);
};

const PartnerListings = () => {
  const { states, partnershipStatuses, hostTypes, partnershipStatusesObj, hostTypesObj } = useFetchConstants();
  const [buildingsFromHP, hostsFromHP, isTimeOut] = useLoadHostsAndBuildingsFromHP();

  const [selectedInfo, setSelectedInfo] = useState({});
  const [buildings, setBuildings] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [addressMapCenter, setAddressMapCenter] = useState();

  useEffect(() => {
    setBuildings(buildingsFromHP);
    setHosts(hostsFromHP);
  }, [buildingsFromHP, hostsFromHP]);

  const [isHostTableRowClicked, setIsHostTableRowClicked] = useState(false);
  const [shouldShowHostAndBuildingInfoModal, setShouldShowHostAndBuildingInfoModal] = useState(false);

  const mapCenter = getMapCenterCoordinate(buildingsFromHP, buildings, addressMapCenter);

  return (
    <Card>
      {buildingsFromHP.length > 0 && hostsFromHP.length > 0 ? (
        <Row gutter={16}>
          <Col span={24} md={10}>
            <FilterContainer>
              <Filters
                states={states}
                hostsFromHP={hostsFromHP}
                hostTypes={hostTypes}
                partnershipStatuses={partnershipStatuses}
                onResetClick={handleOnResetClick(buildingsFromHP, hostsFromHP, setBuildings, setHosts, setAddressMapCenter)}
                onSearchClick={handleOnSearchClick(buildingsFromHP, hostsFromHP, setBuildings, setHosts, setAddressMapCenter)}
              />
            </FilterContainer>
            <div style={{ height: '8px' }} />
            <FilterContainer>
              <Table
                size="middle"
                rowKey={record => record.id}
                columns={getColumns(
                  handleOnHostNameClick(buildings, setSelectedInfo, setShouldShowHostAndBuildingInfoModal, setIsHostTableRowClicked)
                )}
                dataSource={hosts}
                pagination={{ pageSize: 5 }}
              />
            </FilterContainer>
            <div style={{ height: '8px' }} />
            <Legends />
          </Col>
          <Col span={24} md={14}>
            <GoogleMap
              buildings={buildings}
              hosts={hosts}
              partnershipStatusesObj={partnershipStatusesObj}
              hostTypesObj={hostTypesObj}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAHBWhCJOD2ow59B91tQWXL_lunDkacQXE&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `150vh` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              onBuildingClick={handleOnBuildingClick(
                buildings,
                hosts,
                setSelectedInfo,
                setShouldShowHostAndBuildingInfoModal,
                setIsHostTableRowClicked
              )}
              center={mapCenter}
              zoom={10}
              hasPin={!!addressMapCenter}
            />
          </Col>
        </Row>
      ) : isTimeOut ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No partner listing data</span>} />
      ) : (
        <Skeleton />
      )}
      {shouldShowHostAndBuildingInfoModal && (
        <HostAndBuildingInfoModal
          buildingDetails={selectedInfo.buildingDetails}
          hostDetails={selectedInfo.hostDetails}
          onCancel={() => setShouldShowHostAndBuildingInfoModal(false)}
          onlyShowHostTab={isHostTableRowClicked}
        />
      )}
    </Card>
  );
};

export default PartnerListings;
