import React from 'react';
import { Row } from 'antd';
import { BrowserRouter, Link } from 'react-router-dom';

import { getHostRoute } from 'utils/routes';

const HostListing = ({ hosts }) => {
  return (
    <BrowserRouter>
      <div>
        The following hosts will be affected:
        <br />
        {hosts.map(host => 
            <Row>
              <Link to={getHostRoute(host._id).path} target="_blank">
                {host.name}
              </Link>
            </Row>
        )}
      </div>
    </BrowserRouter>
  );
};

export default HostListing;
