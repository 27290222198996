import React, { useMemo, useState, useEffect } from 'react';
import { Button, Col, Empty, Row, Select, Tabs, Skeleton } from 'antd';
import csvjson from 'csvjson';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getCSV } from 'apis/general';
import {
  getEnquiryReport,
  getSourceOfEnquiryReport,
  getDealUnitsReport,
  getHostStatistics,
  getBuildingStatistics,
  getUnitStatistics
} from 'apis/report';
import { useFetchUsersForSelection, useFetchConstant } from 'utils/hooks';

import Card from 'components/Card/Card';
import MonthPicker from 'components/MonthPicker/MonthPicker';

import Histogram from './components/Histogram/Histogram';
import PieChart from './components/PieChart/PieChart';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const StyledTabPane = styled(TabPane)`
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 12px 0 12px;
`;

const FilterSelectionContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  margin: 16px 0;
`;

const ChartRow = styled(Row)`
  margin-bottom: 8px;
`;

const SelectionLabel = styled.p`
  margin-right: 8px;
  line-height: 30px;
`;

const ReportCard = ({
  children,
  isAllowDownloadCSV = true,
  selectionLabel,
  selections,
  title,
  onSelectionChange,
  isLoading,
  query,
  filename,
  addTimeStamp = false
}) => {
  return (
    <Card style={{ marginBottom: '16px' }}>
      <h2 style={{ fontWeight: 'bold' }}>{title}</h2>
      {selections && (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '16px 0' }}>
          <SelectionLabel>{selectionLabel}: </SelectionLabel>
          <Select
            style={{ width: '100%' }}
            defaultValue={''}
            onChange={onSelectionChange}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value={''}>All</Option>
            {selections.map(selection => (
              <Option key={selection.value} value={selection.value}>
                {selection.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
      <Skeleton active={true} loading={isLoading}>
        {children}
      </Skeleton>
      <Row type="flex" justify="center">
        {isAllowDownloadCSV && (
          <Button type="primary" icon="download" onClick={() => handleDownloadCSV(query, filename, addTimeStamp)}>
            Download CSV
          </Button>
        )}
      </Row>
    </Card>
  );
};

const getCountrySelections = () => [{ label: 'Malaysia', value: 'MY' }];

const handleDownloadCSV = (query, filename, addTimeStamp) => {
  getCSV('report', query).then(res => {
    const url = window.URL.createObjectURL(new Blob([csvjson.toCSV(res, { headers: 'key' })]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}${addTimeStamp ? moment().format('YYYY-MM-DD-HH-MM-SS') : ''}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const getMomentDate = (month, year) =>
  moment()
    .set('month', month - 1)
    .set('year', year);

const useLoadEnquiryReportData = (month, year, assignedTo) => {
  const [enquiryStatusData, setEnquiryStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const fetchEnquiryReport = async () => {
      const enquiriesReport = await getEnquiryReport({ month, year, assignedTo }).finally(() => setIsLoading(false));
      setHasData(false);
      enquiriesReport.forEach(report => {
        if (report.noOfEnquiry > 0) {
          setHasData(true);
        }
      });
      setEnquiryStatusData(enquiriesReport);
    };
    fetchEnquiryReport();
  }, [month, year, assignedTo]);
  return { enquiryStatusData, isLoading, hasData };
};

const useLoadSourceOfEnquiryData = (month, year, source) => {
  const [sourceOfEnquiryData, setSourceOfEnquiryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const fetchSourceOfEnquiryReport = async () => {
      const enquiriesReport = await getSourceOfEnquiryReport({ month, year, source }).finally(() => setIsLoading(false));
      setHasData(false);
      enquiriesReport.forEach(report => {
        if (report.noOfEnquiry > 0) {
          setHasData(true);
        }
      });
      setSourceOfEnquiryData(enquiriesReport);
    };
    fetchSourceOfEnquiryReport();
  }, [month, year, source]);
  return { sourceOfEnquiryData, isLoading, hasData };
};

const useLoadDealUnitsData = (month, year, assignedTo) => {
  const [dealUnitsData, setDealUnitsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const fetchDealUnitsReport = async () => {
      const dealUnitsReport = await getDealUnitsReport({ month, year, assignedTo }).finally(() => setIsLoading(false));
      setHasData(false);
      dealUnitsReport.forEach(report => {
        if (report.noOfUnit > 0) {
          setHasData(true);
        }
      });
      setDealUnitsData(dealUnitsReport);
    };
    fetchDealUnitsReport();
  }, [month, year, assignedTo]);
  return { dealUnitsData, isLoading, hasData };
};

const useLoadStatistics = country => {
  const [hostStatistics, setHostStatistics] = useState([]);
  const [buildingStatistics, setBuildingStatistics] = useState([]);
  const [unitStatistics, setUnitStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHostStatistics = async () => {
      const hostStatistics = await getHostStatistics({ country });
      setHostStatistics(hostStatistics);
    };
    const fetchBuildingStatistics = async () => {
      const buildingStatistics = await getBuildingStatistics({ country });
      setBuildingStatistics(buildingStatistics);
    };
    const fetchUnitStatistics = async () => {
      const unitStatistics = await getUnitStatistics({ country });
      setUnitStatistics(unitStatistics);
    };
    fetchHostStatistics();
    fetchBuildingStatistics();
    fetchUnitStatistics();
    setIsLoading(false);
  }, [country]);
  return { hostStatistics, buildingStatistics, unitStatistics, isLoading };
};

const SalesReport = () => {
  const { users: listOfPIC } = useFetchUsersForSelection();
  const { selections: listOfSource } = useFetchConstant('enquirySources');

  const [enquiryReportAssignedTo, setEnquiryReportAssignedTo] = useState('');
  const [dealUnitReportAssignedTo, setDealUnitReportAssignedTo] = useState('');
  const [source, setSource] = useState('');
  const [country, setCountry] = useState('');
  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const { enquiryStatusData, isLoading: isEnquiryStatusDataLoading, hasData: enquiryHasData } = useLoadEnquiryReportData(
    month,
    year,
    enquiryReportAssignedTo
  );
  const { sourceOfEnquiryData, isLoading: isSourceOfEnquiryDataLoading, hasData: sourceHasData } = useLoadSourceOfEnquiryData(month, year, source);
  const { dealUnitsData, isLoading: isDealUnitsDataLoading, hasData: dealUnitsHasData } = useLoadDealUnitsData(month, year, dealUnitReportAssignedTo);
  const { hostStatistics, buildingStatistics, unitStatistics, isLoading: isStatisticsLoading } = useLoadStatistics(country);
  const momentDate = useMemo(() => getMomentDate(month, year), [month, year]);

  return (
    <Card>
      <Tabs defaultActiveKey="reports" tabBarStyle={{ marginBottom: '0px', marginTop: '-15px' }}>
        <StyledTabPane tab="Enquiry Reports" key="reports">
          <FilterSelectionContainer justifyContent="left">
            <MonthPicker
              defaultValue={momentDate}
              onChange={newDate => {
                setYear(newDate.format('YYYY'));
                setMonth(newDate.format('M'));
              }}
            />
          </FilterSelectionContainer>
          <ChartRow gutter={16} type="flex" justify="center">
            <Col span={24} lg={8}>
              <ReportCard
                selectionLabel="PIC"
                selections={listOfPIC}
                title="Status Report"
                onSelectionChange={selection => setEnquiryReportAssignedTo(selection)}
                isLoading={isEnquiryStatusDataLoading}
                query={{ type: 'enquiry', month: month, year: year, assignedTo: enquiryReportAssignedTo }}
                filename={`enquiry-report-${
                  enquiryReportAssignedTo
                    ? listOfPIC
                        .find(pic => pic.value === enquiryReportAssignedTo)
                        .label.split(' ')
                        .join('-')
                    : 'all'
                }-${year}-${month}`}
              >
                {enquiryHasData ? (
                  <Histogram xAxisName="enquiryStatus" yAxisName="noOfEnquiry" graphData={enquiryStatusData} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No enquiry status report</span>} />
                )}
              </ReportCard>
            </Col>
            <Col span={24} lg={8}>
              <ReportCard
                selectionLabel="Source"
                selections={listOfSource}
                title="Source Report"
                onSelectionChange={selection => setSource(selection)}
                isLoading={isSourceOfEnquiryDataLoading}
                query={{ type: 'source', month: month, year: year, source: source }}
                filename={`source-report-${source ? source : 'all'}-${year}-${month}`}
              >
                {sourceHasData ? (
                  <Histogram xAxisName="month" yAxisName="noOfEnquiry" graphData={sourceOfEnquiryData} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No enquiry source report</span>} />
                )}
              </ReportCard>
            </Col>
            <Col span={24} lg={8}>
              <ReportCard
                selectionLabel="PIC"
                selections={listOfPIC}
                title="Deal Units Report"
                onSelectionChange={selection => setDealUnitReportAssignedTo(selection)}
                isLoading={isDealUnitsDataLoading}
                query={{ type: 'deal', month: month, year: year, assignedTo: dealUnitReportAssignedTo }}
                filename={`deal-units-report-${
                  dealUnitReportAssignedTo
                    ? listOfPIC
                        .find(pic => pic.value === dealUnitReportAssignedTo)
                        .label.split(' ')
                        .join('-')
                    : 'all'
                }-${year}-${month}`}
              >
                {dealUnitsHasData ? (
                  <Histogram xAxisName="month" yAxisName="noOfUnit" graphData={dealUnitsData} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No closed enquiry deal units report</span>} />
                )}
              </ReportCard>
            </Col>
          </ChartRow>
        </StyledTabPane>
        <StyledTabPane tab="Statistics" key="statistic">
          <FilterSelectionContainer>
            <SelectionLabel>Country: </SelectionLabel>
            <Select
              style={{ width: '100%' }}
              defaultValue={''}
              onChange={country => {
                setCountry(country);
              }}
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={''}>All</Option>
              {getCountrySelections().map(selection => (
                <Option key={selection.value} value={selection.value}>
                  {selection.label}
                </Option>
              ))}
            </Select>
          </FilterSelectionContainer>
          <ChartRow gutter={16} type="flex">
            <Col span={24} lg={24}>
              <ReportCard
                title="Total Number of Hosts"
                isLoading={isStatisticsLoading}
                query={{ type: 'host', country: country }}
                filename={`host-statistics-${country ? country : 'all'}-`}
                addTimeStamp={true}
              >
                {hostStatistics.length > 0 ? (
                  <PieChart graphData={hostStatistics} field="count" dimension="label" />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No host statistics</span>} />
                )}
              </ReportCard>
            </Col>
            <Col span={24} lg={24}>
              <ReportCard
                title="Total Number of Buildings"
                isLoading={isStatisticsLoading}
                query={{ type: 'building', country: country }}
                filename={`building-statistics-${country ? country : 'all'}-`}
                addTimeStamp={true}
              >
                {buildingStatistics.length > 0 ? (
                  <PieChart graphData={buildingStatistics} field="count" dimension="label" />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No building statistics</span>} />
                )}
              </ReportCard>
            </Col>
            <Col span={24} lg={24}>
              <ReportCard title="Total Number of Units" isAllowDownloadCSV={false} isLoading={isStatisticsLoading}>
                {unitStatistics.length > 0 ? (
                  <PieChart graphData={unitStatistics} field="count" dimension="label" />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No unit statistics</span>} />
                )}
              </ReportCard>
            </Col>
          </ChartRow>
        </StyledTabPane>
      </Tabs>
    </Card>
  );
};

export default withRouter(SalesReport);
