import React from 'react';
import { Form, Modal, notification } from 'antd';
import { withRouter } from 'react-router-dom';

import { putUpdateEnquiry } from 'apis/enquiry';
import { useFetchUsersForSelection } from 'utils/hooks';

import FormSelection from 'components/FormSelection/FormSelection';

const AssignEnquiryPICModal = ({ form, showModal, selectedEnquiry, closeModal, onAssignPICSuccess }) => {
  const { users: listOfPIC } = useFetchUsersForSelection();

  const handleOnAssignPICButtonClicked = e => {
    e.preventDefault();

    const id = selectedEnquiry._id;
    const originalPIC = selectedEnquiry.assignedTo;

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const successNotification = () =>
          notification.success({
            duration: 3,
            message: 'Success',
            description: "You've successfully assigned the PIC to this ticket."
          });

        if (values.pic !== originalPIC) {
          putUpdateEnquiry(id, { assignedTo: values.pic }).then(() => {
            successNotification();
            closeModal();
            onAssignPICSuccess();
          });
        } else {
          successNotification();
          closeModal();
        }
      }
    });
  };

  return (
    <Modal
      title={`Assign PIC for ${selectedEnquiry.ticketNumber}`}
      visible={showModal}
      onOk={handleOnAssignPICButtonClicked}
      onCancel={closeModal}
      okText="Assign"
      destroyOnClose
    >
      <Form>
        <p>
          <b>Enquirer Name: </b> {selectedEnquiry.enquirerName}
        </p>
        <p>
          <b>Company Name: </b> {selectedEnquiry.companyName || '-'}
        </p>
        <p>
          <b>Follow Up Date: </b> {selectedEnquiry.followUpDate}
        </p>
        <p>
          <b>Priority: </b> {selectedEnquiry.priority}
        </p>
        <FormSelection
          form={form}
          name="pic"
          defaultValue={selectedEnquiry.assignedTo}
          selections={listOfPIC}
          placeholder="Select a PIC"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          requiredErrorMessage="Please select the person in charge"
        />
      </Form>
    </Modal>
  );
};

export default withRouter(Form.create()(AssignEnquiryPICModal));
