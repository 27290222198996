import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';

import { purifyPayload } from 'utils/general';

import Card from 'components/Card/Card';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import FormBuildingSuggestions from 'components/FormBuildingSuggestions/FormBuildingSuggestions';
import LocationWithGoogleMap, { useLngLat } from 'components/LocationWithGoogleMap/LocationWithGoogleMap';

const GeneralInfo = ({
  form,
  building,
  buildingTypes,
  buildingRegulationStatuses,
  buildingStatuses,
  corporateBodies,
  buildingTitles,
  onMapCoordinateChange,
  buildingId,
  setBuildingId,
  selectedBuildings
}) => {
  const updateBuildingId = selectedBuildingId => {
    if (!!selectedBuildingId) {
      setBuildingId(selectedBuildingId);
    } else if (buildingId !== '') {
      setBuildingId('');
    }
  };

  const handleOnSelectBuilding = selectedProperty => {
    form.setFieldsValue({
      name: selectedProperty.name,
      type: selectedProperty.type,
      regulationStatus: selectedProperty.regulationStatus,
      status: selectedProperty.status,
      corporateBody: selectedProperty.corporateBody,
      title: selectedProperty.title,
      totalNoOfUnits: undefined,
      propertyAddress: selectedProperty.streetAddress,
      propertyCity: selectedProperty.city,
      propertyZipcode: selectedProperty.zipcode,
      propertyCountry: selectedProperty.country,
      propertyState: selectedProperty.state
    });
    onMapCoordinateChange(selectedProperty.latitude, selectedProperty.longitude);
    updateBuildingId(selectedProperty._id);
  };

  return (
    <Card title="Basic Details">
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormBuildingSuggestions
            form={form}
            defaultValue={building.name}
            onInputChange={updateBuildingId}
            onSelectBuilding={handleOnSelectBuilding}
            selectedBuildings={selectedBuildings}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="type"
            label="Building Type"
            selections={buildingTypes}
            defaultValue={building.type}
            placeholder="Select a building type"
            requiredErrorMessage="Please select a building type"
            isDisabled={!!buildingId}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="regulationStatus"
            label="Building Regulation Status"
            selections={buildingRegulationStatuses}
            defaultValue={building.regulationStatus}
            placeholder="Select a building regulation status"
            isDisabled={!!buildingId}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="status"
            label="Building Status"
            selections={buildingStatuses}
            defaultValue={building.status}
            placeholder="Select a building status"
            isDisabled={!!buildingId}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="corporateBody"
            label="Corporate Body"
            selections={corporateBodies}
            defaultValue={building.corporateBody}
            placeholder="Select a building corporate body"
            isDisabled={!!buildingId}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            form={form}
            name="title"
            label="Building Title"
            selections={buildingTitles}
            defaultValue={building.title}
            placeholder="Select a building title"
            isDisabled={!!buildingId}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={64}>
        <Col span={24} md={12}>
          <FormInputNumber
            form={form}
            name="totalNoOfUnits"
            label="Host On Hand Unit(s)"
            defaultValue={building.hostOnHandUnits}
            placeholder="Number of units owned by this host in this building"
            requiredErrorMessage="Please enter the number of units owned by this host in this building"
          />
        </Col>
      </Row>
    </Card>
  );
};

const BuildingModal = ({
  form,
  building,
  buildingTypes,
  buildingRegulationStatuses,
  buildingStatuses,
  corporateBodies,
  buildingTitles,
  isVisible,
  onSave,
  onCancel,
  selectedBuildings
}) => {
  const { latitude, longitude, setCoordinates } = useLngLat(building.latitude, building.longitude);
  const [buildingId, setBuildingId] = useState(building._id);

  const handleOnSave = e => {
    e.preventDefault();
    e.stopPropagation();
    form.validateFields((err, values) => {
      if (!err) {
        const newBuilding = {
          _id: buildingId,
          name: values.name,
          type: values.type,
          regulationStatus: values.regulationStatus,
          status: values.status,
          corporateBody: values.corporateBody,
          title: values.title,
          totalNoOfUnits: values.totalNoOfUnits,
          hostOnHandUnits: values.totalNoOfUnits,
          streetAddress: values.propertyAddress,
          city: values.propertyCity,
          zipcode: values.propertyZipcode,
          state: values.propertyState,
          country: values.propertyCountry,
          longitude,
          latitude
        };

        const purifiedValues = purifyPayload(newBuilding);
        onSave(purifiedValues);
      }
    });
  };

  return (
    <Modal width="1000px" maskClosable={false} visible={isVisible} onCancel={onCancel} footer={null} destroyOnClose>
      <Form onSubmit={handleOnSave}>
        <GeneralInfo
          form={form}
          building={building}
          buildingTypes={buildingTypes}
          buildingRegulationStatuses={buildingRegulationStatuses}
          buildingStatuses={buildingStatuses}
          corporateBodies={corporateBodies}
          buildingTitles={buildingTitles}
          onMapCoordinateChange={setCoordinates}
          buildingId={buildingId}
          setBuildingId={setBuildingId}
          selectedBuildings={selectedBuildings}
        />
        <Card title="Location Details">
          <LocationWithGoogleMap
            form={form}
            defaultValue={building}
            latitude={latitude}
            longitude={longitude}
            onMapCoordinateChange={setCoordinates}
            isDisabled={!!buildingId}
            isRequired
          />
        </Card>

        <Row gutter={8} type="flex">
          <Col>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default Form.create()(BuildingModal);
