import React, { useEffect, useState } from 'react';
import { Col, Empty, Row, Timeline } from 'antd';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import ReactDiffViewer from 'react-diff-viewer';
import styled from 'styled-components';

import { getActivityLogsWithAggregation } from 'apis/activityLog';
import Card from 'components/Card/Card';
import { getEnquiryRoute, getHostRoute, getUserRoute, getBuildingRoute, getCampaignHostRoute } from 'utils/routes';

import FilterSearchBar from './components/FilterSearchBar/FilterSearchBar';

const LIMIT = 10;

const FILTER_TYPES = {
  all: { label: 'All Activities', value: 'all', placeholder: 'No text search allowed' },
  createdBy: { label: 'Performed by', value: 'createdBy', placeholder: 'Select user to see recent activities' },
  Enquiry: { label: 'Enquiry Module', value: 'Enquiry', placeholder: 'Search by Ticket Number' },
  Host: { label: 'Host Module', value: 'Host', placeholder: 'Search by Host\'s Name' },
  User: { label: 'User Module', value: 'User', placeholder: 'Search by User\'s Name' },
  Building: { label: 'Building Module', value: 'Building', placeholder: 'Search by Building\'s Name' },
  CampaignHost: { label: 'Campaign Host Module', value: 'CampaignHost', placeholder: 'Search by Campaign Host\'s Name' }

};

const LegendsContainer = styled(Row)`
  border: 1.5px solid rgb(224, 231, 231);
  padding: 16px;
  margin-bottom: 16px;
`;

const LegendIndicator = styled.div`
  background-color: ${props => props.color};
  border: 0.5px solid ${props => props.borderColor};
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

const Description = styled.span`
  font-weight: 500;
`;

const SubDescription = styled.span`
  color: #00000073;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
`;

const ViewDetailsButton = styled.span`
  font-size: 12px;
  cursor: pointer;
  color: ${props => props.theme.color.primary};

  :hover {
    text-decoration: underline;
  }
`;

const LoadMoreButton = styled.p`
  font-size: 12px;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  margin-top: 16px;

  :hover {
    text-decoration: underline;
  }
`;

const Legends = () => (
  <LegendsContainer gutter={16}>
    <Col span={6} style={{ display: 'flex' }}>
      <LegendIndicator color="#ffeef0" borderColor="#fbcdd3" /> Removed
    </Col>
    <Col span={6} style={{ display: 'flex' }}>
      <LegendIndicator color="#e6ffed" borderColor="#9ef9b7" /> Newly added
    </Col>
  </LegendsContainer>
);

const getModuleItemName = (module, data, withLink = false) => {
  switch (module) {
    case 'Enquiry':
      return withLink ? <Link to={getEnquiryRoute(data._id).path}>{data.ticketNumber}</Link> : data.ticketNumber;
    case 'Host':
      return withLink ? <Link to={getHostRoute(data._id).path}>{data.name}</Link> : data.name;
    case 'User':
      return withLink ? <Link to={getUserRoute(data._id).path}>{`${data.firstName} ${data.lastName}`}</Link> : `${data.firstName} ${data.lastName}`;
    case 'Building':
      return withLink ? <Link to={getBuildingRoute(data._id).path}>{data.name}</Link> : data.name;
    case 'CampaignHost':
        return withLink ? <Link to={getCampaignHostRoute(data._id).path}>{data.name}</Link> : data.name;
    default:
      return '-';
  }
};

const TimelineItem = ({ who, when, method, module, oldData, newData, onViewDetailsClick }) => {
  let color;
  let description;

  switch (method) {
    case 'delete':
      color = 'red';
      description = (
        <>
          <Description>
            {who} has deleted {module.toLowerCase()} on <i>{moment(when).format('LLLL')}</i>.
          </Description>
          <br />
          <SubDescription>
            {module} - {getModuleItemName(module, oldData)}
          </SubDescription>
        </>
      );
      break;
    case 'create':
      color = 'green';
      description = (
        <>
          <Description>
            {who} has created a new {module.toLowerCase()} on <i>{moment(when).format('LLLL')}</i>.
          </Description>
          <br />
          <SubDescription>
            {module} - {getModuleItemName(module, newData)}
          </SubDescription>
        </>
      );
      break;
    default:
      color = 'blue';
      description = (
        <>
          <Description>
            {who} has updated details on a {module.toLowerCase()} on <i>{moment(when).format('LLLL')}</i>.
          </Description>
          <br />
          <SubDescription>
            {module} - {getModuleItemName(module, newData, true)}
          </SubDescription>
        </>
      );
      break;
  }

  return (
    <Timeline.Item color={color}>
      <p>
        {description}
        <br />
        <ViewDetailsButton onClick={() => onViewDetailsClick(oldData, newData)}>View Details »</ViewDetailsButton>
      </p>
    </Timeline.Item>
  );
};

const useLoadActivityLogs = () => {
  const [todayActivityLogs, setTodayActivityLogs] = useState([]);
  const [yesterdayActivityLogs, setYesterdayActivityLogs] = useState([]);
  const [thisMonthActivityLogs, setThisMonthActivityLogs] = useState([]);
  const [prevMonthsactivityLogs, setPrevMonthsActivityLogs] = useState([]);

  const [totalOfTodayActivityLogs, setTotalOfTodayActivityLogs] = useState(0);
  const [totalOfYesterdayActivityLogs, setTotalOfYesterdayActivityLogs] = useState(0);
  const [totalOfThisMonthActivityLogs, setTotalOfThisMonthActivityLogs] = useState(0);
  const [totalOfPrevMonthsActivityLogs, setTotalOfPrevMonthsActivityLogs] = useState(0);

  const fetchActivityLogs = async (period, currentPage, currentActivityLogs, setActivityLogs, setTotalOfActivityLogs, query = {}) => {
    const today = moment();
    const yesterday = moment().add(-1, 'days');
    const tomorrow = moment().add(1, 'days');
    const dateRange = {
      today: {
        startDate: today.startOf('day').format('YYYY-MM-DD'),
        endDate: tomorrow.startOf('day').format('YYYY-MM-DD')
      },
      yesterday: {
        startDate: yesterday.startOf('day').format('YYYY-MM-DD'),
        endDate: today.endOf('day').format('YYYY-MM-DD')
      },
      thisMonth: {
        startDate: today.startOf('month').format('YYYY-MM-DD'),
        endDate: yesterday.startOf('day').format('YYYY-MM-DD')
      },
      prevMonths: {
        endDate: today.startOf('month').format('YYYY-MM-DD')
      }
    };

    const queryToFetch = {
      filter: encodeURIComponent(JSON.stringify({ startDate: dateRange[`${period}`].startDate, endDate: dateRange[`${period}`].endDate, ...query })),
      pagination: encodeURIComponent(JSON.stringify({ limit: 10, currentPage: currentPage }))
    };
    const activityLogsDetails = await getActivityLogsWithAggregation(queryToFetch);
    setActivityLogs(currentPage > 1 ? [...currentActivityLogs, ...activityLogsDetails.activityLogs] : activityLogsDetails.activityLogs);
    setTotalOfActivityLogs(activityLogsDetails.totalCount);
    return activityLogsDetails;
  };

  // Want this to act like componentDidMount
  useEffect(() => {
    const currentPage = 1;
    fetchActivityLogs('today', currentPage, todayActivityLogs, setTodayActivityLogs, setTotalOfTodayActivityLogs);
    fetchActivityLogs('yesterday', currentPage, yesterdayActivityLogs, setYesterdayActivityLogs, setTotalOfYesterdayActivityLogs);
    fetchActivityLogs('thisMonth', currentPage, thisMonthActivityLogs, setThisMonthActivityLogs, setTotalOfThisMonthActivityLogs);
    fetchActivityLogs('prevMonths', currentPage, prevMonthsactivityLogs, setPrevMonthsActivityLogs, setTotalOfPrevMonthsActivityLogs);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    todayActivityLogs,
    yesterdayActivityLogs,
    thisMonthActivityLogs,
    prevMonthsactivityLogs,
    totalOfTodayActivityLogs,
    totalOfYesterdayActivityLogs,
    totalOfThisMonthActivityLogs,
    totalOfPrevMonthsActivityLogs,
    setTodayActivityLogs,
    setTotalOfTodayActivityLogs,
    setYesterdayActivityLogs,
    setTotalOfYesterdayActivityLogs,
    setThisMonthActivityLogs,
    setTotalOfThisMonthActivityLogs,
    setPrevMonthsActivityLogs,
    setTotalOfPrevMonthsActivityLogs,
    fetchActivityLogs
  };
};

const getFilterQuery = (value, currentFilterSelection) => {
  if (currentFilterSelection === 'all') {
    return {};
  } else if (currentFilterSelection === 'createdBy') {
    return {
      createdBy: value
    };
  } else {
    return {
      moduleName: currentFilterSelection,
      search: value
    };
  }
};

const ActivityLogs = () => {
  const {
    todayActivityLogs,
    yesterdayActivityLogs,
    thisMonthActivityLogs,
    prevMonthsactivityLogs,

    totalOfTodayActivityLogs,
    totalOfYesterdayActivityLogs,
    totalOfThisMonthActivityLogs,
    totalOfPrevMonthsActivityLogs,

    setTodayActivityLogs,
    setTotalOfTodayActivityLogs,

    setYesterdayActivityLogs,
    setTotalOfYesterdayActivityLogs,

    setThisMonthActivityLogs,
    setTotalOfThisMonthActivityLogs,

    setPrevMonthsActivityLogs,
    setTotalOfPrevMonthsActivityLogs,

    fetchActivityLogs
  } = useLoadActivityLogs();

  const [currentPageForToday, setCurrentPageForToday] = useState(1);
  const [currentPageForYesterday, setCurrentPageForYesterday] = useState(1);
  const [currentPageForThisMonth, setCurrentPageForThisMonth] = useState(1);
  const [currentPageForPrevMonths, setCurrentPageForPrevMonths] = useState(1);

  const [isViewDetailsClicked, setViewDetailsClicked] = useState(false);
  const [oldData, setOldData] = useState('');
  const [newData, setNewData] = useState('');

  const [currentFilterSelection, setCurrentFilterSelection] = useState('all');
  const showActivityLogs =
    totalOfTodayActivityLogs > 0 || totalOfYesterdayActivityLogs > 0 || totalOfThisMonthActivityLogs > 0 || totalOfPrevMonthsActivityLogs > 0;

  const handleOnViewDetailsClicked = (oldData, newData) => {
    setViewDetailsClicked(true);
    if (oldData) {
      delete oldData.__v;
      oldData = JSON.stringify(oldData, null, 4).replace(/"/gi, '');
    }

    if (newData) {
      delete newData.__v;
      newData = JSON.stringify(newData, null, 4).replace(/"/gi, '');
    }

    setOldData(oldData);
    setNewData(newData);
  };
  return (
    <>
      <Row type="flex" justify="end" style={{ marginBottom: '16px' }}>
        <Col span={24} md={10}>
          <FilterSearchBar
            filterTypesConstants={FILTER_TYPES}
            placeholder={FILTER_TYPES[currentFilterSelection].placeholder}
            selectedFilter={currentFilterSelection}
            setSelectedFilter={setCurrentFilterSelection}
            onSearch={value => {
              fetchActivityLogs(
                'today',
                1,
                todayActivityLogs,
                setTodayActivityLogs,
                setTotalOfTodayActivityLogs,
                getFilterQuery(value, currentFilterSelection)
              );
              fetchActivityLogs(
                'yesterday',
                1,
                yesterdayActivityLogs,
                setYesterdayActivityLogs,
                setTotalOfYesterdayActivityLogs,
                getFilterQuery(value, currentFilterSelection)
              );
              fetchActivityLogs(
                'thisMonth',
                1,
                thisMonthActivityLogs,
                setThisMonthActivityLogs,
                setTotalOfThisMonthActivityLogs,
                getFilterQuery(value, currentFilterSelection)
              );
              fetchActivityLogs(
                'prevMonths',
                1,
                prevMonthsactivityLogs,
                setPrevMonthsActivityLogs,
                setTotalOfPrevMonthsActivityLogs,
                getFilterQuery(value, currentFilterSelection)
              );
            }}
          />
        </Col>
      </Row>
      <Row gutter={18}>
        {!showActivityLogs ? (
          <Col span={24}>
            <Card>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No related activity logs</span>} />
            </Card>
          </Col>
        ) : (
          <>
            <Col span={24} md={10} style={{ maxHeight: '77vh', overflowY: 'scroll' }}>
              <Card>
                {totalOfTodayActivityLogs > 0 && (
                  <Card title="Today">
                    <Timeline>
                      {todayActivityLogs.map(todayActivityLog => (
                        <TimelineItem
                          key={todayActivityLog._id}
                          who={
                            (todayActivityLog.createdBy && `${todayActivityLog.createdBy.firstName} ${todayActivityLog.createdBy.lastName}`) || 'NIL'
                          }
                          when={todayActivityLog.createdAt}
                          method={todayActivityLog.method}
                          module={todayActivityLog.moduleName}
                          oldData={todayActivityLog.oldData}
                          newData={todayActivityLog.newData}
                          onViewDetailsClick={handleOnViewDetailsClicked}
                        />
                      ))}
                    </Timeline>
                    {totalOfTodayActivityLogs > LIMIT * currentPageForToday && (
                      <LoadMoreButton
                        onClick={() => {
                          setCurrentPageForToday(currentPageForToday + 1);
                          fetchActivityLogs('today', currentPageForToday + 1, todayActivityLogs, setTodayActivityLogs, setTotalOfTodayActivityLogs);
                        }}
                      >
                        {' '}
                        » Load more
                      </LoadMoreButton>
                    )}
                  </Card>
                )}
                {totalOfYesterdayActivityLogs > 0 && (
                  <Card title="Yesterday">
                    <Timeline>
                      {yesterdayActivityLogs.map(yesterdayActivityLog => (
                        <TimelineItem
                          key={yesterdayActivityLog._id}
                          who={
                            (yesterdayActivityLog.createdBy &&
                              `${yesterdayActivityLog.createdBy.firstName} ${yesterdayActivityLog.createdBy.lastName}`) ||
                            'NIL'
                          }
                          when={yesterdayActivityLog.createdAt}
                          method={yesterdayActivityLog.method}
                          module={yesterdayActivityLog.moduleName}
                          oldData={yesterdayActivityLog.oldData}
                          newData={yesterdayActivityLog.newData}
                          onViewDetailsClick={handleOnViewDetailsClicked}
                        />
                      ))}
                    </Timeline>
                    {totalOfYesterdayActivityLogs > LIMIT * currentPageForYesterday && (
                      <LoadMoreButton
                        onClick={() => {
                          setCurrentPageForYesterday(currentPageForYesterday + 1);
                          fetchActivityLogs(
                            'yesterday',
                            currentPageForYesterday + 1,
                            yesterdayActivityLogs,
                            setYesterdayActivityLogs,
                            setTotalOfYesterdayActivityLogs
                          );
                        }}
                      >
                        {' '}
                        » Load more
                      </LoadMoreButton>
                    )}
                  </Card>
                )}
                {totalOfThisMonthActivityLogs > 0 && (
                  <Card title="This Month">
                    <Timeline>
                      {thisMonthActivityLogs.map(thisMonthActivityLog => (
                        <TimelineItem
                          key={thisMonthActivityLog._id}
                          who={
                            (thisMonthActivityLog.createdBy &&
                              `${thisMonthActivityLog.createdBy.firstName} ${thisMonthActivityLog.createdBy.lastName}`) ||
                            'NIL'
                          }
                          when={thisMonthActivityLog.createdAt}
                          method={thisMonthActivityLog.method}
                          module={thisMonthActivityLog.moduleName}
                          oldData={thisMonthActivityLog.oldData}
                          newData={thisMonthActivityLog.newData}
                          onViewDetailsClick={handleOnViewDetailsClicked}
                        />
                      ))}
                    </Timeline>
                    {totalOfThisMonthActivityLogs > LIMIT * currentPageForThisMonth && (
                      <LoadMoreButton
                        onClick={() => {
                          setCurrentPageForThisMonth(currentPageForThisMonth + 1);
                          fetchActivityLogs(
                            'thisMonth',
                            currentPageForThisMonth + 1,
                            thisMonthActivityLogs,
                            setThisMonthActivityLogs,
                            setTotalOfThisMonthActivityLogs
                          );
                        }}
                      >
                        {' '}
                        » Load more
                      </LoadMoreButton>
                    )}
                  </Card>
                )}
                {totalOfPrevMonthsActivityLogs > 0 && (
                  <Card title="Previous Months">
                    <Timeline>
                      {prevMonthsactivityLogs.map(prevMonthsActivityLog => (
                        <TimelineItem
                          key={prevMonthsActivityLog._id}
                          who={
                            (prevMonthsActivityLog.createdBy &&
                              `${prevMonthsActivityLog.createdBy.firstName} ${prevMonthsActivityLog.createdBy.lastName}`) ||
                            'NIL'
                          }
                          when={prevMonthsActivityLog.createdAt}
                          method={prevMonthsActivityLog.method}
                          module={prevMonthsActivityLog.moduleName}
                          oldData={prevMonthsActivityLog.oldData}
                          newData={prevMonthsActivityLog.newData}
                          onViewDetailsClick={handleOnViewDetailsClicked}
                        />
                      ))}
                    </Timeline>
                    {totalOfPrevMonthsActivityLogs > LIMIT * currentPageForPrevMonths && (
                      <LoadMoreButton
                        onClick={() => {
                          setCurrentPageForPrevMonths(currentPageForPrevMonths + 1);
                          fetchActivityLogs(
                            'prevMonths',
                            currentPageForPrevMonths + 1,
                            prevMonthsactivityLogs,
                            setPrevMonthsActivityLogs,
                            setTotalOfPrevMonthsActivityLogs
                          );
                        }}
                      >
                        {' '}
                        » Load more
                      </LoadMoreButton>
                    )}
                  </Card>
                )}
              </Card>
            </Col>
            <Col span={24} md={14} style={{ maxHeight: '77vh', overflowY: 'scroll' }}>
              <Card title="Change Comparison" onClose={() => setViewDetailsClicked(false)}>
                {isViewDetailsClicked ? (
                  <>
                    <Legends />
                    <ReactDiffViewer oldValue={oldData} newValue={newData} splitView={false} disableWordDiff={true} hideLineNumbers={true} />
                  </>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No data comparison</span>} />
                )}
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(ActivityLogs);
