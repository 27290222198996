import { Row, Col } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';

import Location from 'components/Location/Location';
import GoogleMap from 'components/GoogleMapWithOneMarker/GoogleMapWithOneMarker';

export const useLngLat = (defaultLatitude = '', defaultLongitude = '') => {
  const [latitude, setLatitude] = useState(defaultLatitude);
  const [longitude, setLongitude] = useState(defaultLongitude);

  useEffect(() => {
    setLatitude(defaultLatitude);
    setLongitude(defaultLongitude);
  }, [defaultLatitude, defaultLongitude]);

  const setCoordinates = useCallback((lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  }, []);

  return { latitude, longitude, setCoordinates };
};

const LocationWithGoogleMap = ({ form, defaultValue, latitude, longitude, onMapCoordinateChange, isDisabled, isRequired }) => {
  const [zoom, setZoom] = useState(6);

  const handleOnMapCoordinateChange = useCallback(
    (latitude, longitude) => {
      setZoom(16);
      onMapCoordinateChange(latitude, longitude);
    },
    [onMapCoordinateChange]
  );

  const handleOnMapMarkerMove = useCallback(
    newLocation => {
      onMapCoordinateChange(newLocation.latitude, newLocation.longitude);
    },
    [onMapCoordinateChange]
  );

  return (
    <Row gutter={16}>
      <Col span={24} md={12}>
        <Location form={form} isDisabled={isDisabled} isRequired={isRequired} data={defaultValue} onCoordinateChange={handleOnMapCoordinateChange} />
      </Col>
      <Col style={{ height: '400px' }} span={24} md={12}>
        <GoogleMap
          setCoords={handleOnMapMarkerMove}
          longitude={longitude || defaultValue.longitude}
          latitude={latitude || defaultValue.latitude}
          zoom={defaultValue.longitude ? 16 : zoom}
        />
      </Col>
    </Row>
  );
};

export default LocationWithGoogleMap;
