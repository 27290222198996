import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Modal, Icon, List, Button } from 'antd';

import Card from 'components/Card/Card';
import { BareSelect } from 'components/FormSelection/FormSelection';

import { getCurrentIndexForPaginatedList, getLabelOfConstant } from 'utils/general';
import { getHostRoute } from 'utils/routes';
import { getHosts } from 'apis/host';

import AssignHostModal from './components/AssignHostModal';

import styled from 'styled-components/macro';

const useFetchHosts = (hostsMatched, hostTypes) => {
  const [isHostsLoading, setIsHostsLoading] = useState(true);
  const [fullHosts, setFullHosts] = useState([]);

  const hostOptions = useMemo(() => {
    const hostsMatchedIds = hostsMatched.map(host => host.host._id);
    const hostOptions = fullHosts
      .filter(host => !hostsMatchedIds.includes(host._id))
      .map(host => {
        const shouldShowHostType = !!host.type && hostTypes && hostTypes.length > 0;
        const label = `${host.name} ${shouldShowHostType ? `(${getLabelOfConstant(host.type, hostTypes)})` : ''}`;
        return { label, value: host._id };
      });
    return hostOptions;
  }, [fullHosts, hostsMatched, hostTypes]);

  useEffect(() => {
    const fetchData = async () => {
      const fullHosts = await getHosts({ isFetchAll: true }, ['name', 'contact', 'type']);
      setFullHosts(fullHosts);
      setIsHostsLoading(false);
    };

    fetchData();
  }, []);

  return { isHostsLoading, fullHosts, hostOptions };
};

const AddHostButton = styled(Button)`
  margin-bottom: 16px;
  width: auto !important;
`;

const MatchedHostRow = ({ canEdit, hostMatched, hostMatchStatuses, hostTypes, onStatusChange, onDelete }) => {
  const hostType = hostTypes.find(type => type.value === hostMatched.host.type);

  return (
    <Row xs={24} gutter={8}>
      <Col xs={24} md={14}>
        <Row>Contact: {(hostMatched.host.contact && hostMatched.host.contact.contactNumber) || '-'}</Row>
        <Row>Host Type: {hostType ? hostType.label : '-'}</Row>
      </Col>
      <Col xs={24} md={7}>
        <BareSelect isDisabled={!canEdit} options={hostMatchStatuses} defaultValue={hostMatched.status} onChange={onStatusChange} />
      </Col>
      <Col xs={24} md={3}>
        <Button disabled={!canEdit} type="secondary" icon="delete" onClick={onDelete}>
          Unassign
        </Button>
      </Col>
    </Row>
  );
};

const MatchedHostInfo = ({
  canEdit,
  hostsMatched,
  hostMatchStatuses,
  originalHostMatchStatuses,
  hostTypes,
  onHostsMatchedChange
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isHostsLoading, fullHosts, hostOptions } = useFetchHosts(hostsMatched,hostTypes);

  const handleOnHostMatchedStatusChange = useCallback(
    index => status => {
      hostsMatched[index].status = status;
      onHostsMatchedChange(hostsMatched);
    },
    [hostsMatched, onHostsMatchedChange]
  );

  const handleOnHostMatchedDelete = useCallback(
    index => e => {
      Modal.confirm({
        title: 'Are you sure want to remove this host?',
        content: 'This host will not be unassigned from this enquiry until you click the "Save" button',
        icon: <Icon type="warning" />,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk() {
          hostsMatched.splice(index, 1);
          onHostsMatchedChange([...hostsMatched]);
        },
        onCancel() {}
      });
    },
    [hostsMatched, onHostsMatchedChange]
  );

  const handleOnClickAssignHost = e => {
    setIsModalVisible(true);
  };

  const handleOnCancelAssignHost = e => {
    setIsModalVisible(false);
  };

  const handleOnConfirmAssignHost = assignedHost => e => {
    const host = fullHosts.find(host => host._id === assignedHost);
    const newAssignedHost = {
      host,
      status: originalHostMatchStatuses.ASSIGNED.code
    };

    onHostsMatchedChange([...hostsMatched, newAssignedHost]);
    setIsModalVisible(false);
  };

  return (
    <Card title="Matched Hosts" loading={isHostsLoading}>
      {canEdit && (
        <AddHostButton icon="plus" type="primary" onClick={handleOnClickAssignHost}>
          Assign Host
        </AddHostButton>
      )}
      <List
        size="large"
        bordered
        dataSource={hostsMatched}
        pagination={{
          onChange: page => setCurrentPage(page)
        }}
        renderItem={(item, index) => {
          const currentItemIndex = getCurrentIndexForPaginatedList(index, currentPage);

          return (
            <List.Item>
              <List.Item.Meta
                title={
                  <Row style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                    <a href={getHostRoute(item.host._id).path} target="_blank" rel="noopener noreferrer">
                      {item.host.name}
                    </a>
                  </Row>
                }
                description={
                  <MatchedHostRow
                    canEdit={canEdit}
                    hostMatched={item}
                    hostMatchStatuses={hostMatchStatuses}
                    hostTypes={hostTypes}
                    onStatusChange={handleOnHostMatchedStatusChange(currentItemIndex)}
                    onDelete={handleOnHostMatchedDelete(currentItemIndex)}
                  />
                }
              />
            </List.Item>
          );
        }}
      />
      {isModalVisible && (
        <AssignHostModal
          visible={isModalVisible}
          hostOptions={hostOptions}
          onCancel={handleOnCancelAssignHost}
          onConfirm={handleOnConfirmAssignHost}
        />
      )}
    </Card>
  );
};

export default MatchedHostInfo;
